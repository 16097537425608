import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { deleteNormativesThunk, listNormativesThunk } from './Normatives.thunk'
import { setStatusPending, setStatusRejected, setStatusSucceeded } from 'app/__shared__/components/store/thunkStatusHandler'

import { RootState } from 'app/__shared__/components/store/store'
import { TNormatives } from 'app/__shared__/model/types/normatives'
import { TResponse } from 'app/__shared__/model/types/response'
import { statusEnum } from 'app/__shared__/model/enums/statusLoading'

export interface IListNormativesState {
    status:statusEnum,
    response:TResponse<TNormatives>,
} 

const ListNormativesState: IListNormativesState = {
    status: statusEnum.idle,
    response: {
        data: [],
    },
}


export const ListNormativesSlice = createSlice({
  name: 'list-normatives-slice',
  initialState: ListNormativesState,
  reducers: {
  },
  
  extraReducers: (builder) => {
    builder.addCase(listNormativesThunk.pending, (state:IListNormativesState, action: PayloadAction<any>) => {
        setStatusPending(state, action)
    })
    
    builder.addCase(listNormativesThunk.rejected, (state:IListNormativesState, action:  PayloadAction<any>) => {
        setStatusRejected(state, action)
        state.response.data = []
    })
    
    builder.addCase(listNormativesThunk.fulfilled, (state:IListNormativesState, action:  PayloadAction<any>) => {
        setStatusSucceeded(state, action)
        state.response.data = action.payload.data
    })


    builder.addCase(deleteNormativesThunk.pending, (state:IListNormativesState, action: PayloadAction<any>) => {
    })
    
    builder.addCase(deleteNormativesThunk.rejected, (state:IListNormativesState, action:  PayloadAction<any>) => {
    })
    
    builder.addCase(deleteNormativesThunk.fulfilled, (state:IListNormativesState, action:  PayloadAction<any>) => {
    })

    
  },
})


export const ListNormativesActions = {
    ...ListNormativesSlice.actions,
}


export const ListNormativesData = (state:RootState) => state.NormativesReducer.ListNormativesReducer

export const ListNormativesReducer = ListNormativesSlice.reducer