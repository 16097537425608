import { HashRouter } from "react-router-dom";
import { ProtectedRoutes } from "./ProtectedRoutes";
import React from 'react'

export const Routes:React.FC = () => {
    

    return (
        <HashRouter>
            <ProtectedRoutes/>
        </HashRouter>
   )
}