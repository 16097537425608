import { FormEvent, useContext, useEffect, useState } from 'react';
import { azNumberRegexWithout994, emailRegex } from 'app/__shared__/data/regexs';
import { faCheck, faExclamation, faExclamationCircle, faInfoCircle, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { useAppDispatch, useAppSelector } from 'app/__shared__/components/store/store';

import { APPLICATION_PATH } from 'app/__shared__/helpers/api/services/path';
import { BAKU_CORDINATES } from 'app/__shared__/data/contants';
import { CustomFileInput } from 'app/__shared__/components/common/input/CustomFileInput';
import { CustomInput } from 'app/__shared__/components/common/input/CustomInput';
import { CustomSelect } from 'app/__shared__/components/common/select/CustomSelect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IAppeal } from 'app/appeals/slices/AppealsEdit.slice';
import { MapObjectSelectLocation } from 'app/__shared__/components/common/map/MapObjectSelectLocation';
import { ModalContext } from 'app/__shared__/components/context/modal/ModalContext';
import Swl from 'sweetalert2';
import { createAppealsThunk } from 'app/appeals/slices/Appeals.thunk';
import gescoLogo from 'app/__shared__/assets/media/logos/gesco.png';
import identity_card_new from 'app/__shared__/assets/media/image/ynsv.svg';
import identity_card_old from 'app/__shared__/assets/media/image/FIN+_+ShV.png';
import { default as isValidDate } from 'pretty-easy-date-check';
import permanent_residence_card from 'app/__shared__/assets/media/image/dyi.svg';
import { statusEnum } from 'app/__shared__/model/enums/statusLoading';
import temporary_residence_card from 'app/__shared__/assets/media/image/myi.svg';
import toast from 'react-hot-toast';
import { translateBackErrors } from 'app/__shared__/helpers/utils/backDataTranslate/translateBackErrors';
import { url } from 'inspector';
import { viewVacancySettingsThunk } from 'app/vacancy-settings/slices/VacancySettings.thunk';

export const CreateAppeal = () => {
    const createAppealsData = useAppSelector(state => state.AppealsReducer.CreateAppealsReducer);
    const settingsData = useAppSelector(state => state.VacancySettingsReducer);
    const dispatch = useAppDispatch()
    const [birthdayMaxLimit, setBirthdayMaxLimit] = useState<any>('');
    const [birthdayRange, setBirthdayRange] = useState<number[]>([]);
    const {toggleModalShow}  = useContext(ModalContext)

    const [createAppealData, setCreateAppealData] = useState<IAppeal>({
        name: '',
        surname: '',
        father_name: '',
        email: '',
        serial_number: '',
        fin_number: '',
        address: '',
        note:'',
        position:{
            lat:BAKU_CORDINATES.lat,
            lng:BAKU_CORDINATES.lng
        },
        phone: '',
        is_millitary: null,
        has_crime: 0,
        has_invalidity: 0,
        gender:null,
        weight:'',
        height:'',
        birthday:'',
        birth_day:'gün',
        birth_month:'ay',
        birth_year:'il',
        image:null,
        image_url:'',
        video:null,
        video_url:'',
        video_name:'',
        isAllValidStatus:statusEnum.idle,
        steps:{
            currentStep:1,
            step1:{
                status:statusEnum.idle
            },
            step2:{
                status:statusEnum.idle
            },
            step3:{
                status:statusEnum.idle
            },
        }
    })
    const {name, surname, father_name, address,note,birthday,birth_day,birth_month,birth_year,email,fin_number,gender,has_crime,has_invalidity,height,image,image_url,image_name, isAllValidStatus,is_millitary,phone,serial_number,video,video_url,video_name,weight} = createAppealData

    const [position, setPosition] = useState<{
        lat:number,
        lng:number
    }>({lat:BAKU_CORDINATES.lat, lng:BAKU_CORDINATES.lng})
    
    useEffect(() => {
        setCreateAppealData({
            ...createAppealData,
            position:position
        })
    }, [position])
    
    useEffect(() => {
        dispatch(viewVacancySettingsThunk({}))
    }, [dispatch])

    useEffect(() => {
        if(settingsData.status === statusEnum.succeeded){
            const createDateFromAge = new Date(new Date().getFullYear() - settingsData.data.min_age, 0, 1);
            const convertString = createDateFromAge.toISOString().split('T')[0]
            setBirthdayMaxLimit(convertString)
            const year = new Date().getFullYear() - settingsData.data.min_age
            const year_range = []
            for (let i = year; i >= new Date().getFullYear() - settingsData.data.max_age; i--) {
                year_range.push(i)             
            }
            setBirthdayRange(year_range)
            console.log(settingsData.data);
            
            setCreateAppealData({
                ...createAppealData,
                is_millitary:settingsData.data.required_military,
                gender:settingsData.data.gender
            })
        }
    }, [settingsData.status , settingsData.data.min_age])

    useEffect(() => {
        if(createAppealsData.submit_status === statusEnum.succeeded){
            
            Swl.fire({
                title: 'Müraciətiniz qeydə alındı.',
                text: 'Uyğun olduğunuz halda sizə geri dönüş ediləcək.',
                icon: 'success',
                confirmButtonText: 'Yeni müraciət',
                showCancelButton: true,
                cancelButtonText: 'Geri',
                cancelButtonColor: '#d33',
                confirmButtonColor: '#3085d6',
                reverseButtons: true,
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                focusConfirm: false,
            }, ).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    window.location.reload()
                } else if (result.isDenied) {
                    
                }else{}
              })
            // toast.success("Məlumatlarınız uğurla yükləndi")
        }
        if(createAppealsData.submit_status === statusEnum.failed){
            toast.error("Xəta baş verdi, məlumatlarınızı bir daha yoxlayın.")
            setCreateAppealData({
                ...createAppealData,
                steps:{
                    ...createAppealData.steps,
                    step3:{
                        status:statusEnum.failed
                    }
                }
            })
        }
    }, [createAppealsData.submit_status])
    

    const goStep2 = () => {
        if(height < settingsData.data.min_height || checkDateIsInvalid().isNotValid){
            setCreateAppealData({
                ...createAppealData,
                steps:{
                    ...createAppealData.steps,
                    step1:{
                        status:statusEnum.failed
                    }
                }
            })
        }else{
            setCreateAppealData({
                ...createAppealData,
                birthday:checkDateIsInvalid().date!,
                steps:{
                    ...createAppealData.steps,
                    currentStep:2,
                    step1:{
                        status:statusEnum.succeeded
                    }
                }
            })
        }
    }

    const goStep3 = () => {
        if(!name || !surname || !father_name || name.length < 3 || surname.length < 3 || father_name.length < 3 || fin_number.length < 5 || !address  || !email || !emailRegex.test(email) || !azNumberRegexWithout994.test(phone)  || !fin_number || !phone || !serial_number || !weight ){
            setCreateAppealData({
                ...createAppealData,
                steps:{
                    ...createAppealData.steps,
                    step2:{
                        status:statusEnum.failed
                    }
                }
            })
        }else{
            setCreateAppealData({
                ...createAppealData,
                steps:{
                    ...createAppealData.steps,
                    currentStep:3,
                    step2:{
                        status:statusEnum.succeeded
                    }
                }
            })
        }
    }

    
    const checkDateIsInvalid = ():{isNotValid:boolean,date:string | null} => {
        const day = parseInt(birth_day)
        const month = parseInt(birth_month)
        const year = parseInt(birth_year)
        const date = `${month}/${day}/${year}`
        const date_obj = new Date(date)
        
        const invalid_date = isNaN(date_obj.getTime())
        if(invalid_date){
            console.log(invalid_date);
            return {
                date:null,
                isNotValid:true,
            }
        }
        if(date_obj.getDate() !== day || (date_obj.getMonth() + 1) !== month || date_obj.getFullYear() !== year){
            return {
                date:null,
                isNotValid:true
            }
        }else {
            return {
                date:date_obj.toISOString(),
                isNotValid:false
            }
        }
        
    }

    const handleSubmit = async (e:FormEvent) => {
        e.preventDefault()
        if(createAppealData.steps.currentStep === 1){
            goStep2()
            return
        }

        if(createAppealData.steps.currentStep === 2){
            goStep3()
            return
        }

        if(
            createAppealData.steps.step1.status === statusEnum.failed ||
            createAppealData.steps.step2.status === statusEnum.failed 
            ){
            return 
        }

        if(
            !name  || 
            !surname || 
            !father_name || 
            !address || 
            !birthday || 
            !emailRegex.test(email) || 
            !fin_number || 
            !serial_number || 
            !azNumberRegexWithout994.test(phone) || 
            !position.lat || 
            !position.lng || 
            !weight || 
            !height || 
            !image 
        ) 
        {

            setCreateAppealData(
                {
                    ...createAppealData,
                    steps:{
                        ...createAppealData.steps,
                        step3:{
                            status:statusEnum.failed
                        }
                    },
                    isAllValidStatus:statusEnum.failed
                }
            )
        }
        else{
            setCreateAppealData(
                {
                    ...createAppealData,
                    isAllValidStatus:statusEnum.succeeded
                }
            )
            const postFormData = new FormData()
           
            postFormData.append('name', name)
            postFormData.append('surname', surname)
            postFormData.append('father_name', father_name)
            postFormData.append('email', email)
            postFormData.append('address', address)
            postFormData.append('birthday', new Date(birthday).toISOString())
            postFormData.append('email', email)
            postFormData.append('fin', fin_number)
            postFormData.append('phone', phone)
            postFormData.append('serial_number', serial_number)
            postFormData.append('fin', fin_number)
            postFormData.append('lat', `${position.lat}`)
            postFormData.append('long', `${position.lng}`)
            postFormData.append('weight', weight)
            postFormData.append('height', height)
            postFormData.append('note', note)
            postFormData.append('image', image , image.name)
            if(video){
                postFormData.append('video', video , video.name)
            }
            postFormData.append('is_military', `${is_millitary}`)   
            postFormData.append('has_crime', `${has_crime}`)
            postFormData.append('has_invalidity', `${has_invalidity}`)
            postFormData.append('gender', `${gender}`)

            await dispatch(createAppealsThunk({
                data:postFormData
            }))
        }
    }

    const backStep1 = () => {
        setCreateAppealData({
            ...createAppealData,
            steps:{
                ...createAppealData.steps,
                currentStep:1,
                step1:{
                    status:statusEnum.idle
                }
            }
        })
    }

    const backStep2 = () => {
        setCreateAppealData({
            ...createAppealData,
            steps:{
                ...createAppealData.steps,
                currentStep:2,
                step2:{
                    status:statusEnum.idle
                }
            }
        })
    }

    const openInfoPassport = () => {
        toggleModalShow(
            <div className='h-auto  flex  flex-col gap-5'>
                <img src={identity_card_new} alt="" className='w-full h-48 ' />
                <img src={identity_card_old} alt="" className='w-full h-48 ' />
                <img src={temporary_residence_card} alt="" className='w-full h-48 ' />
                <img src={permanent_residence_card} alt="" className='w-full h-48 ' />
            </div>,
            "Şəxsiyyət vəsiqəsinin FİN kodu və seriya nömrəsi"
        )
    }

    return (
        <div className="w-full flex flex-col relative">

            <div className='create-appeal w-full absolute h-full'></div>
            {
                settingsData.status === statusEnum.loading ?
                <div className="row mx-auto flex justify-center items-center p-4 w-full md:w-full" >
                    {/* <div className="text-primary text-2xl" role="status">
                        <FontAwesomeIcon icon={faSpinner} className='animate-spin'/>
                        <span className="text-white text-lg"> Məlumatlar Yüklənir...</span>
                    </div> */}
                </div>
                :
                <div className="max-w-2xl mx-auto pt-10 gap-4 flex flex-col mb-20 z-10 relative rounded-2xl create-appeal-form mt-16">
                    
                    <div className="form-logo">
                        <img width={150} src={gescoLogo} alt="logo"  className='mx-auto'/>
                    </div>

                    <form onSubmit={handleSubmit} className="row h-full">

                        <div className='flex gap-2 justify-center p-2 w-full items-center'>
                            <span className={`text-white step ${createAppealData.steps.step1.status === statusEnum.failed ? "step-failed" :  createAppealData.steps.step1.status === statusEnum.succeeded ? "step-succeeded" : createAppealData.steps.currentStep === 1 ? 'bg-[#5B5BD7]' : 'bg-gray-400'}` }> { createAppealData.steps.step1.status === statusEnum.failed ? <FontAwesomeIcon icon={faExclamation} size={"sm"}/> : createAppealData.steps.step1.status === statusEnum.succeeded ? <FontAwesomeIcon icon={faCheck}/> : '1'}</span>
                            <div className='step-line'></div>
                            <span className={`text-white step ${createAppealData.steps.step2.status === statusEnum.failed ? "step-failed" :  createAppealData.steps.step2.status === statusEnum.succeeded ? "step-succeeded" : createAppealData.steps.currentStep === 2 ? 'bg-[#5B5BD7]' : 'bg-gray-400'}` }>{ createAppealData.steps.step2.status === statusEnum.failed ? <FontAwesomeIcon icon={faExclamation} size={"sm"}/> : createAppealData.steps.step2.status === statusEnum.succeeded ? <FontAwesomeIcon icon={faCheck}/> : '2'}</span>
                            <div className='step-line'></div>
                            <span className={`text-white step ${createAppealData.steps.step3.status === statusEnum.failed ? "step-failed" :  createAppealData.steps.step3.status === statusEnum.succeeded ? "step-succeeded" : createAppealData.steps.currentStep === 3 ? 'bg-[#5B5BD7]' : 'bg-gray-400'}` }>{ createAppealData.steps.step3.status === statusEnum.failed ? <FontAwesomeIcon icon={faExclamation} size={"sm"}/> : createAppealData.steps.step3.status === statusEnum.succeeded ? <FontAwesomeIcon icon={faCheck}/> : '3'}</span>
                        </div>
                        {/* step 1 */}
                        <div className={`mx-auto ${createAppealData.steps.currentStep === 1 ? "row" : 'hidden'}`}>

                            <div className="form-group  col-md-6">
                                <input type="text" disabled className="form-control text-sm"  value={settingsData.data.status === statusEnum.loading ? "Yüklənir" : settingsData.data.required_military === 0 ? "Hərbi xidməti başa vurmamış." : "Hərbi xidməti başa vurmuş"}/>
                                {/*
                                <CustomSelect
                                    value={is_millitary}
                                    onChange={(data:any)=>
                                        setCreateAppealData({
                                            ...createAppealData,
                                            is_millitary:data
                                        })
                                    }
                                    disabled
                                    options={[{value:0,label:'Xidmətdə olmamış.',disabled:(settingsData.data.required_military !== 0)},{value:1,label:'Xidməti başa vurmuş.',disabled:(settingsData.data.required_military !== 1)}]}
                                    placeHolder="Hərbi mükəlləfiyyətinizi seçin"
                                    emptyError='Hərbi mükəlləfiyyətinizi seçin'
                                    required={true}
                                    isFormSubmitStatus={isAllValidStatus}
                                /> */}
                                {/* <small>{settingsData.data.required_military === 1 ? "*Hərbi mükəlləfiyyətiniz xidmətdə olmuş olmalıdır." :""}</small> */}
                            </div>

                            <div className="form-group  col-md-6">
                                <input type="text" disabled className="form-control text-sm"  value={settingsData.data.status === statusEnum.loading ? "Yüklənir" : (settingsData.data.gender === '1' ? "Kişi" : "Qadın")}/>
    {/*
                                <CustomSelect
                                    value={gender}
                                    onChange={(data:any)=>
                                        setCreateAppealData({
                                            ...createAppealData,
                                            gender:data
                                        })
                                    }
                                    options={[{value:1,label:'Kişi',disabled:(settingsData.data.gender !== '1')},{value:2,label:'Qadın',disabled:settingsData.data.gender !== '2'}]}
                                    placeHolder="Cinsi seçin"
                                    emptyError='Cinsi seçin'
                                    required={true}
                                    isFormSubmitStatus={isAllValidStatus}
                                /> */}
                                {/* <small>{settingsData.data.gender === 1 ? "*Yalnız bəy namizədlər müraicət etməlidir." :"*Yalnız xanım namizədlər müraicət etməlidir"}</small> */}
                            </div>

                            <div className="form-group  col-md-6">
                                <input type="text" disabled className="form-control text-sm"  value={"Cinayət məsuliyyətinə cəlb olunmamış"}/>

                                {/* <label>Məhkumluğunuz varmı ?</label> */}
                                {/* <CustomSelect
                                    value={has_crime}
                                    onChange={(data:any)=>
                                        setCreateAppealData({
                                            ...createAppealData,
                                            has_crime:data
                                        })
                                    }
                                    options={[{value:1,label:'Bəli'},{value:0,label:'Xeyr'}]}
                                    placeHolder="Məhkumluğunuz varmı"
                                    emptyError='Məhkumluğunuzu qeyd edin'
                                    required={true}
                                    isFormSubmitStatus={isAllValidStatus}
                                /> */}
                                {/* <small>{settingsData.data.has_crime === 1 ? "*Məhkumluğunuz olmamalıdır." :""}</small> */}
                            </div>
                            
                            <div className="form-group  col-md-6">
                                <input type="text" disabled className="form-control text-sm"  value={"Əlillik dərəcəsi yoxdur"}/>

                                {/* <CustomSelect
                                    value={has_invalidity}
                                    onChange={(data:any)=>
                                        setCreateAppealData({
                                            ...createAppealData,
                                            has_invalidity:data
                                        })
                                    }
                                    options={[{value:1,label:'Bəli'},{value:0,label:'Xeyr'}]}
                                    placeHolder="Əlliliyiniz varmı"
                                    emptyError='Əlliliyinizi qeyd edin'
                                    required={true}
                                    isFormSubmitStatus={isAllValidStatus}
                                /> */}
                                {/* <small>{settingsData.data.has_invalidity === 1 ? "*Əlliliyiniz olmamalıdır." :""}</small> */}
                            </div>

                            <div className="form-group  col-md-6">
                                <small className='text-sm text-white'>Boy (sm)</small>
                                <CustomInput
                                    // required={true}
                                    isFormSubmitStatus={isAllValidStatus}
                                    emptyError={'Boy hündürlüyünüzü daxil edin.'}
                                    value={height}
                                    type='text'
                                    placeHolder={'180'}
                                    maxLength={3}
                                    onlyNumber={true}
                                    onChange={(data) => {
                                        setCreateAppealData({
                                            ...createAppealData,
                                            height: data,
                                        })
                                    }}
                                />
                                {/* <div className='w-full'>
                                    <small>
                                        *Minimum boy hündürlüyü minimum {settingsData.data.min_height} olmalıdır.
                                    </small>
                                </div> */}
                            </div>

                            <div className="form-group  col-md-6">
                                <small className='text-sm text-white'>Doğum tarixi ( yaş aralığı: {settingsData.data.min_age} - {settingsData.data.max_age} )</small>
                                
                                <div className='flex gap-2'>
                                    <select value={createAppealData.birth_day} onChange={
                                        (e) => {
                                            setCreateAppealData({
                                                ...createAppealData,
                                                birth_day: e.target.value,
                                            })
                                        }
                                    } className="w-max form-control" name="day" id="">
                                        <option value="gün" disabled className='hidden' selected>
                                            gün
                                        </option>
                                        <option value="01" >
                                            01
                                        </option>
                                        <option value="02">
                                            02
                                        </option>
                                        <option value="03">
                                            03
                                        </option>
                                        <option value="04">
                                            04
                                        </option>
                                        <option value="05">
                                            05
                                        </option>
                                        <option value="06">
                                            06
                                        </option>
                                        <option value="07">
                                            07
                                        </option>
                                        <option value="08">
                                            08
                                        </option>
                                        <option value="09">
                                            09
                                        </option>
                                        <option value="10">
                                            10
                                        </option>
                                        <option value="11">
                                            11
                                        </option>
                                        <option value="12">
                                            12
                                        </option>
                                        <option value="13">
                                            13
                                        </option>
                                        <option value="14">
                                            14
                                        </option>
                                        <option value="15">
                                            15
                                        </option>
                                        <option value="16">
                                            16
                                        </option>
                                        <option value="17">
                                            17
                                        </option>
                                        <option value="18">
                                            18
                                        </option>
                                        <option value="19">
                                            19
                                        </option>
                                        <option value="20">
                                            20
                                        </option>
                                        <option value="21">
                                            21
                                        </option>
                                        <option value="22">
                                            22
                                        </option>
                                        <option value="23">
                                            23
                                        </option>
                                        <option value="24">
                                            24
                                        </option>
                                        <option value="25">
                                            25
                                        </option>
                                        <option value="26">
                                            26
                                        </option>
                                        <option value="27">
                                            27
                                        </option>
                                        <option value="28">
                                            28
                                        </option>
                                        <option value="29">
                                            29
                                        </option>
                                        <option value="30">
                                            30
                                        </option>
                                        <option value="31">
                                            31
                                        </option>
                                    </select>

                                    <select value={createAppealData.birth_month} onChange={
                                        (e) => {
                                            setCreateAppealData({
                                                ...createAppealData,
                                                birth_month: e.target.value,
                                            })
                                        }
                                    } className="form-control" name="" id="15">
                                        <option className='hidden' value="ay" disabled selected>
                                            ay
                                        </option>
                                        <option value="01">Yanvar</option>
                                        <option value="02">Fevral</option>
                                        <option value="03">Mart</option>
                                        <option value="04">Aprel</option>
                                        <option value="05">May</option>
                                        <option value="06">Iyun</option>
                                        <option value="07">Iyul</option>
                                        <option value="08">Avqust</option>
                                        <option value="09">Sentyabr</option>
                                        <option value="10">Oktyabr</option>
                                        <option value="11">Noyabr</option>
                                        <option value="12">Dekabr</option>
                                    </select>

                                    <select value={createAppealData.birth_year} onChange={
                                        (e) => {
                                            setCreateAppealData({
                                                ...createAppealData,
                                                birth_year: e.target.value,
                                            })
                                        }
                                    }
                                    className="w-max form-control" name="" id="">
                                        <option className='hidden' value="il" disabled selected>
                                            il
                                        </option>
                                        {
                                            birthdayRange.map(item => {
                                                return <option key={item} value={item}>{item}</option>
                                            })
                                        }
                                    </select>
        
                                </div>
                                {/* <CustomInput
                                    required={true}
                                    isFormSubmitStatus={isAllValidStatus}
                                    emptyError={'Təvəllüdünüzü daxil edin.'}
                                    value={birthday}
                                    type='date'
                                    maxDate={birthdayMaxLimit}
                                    onChange={(data) => {
                                        setCreateAppealData({
                                            ...createAppealData,
                                            birthday: data,
                                        })
                                    }}
                                /> */}

                                {/* <div className='w-full'>
                                    <small>*Minimum yaşınız {settingsData.data.min_age} olmalıdır.</small>
                                </div> */}
                            </div>

                            <div className='col-md-12 flex items-start gap-1'>
                                <FontAwesomeIcon icon={faExclamationCircle} className='text-primary text-xs mt-0.5 ml-0.5'/>
                                <p className='text-sm text-white'>Hörmətli namizəd, yuxarıda qeyd olunan tələblərə uyğun olduğunuz halda, müraciətinizi tamamlamaq üçün növbəti mərhələyə keçid edin.</p>
                            </div>
                            {
                                createAppealData.steps.step1.status === statusEnum.failed &&
                                <>
                                    {
                                        height < settingsData.data.min_height &&
                                        <div className="col-md-12 flex items-start gap-1 mt-2">
                                            <FontAwesomeIcon icon={faExclamationCircle} className='text-red-500 text-xs mt-0.5 ml-0.5'/>
                                            <p className='text-sm text-white' >Minimum boy ölçünüz {settingsData.data.min_height} sm olmalıdır.</p>
                                        </div>
                                    }
                                    {
                                        checkDateIsInvalid().isNotValid &&
                                        <div className="col-md-12 flex items-start gap-1 mt-2">
                                            <FontAwesomeIcon icon={faExclamationCircle} className='text-red-500 text-xs mt-0.5 ml-0.5'/>
                                            <p className='text-sm text-white' >Tarix məlumatlarını düzgün daxil edin</p>
                                        </div>
                                    }
                                </>
                            }
                        

                            <div className="row mt-5 ml-auto pr-8">
                                <button
                                    onClick={goStep2}
                                    type="button"
                                    className="btn btn-primary font-weight-bold ml-2  hover:text-white"
                                >
                                    Növbəti
                                </button>
                            </div>
                        </div>


                        {/* step 2 */}
                        <div className={`mx-auto ${createAppealData.steps.currentStep === 2 ? "row" : 'hidden'}`}>

                            <div className="form-group  col-md-6">
                                <small className='text-sm text-white'>Ad</small>
                                <CustomInput
                                    // required={true}
                                    isFormSubmitStatus={createAppealData.steps.step2.status}
                                    emptyError={'Adınızı daxil edin.'}
                                    value={name}
                                    type='text'
                                    placeHolder='Adınız'
                                    onChange={(data) => {
                                        setCreateAppealData({
                                            ...createAppealData,
                                            name: data,
                                        })
                                    }}
                                />
                            </div>
                            
                            <div className="form-group  col-md-6">
                                <small className='text-sm text-white'>Soyad</small>
                                <CustomInput
                                    // required={true}
                                    isFormSubmitStatus={createAppealData.steps.step2.status}
                                    emptyError={'Soyadınızı daxil edin.'}
                                    value={surname}
                                    type='text'
                                    placeHolder='Soyadınız'
                                    onChange={(data) => {
                                        setCreateAppealData({
                                            ...createAppealData,
                                            surname: data,
                                        })
                                    }}
                                />
                            </div>

                            <div className="form-group  col-md-6">
                                <small className='text-sm text-white'>Ata adı</small>
                                <CustomInput
                                    // required={true}
                                    isFormSubmitStatus={createAppealData.steps.step2.status}
                                    emptyError={'Ata adını daxil edin.'}
                                    value={father_name}
                                    type='text'
                                    placeHolder='Ata adınız'
                                    onChange={(data) => {
                                        setCreateAppealData({
                                            ...createAppealData,
                                            father_name: data,
                                        })
                                    }}
                                />
                            </div>


                            <div className="form-group  col-md-6">
                                <small className='text-sm text-white'>Elektron poçt ünvanı</small>
                                <CustomInput
                                    // required={true}
                                    isFormSubmitStatus={createAppealData.steps.step2.status}
                                    emptyError={'Elektron poçtunuzu daxil edin.'}
                                    invalidError={"Elektron poçtunuzu düzgün daxil edin."}
                                    value={email}
                                    type='email'
                                    placeHolder='nümunə@gmail.com'
                                    onChange={(data) => {
                                        setCreateAppealData({
                                            ...createAppealData,
                                            email: data,
                                        })
                                    }}
                                    regex={emailRegex}
                                />
                            </div>


                            <div className="form-group  col-md-6">
                                <small className='text-sm text-white'>Faktiki yaşayış ünvanı</small>
                                <CustomInput
                                    // required={true}
                                    isFormSubmitStatus={createAppealData.steps.step2.status}
                                    emptyError={'Yaşadığınız yeri qeyd edin'}
                                    value={address}
                                    type='text'
                                    placeHolder='Bakı şəhəri, Suraxanı rayonu'
                                    onChange={(data) => {
                                        setCreateAppealData({
                                            ...createAppealData,
                                            address: data,
                                        })
                                    }}
                                />
                            </div>


                            <div className="form-group  col-md-6 relative">
                                <small className='text-sm text-white'>Şəxsiyyət vəsiqəsinin seriyası və nömrəsi</small>
                                <CustomInput
                                    // required={true}
                                    isFormSubmitStatus={createAppealData.steps.step2.status}
                                    emptyError={'Şəxsiyyətin seriya nömrəsini daxil edin.'}
                                    value={serial_number}
                                    maxLength={12}
                                    type='text'
                                    onlyNumber={false}
                                    placeHolder='Seriya nömrəsi'
                                    onChange={(data) => {
                                        setCreateAppealData({
                                            ...createAppealData,
                                            serial_number: data,
                                        })
                                    }}
                                />
                                <button type='button' onClick={openInfoPassport} className='absolute right-6 top-8 text-blue-500'>
                                    <FontAwesomeIcon icon={faInfoCircle}/>
                                </button>
                            </div>


                            <div className="form-group  col-md-6">
                                <small className='text-sm text-white'>Şəxsiyyət vəsiqəsinin FİN kodu</small>
                                <CustomInput
                                    // required={true}
                                    isFormSubmitStatus={createAppealData.steps.step2.status}
                                    emptyError={'Şəxsiyyətin fin nömrəsini daxil edin.'}
                                    value={fin_number}
                                    type='text'
                                    placeHolder='FİN kod'
                                    maxLength={10}
                                    onChange={(data) => {
                                        setCreateAppealData({
                                            ...createAppealData,
                                            fin_number: data,
                                        })
                                    }}
                                />
                                <button type='button' onClick={openInfoPassport} className='absolute right-6 top-8 text-blue-500'>
                                    <FontAwesomeIcon icon={faInfoCircle}/>
                                </button>
                            </div>

                            <div className="form-group  col-md-6">
                                <small className='text-sm text-white'>Telefon nömrəsi</small>
                                <CustomInput
                                    // required={true}
                                    isFormSubmitStatus={createAppealData.steps.step2.status}
                                    emptyError={'Telefon nömrəsini daxil edin.'}
                                    invalidError={'Telefon nömrəsi düzgün deyil.'}
                                    value={phone}
                                    type='text'
                                    placeHolder='0701234567'
                                    regex={azNumberRegexWithout994}
                                    maxLength={10}
                                    onlyNumber={true}
                                    onChange={(data) => {
                                        setCreateAppealData({
                                            ...createAppealData,
                                            phone: data,
                                        })
                                    }}
                                />
                            </div>

                            <div className="form-group  col-md-6">
                                <small className='text-sm text-white'>Çəki (kq)</small>
                                <CustomInput
                                    // required={true}
                                    isFormSubmitStatus={createAppealData.steps.step2.status}
                                    emptyError={'Çəkinizi daxil edin.'}
                                    value={weight}
                                    type='text'
                                    placeHolder='60'
                                    maxLength={3}
                                    onlyNumber={true}
                                    onChange={(data) => {
                                        setCreateAppealData({
                                            ...createAppealData,
                                            weight: data,
                                        })
                                    }}
                                />
                            </div>

                            <div className="form-group  col-md-12">
                                <small className='text-sm text-white'>Qeyd</small>
                                <textarea
                                    className='form-control resize-none'
                                    name=""
                                    id=""
                                    cols="30"
                                    rows="5"
                                    value={note}
                                    maxLength={1000}
                                    onChange={
                                        (e) => {
                                            console.log(e.target.value)
                                            setCreateAppealData({
                                                ...createAppealData,
                                                note:e.target.value
                                            })
                                        }
                                    }
                                >

                                </textarea>
                            </div>


                            {
                                createAppealData.steps.step2.status === statusEnum.failed && 
                                <>
                                    {
                                        name.length < 3 &&
                                        <div className="w-full flex gap-4  items-center mt-4">
                                            <FontAwesomeIcon icon={faExclamationCircle} className='text-red-500'/>
                                            <span className='text-white' >Ad minimum 3 hərfdən ibartət olmalıdır</span>
                                        </div>
                                    }
                                    {
                                        surname.length < 3 &&
                                        <div className="w-full flex gap-4  items-center mt-4">
                                            <FontAwesomeIcon icon={faExclamationCircle} className='text-red-500'/>
                                            <span className='text-white' >Soyad minimum 3 hərfdən ibartət olmalıdır</span>
                                        </div>
                                    }
                                    {
                                        father_name.length < 3 &&
                                        <div className="w-full flex gap-4  items-center mt-4">
                                            <FontAwesomeIcon icon={faExclamationCircle} className='text-red-500'/>
                                            <span className='text-white' >Ata adı minimum 3 hərfdən ibartət olmalıdır</span>
                                        </div>
                                    }
                                    {
                                        fin_number.length < 5 &&
                                        <div className="w-full flex gap-4  items-center mt-4">
                                            <FontAwesomeIcon icon={faExclamationCircle} className='text-red-500'/>
                                            <span className='text-white' >Fin minimum 5 hərfdən ibartət olmalıdır</span>
                                        </div>
                                    }
                                </>
                            }

                            <div className='col-md-12 flex justify-end'>
                                <button
                                    onClick={backStep1}
                                    type="button"
                                    className="btn btn-primary font-weight-bold ml-2  hover:text-white"
                                >
                                    Əvvələ
                                </button>
                                
                                <button
                                    onClick={goStep3}
                                    type="button"
                                    className="btn btn-primary font-weight-bold ml-2  hover:text-white"
                                >
                                    Növbəti
                                </button>
                            </div>
                            
                        </div>
                        

                        {/* <MapObjectSelectLocation  position={position} setPosition={setPosition} /> */}

                        {/* step 3 */}
                        <div className={`mx-auto ${createAppealData.steps.currentStep === 3 ? "row" : 'hidden'}`}>
                            
                            <div className="form-group  col-md-12">
                                <small className='text-sm text-white'>Şəkil</small>
                                <CustomFileInput
                                    // required={true}
                                    isFormSubmitStatus={createAppealData.steps.step3.status}
                                    emptyError={'Şəklinizi yükləyin'}
                                    value={image}
                                    type='file'
                                    fileMessage="Şəkilinizi yükləyin"
                                    file_name={image_name}
                                    required={true}

                                    id='image_file'
                                    onChange={(data:any) => {
                                        const url = URL.createObjectURL(data);
                                        setCreateAppealData({
                                            ...createAppealData,
                                            image: data,
                                            image_url:url,
                                            image_name:data.name,
                                        })
                                    }}
                                    url={image_url}
                                    accept="image/*"
                                />
                                {
                                    createAppealData.image_url && 
                                    <div className='mt-2 rounded-md p-5 border-2 border-gray-200 relative border-dashed'>
                                        <button onClick={
                                            ()=>{
                                                setCreateAppealData({
                                                    ...createAppealData,
                                                    image_url:'',
                                                    image:null,
                                                })
                                                document.getElementById('image_file').value = null
                                            }
                                        } className='absolute right-0 top-0 text-white m-2 hover:text-[#5B5BD7]' type="button" >✖</button>
                                        <img src={createAppealData.image_url} alt="personal" className='w-50 rounded-md '/>
                                    </div>
                                }
                            </div>

                            <div className="form-group  col-md-12">
                                <small className='text-sm text-white'>Video fayl </small>
                                <CustomFileInput
                                    isFormSubmitStatus={statusEnum.idle}
                                    // emptyError={'Video faylını yükləyin.'}
                                    required={false}
                                    value={video}
                                    type='file'
                                    fileMessage="Video faylınızı yükləyin"
                                    url={video_url}
                                    file_name={video_name}
                                    onChange={(data:any) => {
                                        const url = URL.createObjectURL(data);
                                        setCreateAppealData({
                                            ...createAppealData,
                                            video: data,
                                            video_url:url,
                                            video_name:data.name,
                                        })
                                    }}
                                    accept="video/*"
                                    id={"video_file"}
                                />
                                {
                                    createAppealData.video_url && 
                                    <div className='mt-2 rounded-md p-5 border-2 border-gray-200 relative border-dashed'>
                                        <button onClick={
                                            ()=>{
                                                setCreateAppealData({
                                                    ...createAppealData,
                                                    video_url:'',
                                                    video:null,
                                                    video_name:''
                                                })
                                                document.getElementById('video_file').value = null
                                            }
                                        } className='absolute right-0 top-0 text-white m-2 hover:text-[#5B5BD7]' type="button" >✖</button>
                                        <video controls src={video_url}></video>
                                    </div>
                                }
                            </div>
                            {
                                createAppealData.steps.currentStep === 3 &&
                                <div className="col-md-12">
                                    <small className='text-sm  text-white'>Yaşadığınız məkana uyğun addressi xəritə üzərində seçin</small>
                                    <MapObjectSelectLocation  position={position} setPosition={setPosition} />
                                </div>
                            }
                            {
                                createAppealData.steps.step3.status === statusEnum.failed && 
                                <>
                                    {/* {
                                        height < settingsData.data.min_height &&
                                        <div className="col-md-12 flex items-start gap-1 mt-2">
                                            <FontAwesomeIcon icon={faExclamationCircle} className='text-red-500 text-xs mt-0.5 ml-0.5'/>
                                            <p className='text-sm text-white' >Minimum boy ölçünüz {settingsData.data.min_height} sm olmalıdır.</p>
                                        </div>
                                    } */}
                                </>
                            }
                            {}

                            {
                                createAppealsData.submit_status === statusEnum.failed &&
                                <div className="col-md-12 flex flex-col w-full justify-center mt-2 items-center">
                                    {Object.keys(createAppealsData.submit_errors).map((key, index) => (
                                        <div key={index} className="w-full flex gap-4  items-center mt-4">
                                            <FontAwesomeIcon icon={faExclamationCircle} className='text-red-500'/>
                                            {
                                                createAppealsData.submit_errors[key].map((error:any, index:any) => (
                                                    <span className='text-white' key={index}>
                                                        {translateBackErrors(error)}
                                                    </span>
                                                ))
                                            }
                                        </div>
                                    ))}
                                </div>
                            }
                            
                            <div className='col-md-12 flex justify-end mt-5'>
                                <button
                                    onClick={backStep2}
                                    type="button"
                                    className="btn btn-primary font-weight-bold ml-2  hover:text-white"
                                >
                                    Əvvələ
                                </button>
                                
                                <button
                                    type="submit"
                                    className="btn btn-success text-white font-weight-bold ml-2  hover:text-white"
                                >
                                    Təsdiqlə
                                </button>
                            </div>
                            
                        </div>
                        

                        {/* <div className="form-group  col-md-6">
                            
                        </div> */}

                        {/* <div className="form-group  col-md-6">
                            
                        </div> */}

                        {/* <div className="form-group  col-md-6">
                            
                        </div> */}

                        {/* <div className="form-group  col-md-6">
                            
                        </div> */}

                        {/* <div className="form-group  col-md-6">
                            
                        </div> */}

                        {/* <div className="form-group  col-md-6">
                            
                        </div> */}

                        {/* <div className="form-group  col-md-6">
                            
                        </div> */}

                        
                        {/* <div className="form-group  col-md-6">
                            
                            <div className='w-full'>
                                <small>*Minimum çəkiniz {settingsData.data.min_weight} olmalıdır.</small>
                            </div>
                        </div> */}
                        
                        {/* <div className="form-group  col-md-6">
                            <label>Təvəllüdünüzü daxil edin.</label>
                            <CustomInput
                                required={true}
                                isFormSubmitStatus={isAllValidStatus}
                                emptyError={'Təvəllüdünüzü daxil edin.'}
                                value={birthday}
                                type='date'
                                maxDate={birthdayMaxLimit} 
                                onChange={(data) => {
                                    setCreateAppealData({
                                        ...createAppealData,
                                        birthday: data,
                                    })
                                }}
                            />
                            <div className='w-full'>
                                <small>*Minimum yaşınız {settingsData.data.min_age} olmalıdır.</small>
                            </div>
                        </div> */}

                        {/* <div className="form-group  col-md-6">
                            
                        </div> */}

                        {/* <div className="form-group  col-md-6">
                            <label>Haqqınızda olan video faylını yükləyin.</label>
                            <CustomInput
                                isFormSubmitStatus={isAllValidStatus}
                                emptyError={'Video faylını yükləyin.'}
                                value={video}
                                type='file'
                                fileMessage="Video faylını yükləyin."
                                onChange={(data) => {
                                    setCreateAppealData({
                                        ...createAppealData,
                                        video: data,
                                    })
                                }}
                                accept="video/*"
                            />
                        </div> */}

                        
                        
                        
                        {/*                     
                            <div className="row mt-5  col-md-12">
                                <div className="col-md-12 text-md-right">
                                    <button
                                        type="submit"
                                        className="btn btn-success font-weight-bold ml-2 bg-blue-400n-500 te3t-white hover:text-white"
                                    >
                                        Təsdiq et
                                    </button>
                                </div>
                            </div> 
                        */}

                        {
                            createAppealsData.submit_status === statusEnum.loading &&
                            <div className="col-md-12  flex items-center gap-2 justify-center">
                                <div className="animate-pulse  inline-block w-4 h-4  rounded-full bg-slate-100" >
                                </div>
                                <div className="animate-pulse inline-block w-4 h-4  rounded-full bg-slate-100" >
                                </div>
                                <div className="animate-pulse inline-block w-4 h-4  rounded-full bg-slate-100" >
                                </div>
                            </div>
                        }

                    </form>
                </div>
            }
        </div>
    )
}