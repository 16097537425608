import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { editNormativesThunk, viewNormativesThunk } from './Normatives.thunk'
import { setStatusPending, setStatusRejected, setStatusSucceeded } from 'app/__shared__/components/store/thunkStatusHandler'

import { RootState } from 'app/__shared__/components/store/store'
import { statusEnum } from 'app/__shared__/model/enums/statusLoading'

export interface INormativesEditState{
    status:statusEnum,
    data?:any,
    submit_status:statusEnum,
    submit_errors:any
} 

const EditNormativesState: INormativesEditState = {
    status: statusEnum.idle,
    submit_status:statusEnum.idle,
    data:null,
    submit_errors:null
}


export const EditNormativesSlice = createSlice({
  name: 'edit-normatives-slice',
  initialState: EditNormativesState,
  reducers: {
    resetNormativesEdit: (state:INormativesEditState) => {
        state.status = statusEnum.idle
        state.data = null
        state.submit_status = statusEnum.idle
        state.submit_errors = null
    }
      
  },
  
  extraReducers: (builder) => {
    builder.addCase(viewNormativesThunk.pending, (state:INormativesEditState, action: PayloadAction<any>) => {
        setStatusPending(state, action)
    })
    
    builder.addCase(viewNormativesThunk.rejected, (state:INormativesEditState, action:  PayloadAction<any>) => {
        setStatusRejected(state, action)
        state.data = null
    })
    
    builder.addCase(viewNormativesThunk.fulfilled, (state:INormativesEditState, action:  PayloadAction<any>) => {
        setStatusSucceeded(state, action)
        state.data = action.payload.data
    })



    builder.addCase(editNormativesThunk.pending, (state:INormativesEditState, action: PayloadAction<any>) => {
        state.submit_status = statusEnum.loading
    })
    
    builder.addCase(editNormativesThunk.rejected, (state:INormativesEditState, action:  PayloadAction<any>) => {
        state.submit_status = statusEnum.failed
        state.submit_errors = action.payload.errors
    })
    
    builder.addCase(editNormativesThunk.fulfilled, (state:INormativesEditState, action:  PayloadAction<any>) => {
        state.submit_status = statusEnum.succeeded
        setStatusSucceeded(state, action)
        state.data = action.payload.data
    })


    


    
  },
})


export const EditNormativeActions = {
    ...EditNormativesSlice.actions,
}


export const EditNormativesData = (state:RootState) => state.NormativesReducer.EditNormativesReducer
export const EditNormativesReducer = EditNormativesSlice.reducer