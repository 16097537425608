import { createBranchesService, deleteBranchesService, editBranchesService, listAllBranchesService, listBranchesService, viewBranchesService } from "app/__shared__/helpers/api/services/branches"

import { createAsyncThunk } from "@reduxjs/toolkit"

export const listBranchesThunk = createAsyncThunk("branches/list/paginated", listBranchesService)
export const listAllBranchesThunk = createAsyncThunk("branches/list/all", listAllBranchesService)
export const viewBranchesThunk = createAsyncThunk("branches/view", viewBranchesService)
export const editBranchesThunk = createAsyncThunk("branches/edit", editBranchesService)
export const createBranchesThunk = createAsyncThunk("branches/create", createBranchesService)
export const deleteBranchesThunk = createAsyncThunk("branches/delete", deleteBranchesService)

