import { Link, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'app/__shared__/components/store/store';

import { AppealsEditForm } from '../AppealsEditForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormLoader } from 'app/__shared__/components/common/form-loader/FormLoader';
import { faList } from '@fortawesome/free-solid-svg-icons';
import { statusEnum } from 'app/__shared__/model/enums/statusLoading';
import { useEffect } from 'react';
import { viewAppealsThunk } from '../slices/Appeals.thunk';

export const AppealsEdit = () => {
    const dispatch = useAppDispatch()
    const selectAppealsData = useAppSelector(state => state.AppealsReducer.SelectAppealsReducer)
    let { id } = useParams();
    
    useEffect(() => {
        dispatch(viewAppealsThunk({id:id}))
    }, [])

    return (
        <>
            <div className="bg-white rounded p-4">
                <div className="row mb-4">
                    <div className="col-md-12">
                        <div className="page-header">
                            <h1 className="font-weight-bold">Müraciət məlumatlarını yeniləyib təsdiqləyin.</h1>
                        </div>
                    </div>
                </div>
                
                {selectAppealsData.status === statusEnum.loading && <FormLoader/>}
                <div>
                    {
                        selectAppealsData.status === statusEnum.succeeded  &&
                        <>
                            <AppealsEditForm id={id} selectedAppealData={selectAppealsData}/>
                            <div className="row">
                                <div className="col-md-12 text-center">
                                    <Link
                                        to="/appeals"
                                        className='btn btn-light'
                                    >
                                        <FontAwesomeIcon icon={faList} className="mr-2" />
                                        Listə geri qayıt
                                    </Link>
                                </div>
                            </div>
                        </>
                    }

                    
                    {
                        selectAppealsData.status === statusEnum.failed &&
                        <div className="row">
                            <div className="col-md-12">
                                <div className="alert alert-warning">
                                    Məlumat tapılmadı
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </>
    )
}
 