export enum genderEnum {
    idle,
    male,
    female,
}

export enum militaryEnum {
    idle,
    has,
    no,
}

export enum disabilityEnum {
    idle,
    is,
    no,
}

export enum hasEnum {
    idle,
    yes,
    no,
}