import { useEffect, useState } from "react"

import { statusEnum } from "app/__shared__/model/enums/statusLoading"

export const useInputErrorHandle = ({isFormSubmitStatus , isBlured , isEmpty, isInValid , emptyError , invalidError, isClicked , value}: {
    isFormSubmitStatus?:statusEnum,
    isBlured?:boolean,
    isEmpty?:boolean,
    isInValid?:boolean,
    emptyError?:string,
    invalidError?:string,
    isClicked?:boolean,
    value?:string
}) => {
    const [error , setError] = useState<any>()
    const [errorClass , setErrorClass] = useState<any>()
    const [errorSpanText , setErrorSpanText] = useState<any>(true)
    
    useEffect(() => {
        if(isFormSubmitStatus === statusEnum.failed) {
            if(value === null || value === undefined || value === "" ) {
                setError(emptyError)
                setErrorSpanText(true)
                return  
            } else {

                if(isInValid) {
                    setErrorClass("")
                    setErrorSpanText("")
                    setError(false)
                } else {
                    setErrorSpanText(true)
                    setError(invalidError)
                    setErrorClass("is-invalid")
                }
            }
        }
        else {
            if(isClicked ) {
                if(!value) {
                    setError(emptyError)
                    setErrorSpanText(true)
                    setErrorClass("is-invalid")
                    return 
                } else {
                    if(isInValid) {
                        setErrorClass("")
                        setErrorSpanText("")
                        setError(false)
                    } else {
                        setErrorSpanText(true)
                        setError(invalidError)
                        setErrorClass("is-invalid")
                    }
                }
            }
        }
    }, [isBlured, isFormSubmitStatus])
    

    return ({error, errorClass, errorSpanText})
}