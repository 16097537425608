import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { RequestType } from 'app/__shared__/model/types/request'
import { RootState } from '../../__shared__/components/store/store'
import { UserType } from 'app/__shared__/model/types/user.type'
import { createUsersThunk } from './Users.thunk'
import { statusEnum } from 'app/__shared__/model/enums/statusLoading'

export type CreateUsersStateType = {} & RequestType<UserType | null>

const CreateUsersState: CreateUsersStateType = {
    status: statusEnum.idle,
    response:{
        data:null,
        errors: {},
    }
}


export const CreateUsersSlice = createSlice({
  name: 'create-users-slice',
  initialState: CreateUsersState,
  reducers: {
    resetUsersCreate: (state:CreateUsersStateType) => {
        state.status = statusEnum.idle
        state.response.data = null
        state.response.errors = {}
    }
      
  },
  
  extraReducers: (builder) => {
    
    builder.addCase(createUsersThunk.pending, (state, action: PayloadAction<any>) => {
        state.status = statusEnum.loading
    })
    
    builder.addCase(createUsersThunk.rejected, (state, action:  PayloadAction<any>) => {
        console.log(action.payload)
        state.status = statusEnum.failed
        state.response.errors = action.payload.errors
    })
    
    builder.addCase(createUsersThunk.fulfilled, (state, action:  PayloadAction<any>) => {
        state.status = statusEnum.succeeded
        state.response.data = action.payload.data
    })
    
  },
})


export const CreateUsersActions = {
    ...CreateUsersSlice.actions,
}


export const CreateUsersData = (state:RootState) => state.UsersReducer.CreateUsersReducer
export const CreateUsersReducer = CreateUsersSlice.reducer