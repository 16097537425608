// import { UserCreateForm } from "./UserCreateForm"
import { faCirclePlus} from "@fortawesome/free-solid-svg-icons"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useContext } from "react"
import { ModalContext } from "app/__shared__/components/context/modal/ModalContext"
import { NormativesCreateForm } from "./NormativesCreateForm"

export const NormativesAddButton = (props: any) => {
    const {toggleModalShow} = useContext(ModalContext)

    const handleClick = () => {
        toggleModalShow(<NormativesCreateForm/>, "Normativ məlumatlarını daxil edin.")  
    }


    return (
        <button onClick={handleClick} className="rounded-md px-4 py-2 bg-[#1A67B4] text-lg text-white btn gap-2"  >
            <span> Normativ əlavə et</span>
            <FontAwesomeIcon icon={faCirclePlus} className="ml-2" />
        </button>
    )

}