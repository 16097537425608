import { AXIOS_CONFIG } from "../config"
import { axiosInstance } from "../interceptor"
import { notifyError } from "app/__shared__/helpers/utils/notify/notifyError"

export interface PermissionResponseInterface {
    id: number,
    name: string,
    guard_name: string,
}



export type PermissionsType = PermissionResponseInterface[]




export const listPermissionsService = async ({rejectWithValue}:any) => {
    try {
        const response:any = await axiosInstance(AXIOS_CONFIG.PERMISSION.LIST_PERMISSION)
        return response.data
    } catch (error:any) {
        return rejectWithValue(error.response.data)
    }
}



export const viewPermissionsService = async (id:number , {rejectWithValue}:any) => {
    try {
        const response = await axiosInstance({...AXIOS_CONFIG.PERMISSION.SHOW_PERMISSION, params:{id}})
        return response.data
    } catch (error:any) {
        return rejectWithValue(error.response.data)
    }
}


export const createPermissionsService = async (data:any , {rejectWithValue}:any) => {
    try {
        const response = await axiosInstance({...AXIOS_CONFIG.PERMISSION.CREATE_PERMISSION, data})
        return response.data
    } catch (error:any) {
        return rejectWithValue(error.response.data)
    }
}


export const editPermissionsService = async (data:any , {rejectWithValue}:any) => {
    try {
        const response = await axiosInstance(AXIOS_CONFIG.PERMISSION.UPDATE_PERMISSION)
        return response.data
    } catch (error:any) {
        return rejectWithValue(error.response.data)
    }
}


export const deletePermissionsService = async (id:number , {rejectWithValue}:any) => {
    try {
        const response = await axiosInstance({...AXIOS_CONFIG.PERMISSION.DELETE_PERMISSION, params:{id}})
        return response.data
    } catch (error:any) {
        return rejectWithValue(error.response.data)
    }
}
