import {AuthReducer} from '../../../auth/Auth.slice'
import { PermissionReducer } from '../../../permissions/Permissions.slice';
import { VacancySettingsReducer } from '../../../vacancy-settings/slices/VacancySettings.slice';
import { combineReducers } from "@reduxjs/toolkit";
import { AppealsReducer } from 'app/appeals/slices/Appeals.reducer';
import { BranchesReducer } from 'app/branches/slices/Branches.reducer';
import { NormativesReducer } from 'app/normatives/slices/Normatives.reducer';
import { RolesReducer } from 'app/roles/slices/Roles.reducer';
import { UsersReducer } from 'app/users/slices/Users.reducer';

export const rootReducer = combineReducers({
    AuthReducer: AuthReducer,
    PermissionReducer:PermissionReducer,
    UsersReducer:UsersReducer,
    RolesReducer:RolesReducer,
    VacancySettingsReducer:VacancySettingsReducer,
    NormativesReducer:NormativesReducer,
    BranchesReducer:BranchesReducer,
    AppealsReducer:AppealsReducer,
})

