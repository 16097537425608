import { createAsyncThunk } from "@reduxjs/toolkit"
import { getMeService } from "app/__shared__/helpers/api/services/auth/me"
import { loginService } from "app/__shared__/helpers/api/services/auth/login"
import { logoutService } from "app/__shared__/helpers/api/services/auth/logout"
import { refreshTokenService } from "app/__shared__/helpers/api/services/auth/refreshToken"

export const loginThunk = createAsyncThunk("auth/login", loginService)

export const meThunk = createAsyncThunk("auth/me", getMeService)

export const refreshTokenThunk = createAsyncThunk("auth/refreshToken", refreshTokenService)     

export const logoutThunk = createAsyncThunk("auth/logout", logoutService)     

