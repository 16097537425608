import { AXIOS_CONFIG } from "../config"
import { PaginationType } from "app/__shared__/model/types/pagination.type"
import { axiosInstance } from "../interceptor"

export const listBranchesService = async (pagination:PaginationType,{rejectWithValue}:any) => {
    try {
        AXIOS_CONFIG.BRANCH.LIST_BRANCH.url =  `/branches?type=list&per_page=${pagination.pageLimit}&page=${pagination.currentPage}&totalPages=${pagination.totalPages}`
        const response = await axiosInstance({...AXIOS_CONFIG.BRANCH.LIST_BRANCH})
        return response.data
    } catch (error:any) {
        return rejectWithValue(error.response.data)
    }
}

export const listAllBranchesService = async ({rejectWithValue}:any) => {
    try {
        const response = await axiosInstance(AXIOS_CONFIG.BRANCH.LIST_ALL_BRANCH)
        return response.data
    } catch (error:any) {
        return rejectWithValue(error.response.data)
    }
}

export const viewBranchesService = async (data:any ,{rejectWithValue}:any) => {
    try {
        const response = await axiosInstance(`branches/${data.id}`, AXIOS_CONFIG.BRANCH.SHOW_BRANCH)
        return response.data
    } catch (error:any) {
        return rejectWithValue(error.response.data)
    }
}

export const createBranchesService = async (data:any, {rejectWithValue}:any) => {
    try {
        const config:any = AXIOS_CONFIG.BRANCH.CREATE_BRANCH
        config.data = data.data
        const response = await axiosInstance(config)
        return response.data
    } catch (error:any) {
        return rejectWithValue(error.response.data)
    }
}

export const editBranchesService = async (data:any, {rejectWithValue}:any) => {
    try {
        const response = await axiosInstance(`branches/${data.id}` , {...AXIOS_CONFIG.BRANCH.UPDATE_BRANCH, data:data.data})
        return response.data
    } catch (error:any) {
        return rejectWithValue(error.response.data)
    }
}

export const deleteBranchesService = async (data:any, {rejectWithValue}:any) => {
    try {
        const response = await axiosInstance(`/branches/${data.id}`, AXIOS_CONFIG.BRANCH.DELETE_BRANCH)
        return response.data
    } catch (error:any) {
        return rejectWithValue(error.response.data)
    }
}


