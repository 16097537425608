import { useAppDispatch, useAppSelector } from "../__shared__/components/store/store"

import { RolesCustomTableButton } from "./RolesCustomTableButton"
import { TableLoader } from "app/__shared__/components/common/loader/TableLoader"
import { listRolesThunk } from "./slices/Roles.thunk"
import { useEffect } from "react"

interface ICustomTableProps {
}

export const RolesCustomTable = (props:ICustomTableProps) => {
    
    const listRolesResponse = useAppSelector(state => state.RolesReducer.ListRolesReducer)
    const listRoles  = listRolesResponse.response
    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(listRolesThunk({}))
    }, [dispatch])
    
   
    return(
        <div className='flex-col w-full mt-10'>
                <table className="table table-striped">
                    <thead>
                        <tr>
                        <th scope="col">ID</th>
                        <th scope="col">Rol Adı</th>
                        <th scope="col">Əməliyyat</th>
                        </tr>
                    </thead>
                    <tbody>
                        <TableLoader status={listRolesResponse.status}>
                            {
                                listRoles.data.map((element) => 
                                    <tr key={element.id}>
                                        <th scope="row">
                                            {element.id}
                                        </th>
                                        <td >
                                            {element.name}
                                        </td>
                                        <td>
                                            <RolesCustomTableButton id={element.id}/>
                                        </td>
                                    </tr>
                            )}
                        </TableLoader>
                    </tbody>
                </table>
        </div>
    )
}


