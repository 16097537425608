import { Link, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'app/__shared__/components/store/store';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormLoader } from 'app/__shared__/components/common/form-loader/FormLoader';
import { faList } from '@fortawesome/free-solid-svg-icons';
import { statusEnum } from 'app/__shared__/model/enums/statusLoading';
import { useEffect } from 'react';
import { viewRolesThunk } from '../slices/Roles.thunk';

export const RolesView = () => {
    const dispatch = useAppDispatch()
    const selectRoles = useAppSelector(state => state.RolesReducer.SelectRolesReducer)
    let { id } = useParams();

    useEffect(() => {
        dispatch(viewRolesThunk({id}))
    }, [])
    

    return (
        <div className="bg-white rounded p-4">
            
            <div className="row mb-4">
                <div className="col-md-12">
                    <div className="page-header">
                        <h1 className="font-weight-bold">Baxış</h1>
                    </div>
                </div>
            </div>
            
            {selectRoles.status === statusEnum.loading && <FormLoader/>}
            <form>
                {
                    selectRoles.status === statusEnum.succeeded  &&
                    <>
                        <div className="row mb-4 pt-2">
                            <div className="col-md-4 mb-2">
                                <label className="text-muted">ID</label>
                                <input
                                    name="id"
                                    placeholder="id"
                                    disabled
                                    defaultValue={selectRoles.response.data.id}
                                    className="form-control"
                                />
                            </div>
                            <div className="col-md-4 mb-2">
                                <label>Ad</label>
                                <input
                                    name="name"
                                    placeholder="Ad "
                                    disabled
                                    defaultValue={selectRoles.response.data.name}
                                    className="form-control"
                                />
                            </div>
                            
                        </div>
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                <th scope="col">ID</th>
                                <th scope="col">İcazələr</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    selectRoles.response.data.permissions.map((permission:any, index:any) => {
                                        return (
                                            <tr key={index}>
                                                <th scope="row">{permission.id}</th>
                                                <td>{permission.title}</td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                        <div className="row">
                            <div className="col-md-12 text-center">
                                <Link
                                    to="/roles"
                                    className='btn btn-light'
                                >
                                    <FontAwesomeIcon icon={faList} className="mr-2" />
                                    Listə geri qayıt
                                </Link>
                            </div>
                        </div>
                    </>
                }

                
                {
                    selectRoles.status === statusEnum.failed &&
                    <div className="row">
                        <div className="col-md-12">
                            <div className="alert alert-warning">
                                Məlumat tapılmadı
                            </div>
                        </div>
                    </div>
                }
            </form>
        </div>
    )
}
 