import {
    Form,
    Input,
    Label,
} from 'reactstrap';
import { Link, useParams } from 'react-router-dom';
import { faList,  } from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { statusEnum } from 'app/__shared__/model/enums/statusLoading';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'app/__shared__/components/store/store';
import { FormLoader } from 'app/__shared__/components/common/form-loader/FormLoader';
import { viewAppealsThunk } from '../slices/Appeals.thunk';
import { AppealsCreateForm } from '../AppealsCreateForm';

export const AppealsCreate = () => {
    const dispatch = useAppDispatch()
    const selectAppealsData = useAppSelector(state => state.AppealsReducer.SelectAppealsReducer)
    let { id } = useParams();

    useEffect(() => {
        dispatch(viewAppealsThunk({id:id}))
    }, [])
    

    return (
        <>
                <div className="bg-white rounded p-4">
                    <div className="row mb-4">
                        <div className="col-md-12">
                            <div className="page-header">
                                <h1 className="font-weight-bold">Müraciət Məlumatlarını daxil edib təsdiqləyin.</h1>
                            </div>
                        </div>
                    </div>
                    
                    {selectAppealsData.status === statusEnum.loading && <FormLoader/>}
                    <div>
                        {
                            selectAppealsData.status === statusEnum.succeeded  &&
                            <>
                                <AppealsCreateForm/>
                                <div className="row">
                                    <div className="col-md-12 text-center">
                                        <Link
                                            to="/appeals"
                                            className='btn btn-light'
                                        >
                                            <FontAwesomeIcon icon={faList} className="mr-2" />
                                            Listə geri qayıt
                                        </Link>
                                    </div>
                                </div>
                            </>
                        }

                        
                        {
                            selectAppealsData.status === statusEnum.failed &&
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="alert alert-warning">
                                       Məlumat tapılmadı
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </>
    )
}
 