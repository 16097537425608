import { createAppealsService, deleteAppealsService, editAppealsService, listAppealsService, rejectAppealsService, sendMeetForAppealsService, sendResultForAppealsService, viewAppealsService } from "app/__shared__/helpers/api/services/appeals"

import { createAsyncThunk } from "@reduxjs/toolkit"

export const listAppealsThunk = createAsyncThunk("appeals/list", listAppealsService)
export const viewAppealsThunk = createAsyncThunk("appeals/view", viewAppealsService)
export const editAppealsThunk = createAsyncThunk("appeals/edit", editAppealsService)
export const createAppealsThunk = createAsyncThunk("appeals/create", createAppealsService)
export const deleteAppealsThunk = createAsyncThunk("appeals/delete", deleteAppealsService)
export const rejectAppealsThunk = createAsyncThunk("appeals/reject", rejectAppealsService)
export const sendMeetForAppealsThunk = createAsyncThunk("appeals/send-meet", sendMeetForAppealsService)
export const sendResultForAppealsThunk = createAsyncThunk("appeals/send-result", sendResultForAppealsService)

