import React, { useContext, useEffect, useState } from "react"
import { createNormativesThunk, listNormativesThunk } from './slices/Normatives.thunk';
import { faExclamationCircle, faSpinner } from "@fortawesome/free-solid-svg-icons"
import { useAppDispatch, useAppSelector } from 'app/__shared__/components/store/store';

import { CreateNormativesActions } from './slices/NormativesCreate.slice';
import { CustomInput } from 'app/__shared__/components/common/input/CustomInput';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ModalContext } from 'app/__shared__/components/context/modal/ModalContext';
import Select from 'react-select';
import Swl from 'sweetalert2';
import { statusEnum } from "app/__shared__/model/enums/statusLoading"
import toast from "react-hot-toast";

export const NormativesCreateForm = () => {
    const createNormativesData = useAppSelector(state => state.NormativesReducer.CreateNormativesReducer)
    const {toggleModalClose} = useContext(ModalContext)
    const dispatch = useAppDispatch()

    const [formData, setFormData] = useState<{
        name: string,
        status: {
            id: number,
            label: string
        },
        isAllValidStatus: statusEnum,
    }>({
        name: '',
        status: {id: 0 , label: 'Deaktiv'},
        isAllValidStatus:statusEnum.idle
    })
    const {name, status, isAllValidStatus} = formData

    useEffect(() => {
        if(createNormativesData.submit_status === statusEnum.succeeded){
            toggleModalClose()   
            dispatch(listNormativesThunk({}))
            toast.success('Normativ bazaya əlavə edildi!')
            dispatch(CreateNormativesActions.resetNormativesCreate())
        }
    }, [createNormativesData.submit_status])

   

    const handleSubmit = (e:React.FormEvent) => {
        e.preventDefault()
        if(!name){
            setFormData({...formData, isAllValidStatus: statusEnum.failed})
            return 
        }else{
            setFormData({...formData, isAllValidStatus: statusEnum.succeeded})
        }


        dispatch(createNormativesThunk(
            {
                data:{
                    name,
                    status: status.id,
                }
            }
        ))

        
        
    }
    
    return (
        <div>
            {
                <form  className="max-w-lg" onSubmit={handleSubmit}>
                    <div className="row pt-2">
                        
                        <div className="col-md-12 mb-4">
                            <label className="font-weight-bold">Ad</label>
                            <CustomInput
                                required={true}
                                isFormSubmitStatus={isAllValidStatus}
                                emptyError={'Normativ adını daxil edin.'}
                                value={name}
                                type='text'
                                placeHolder='Normativ adını daxil edin.'
                                onChange={
                                    (data) => setFormData({
                                        ...formData,
                                        name: data
                                    })
                                }
                            />
                        </div>
                        
                        <div className="col-md-12 mb-4">
                            <label>Status</label>
                            <Select
                                placeholder="Status"
                                value={status}
                                options={[{id:1, label:"Aktiv"}, {id:0, label:"Deaktiv"}]}
                                getOptionValue={(option:any) => option.id}
                                getOptionlabel={(option:any) => option.label}
                                onChange={(e:any) => {
                                    setFormData({
                                        ...formData,
                                        status: e
                                    })
                                }}
                            />
                        </div>
                        
                        
                        
                    </div>
                    <div className="row">
                        <div className="col-md-12 text-md-right">
                            <button
                                type="button"
                                onClick={toggleModalClose}
                                className="btn btn-primary font-weight-bold ml-2 bg-gray-500 text-white"
                            >
                                Ləğv et
                            </button>
                            <button
                                type="submit"
                                color="success"
                                className="btn btn-success font-weight-bold ml-2 bg-green-500 text-white hover:text-white"
                            >
                                Təsdiq et
                            </button>
                        </div>
                    </div>
                    {
                        createNormativesData.submit_status === statusEnum.loading &&
                        <div className="flex w-full justify-center mt-2 items-center">
                            <div className="flex gap-4  items-center mt-4">
                                <FontAwesomeIcon icon={faSpinner} className='animate-spin'/>
                                <span>Yüklənir</span>
                            </div>
                        </div>
                    }

                    {
                        createNormativesData.submit_status === statusEnum.failed &&
                        <div className="flex w-full justify-center mt-2 items-center">
                            {Object.keys(createNormativesData.submit_errors).map((key, index) => (
                                <div key={index} className="flex gap-4  items-center mt-4">
                                    <FontAwesomeIcon icon={faExclamationCircle} className='text-red-500'/>
                                    {
                                        createNormativesData.submit_errors[key].map((error:any, index:any) => (
                                            <span key={index}>{error}</span>
                                        ))
                                    }
                                </div>
                            ))}
                        </div>
                    }
                </form>
            }

        </div>
    )
}