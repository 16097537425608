import { Link, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faList } from '@fortawesome/free-solid-svg-icons';
import { statusEnum } from 'app/__shared__/model/enums/statusLoading';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'app/__shared__/components/store/store';
import { viewNormativesThunk } from '../slices/Normatives.thunk';
import { FormLoader } from 'app/__shared__/components/common/form-loader/FormLoader';

export const NormativesView = () => {
    const dispatch = useAppDispatch()
    const selectNormativesData = useAppSelector(state => state.NormativesReducer.SelectNormativesReducer)
    let { id } = useParams();

    useEffect(() => {
        dispatch(viewNormativesThunk({id}))
    }, [])
    

    return (
        <>
                <div className="bg-white rounded p-4">
                    <div className="row mb-4">
                        <div className="col-md-12">
                            <div className="page-header">
                                <h1 className="font-weight-bold">Baxış</h1>
                            </div>
                        </div>
                    </div>
                    
                    {selectNormativesData.status === statusEnum.loading && <FormLoader/>}
                    <form>
                        {
                            selectNormativesData.status === statusEnum.succeeded  &&
                            <>
                                <div className="row mb-4 pt-2">
                                    <div className="col-md-4 mb-2">
                                        <label className="text-muted">ID</label>
                                        <input
                                            name="id"
                                            placeholder="id"
                                            disabled
                                            className="form-control"
                                            defaultValue={selectNormativesData.data.id}
                                        />
                                    </div>
                                    <div className="col-md-4 mb-2">
                                        <label>Ad</label>
                                        <input
                                            name="name"
                                            placeholder="Ad "
                                            disabled
                                            className="form-control"
                                            defaultValue={selectNormativesData.data.name}
                                        />
                                    </div>

                                    <div className="col-md-4 mb-2">
                                        <label>Status</label>
                                        <input
                                            name="name"
                                            placeholder="Ad "
                                            disabled
                                            className="form-control"
                                            defaultValue={selectNormativesData.data.status ? 'Aktiv' : 'Deaktiv'}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 text-center">
                                        <Link
                                            to="/normatives"
                                            className='btn btn-light'
                                        >
                                            <FontAwesomeIcon icon={faList} className="mr-2" />
                                            Listə geri qayıt
                                        </Link>
                                    </div>
                                </div>
                            </>
                        }

                        
                        {
                            selectNormativesData.status === statusEnum.failed &&
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="alert alert-warning">
                                       Məlumat tapılmadı
                                    </div>
                                </div>
                            </div>
                        }
                    </form>
                </div>
            </>
    )
}
 