import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { editVacancySettingsThunk, viewVacancySettingsThunk } from './VacancySettings.thunk'
import { setStatusPending, setStatusRejected, setStatusSucceeded } from '../../__shared__/components/store/thunkStatusHandler'

import { RootState } from '../../__shared__/components/store/store'
import { statusEnum } from 'app/__shared__/model/enums/statusLoading'

export interface IVacancySettingsState {
    status:statusEnum,
    data:any,
    update_status:statusEnum,
    update_errors:any,
} 

const VacancyRolesState: IVacancySettingsState = {
    status: statusEnum.idle,
    data: {},
    update_status: statusEnum.idle,
    update_errors: {},
}


export const VacancySettingsSlice = createSlice({
  name: 'vacancy-settings-slice',
  initialState: VacancyRolesState,
  reducers: {
    resetUpdate: (state:IVacancySettingsState) => {
        state.update_status = statusEnum.idle
        state.update_errors = {}
    }
      
  },
  
  extraReducers: (builder) => {
    builder.addCase(viewVacancySettingsThunk.pending, (state:IVacancySettingsState, action: PayloadAction<any>) => {
        setStatusPending(state, action)
    })
    
    builder.addCase(viewVacancySettingsThunk.rejected, (state:IVacancySettingsState, action:  PayloadAction<any>) => {
        setStatusRejected(state, action)
        state.data = {}
    })
    
    builder.addCase(viewVacancySettingsThunk.fulfilled, (state:IVacancySettingsState, action:  PayloadAction<any>) => {
        setStatusSucceeded(state, action)
        state.data = action.payload.data
    })


    builder.addCase(editVacancySettingsThunk.pending, (state:IVacancySettingsState, action: PayloadAction<any>) => {
        state.update_status = statusEnum.loading
        state.update_errors = {}
    })
    
    builder.addCase(editVacancySettingsThunk.rejected, (state:IVacancySettingsState, action:  PayloadAction<any>) => {
        state.update_status = statusEnum.failed
        state.update_errors = action.payload.errors
    })
    
    builder.addCase(editVacancySettingsThunk.fulfilled, (state:IVacancySettingsState, action:  PayloadAction<any>) => {
        state.update_status = statusEnum.succeeded
        state.data = action.payload
        console.log(action.payload)
        state.update_errors = {}
    })

    
  },
})


export const VacancySettingsActions = {
    ...VacancySettingsSlice.actions,
}


export const VacancySettingsData = (state:RootState) => state.VacancySettingsReducer

export const VacancySettingsReducer = VacancySettingsSlice.reducer