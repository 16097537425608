import { AuthData } from "app/auth/Auth.slice";
import { NormativesAddButton } from "../NormativesAddButton"
import { NormativesCustomTable } from "../NormativesCustomTable"
import { VACANCY_SETTINGS_PERMISSIONS } from "app/__shared__/config/permissions";
import { useAppSelector } from "app/__shared__/components/store/store";

export const NormativesList = () => {
    const {user:{permissions}} = useAppSelector(AuthData);

    
    return (
        <div className="bg-white rounded-md p-6">
            
            <div className="w-full flex justify-between items-center">
                <h1 className="text-4xl align-top">Normativlər</h1>
                { permissions[VACANCY_SETTINGS_PERMISSIONS.update] &&  <NormativesAddButton/>}
            </div>

            <NormativesCustomTable/>
        </div>
    )
}
 