import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { setStatusPending, setStatusRejected, setStatusSucceeded } from '../../__shared__/components/store/thunkStatusHandler'

import { RootState } from '../../__shared__/components/store/store'
import { createNormativesThunk } from './Normatives.thunk'
import { statusEnum } from 'app/__shared__/model/enums/statusLoading'

interface INormativesCreateState {
    status:statusEnum,
    data?:any,
    submit_status:statusEnum,
    submit_errors:any
} 

const NormativesCreateState: INormativesCreateState = {
    status: statusEnum.idle,
    submit_status:statusEnum.idle,
    data:null,
    submit_errors:null
}


export const CreateNormativesSlice = createSlice({
  name: 'create-normatives-slice',
  initialState: NormativesCreateState,
  reducers: {
    resetNormativesCreate: (state:INormativesCreateState) => {
        state.status = statusEnum.idle
        state.data = null
        state.submit_status = statusEnum.idle
        state.submit_errors = null
    }
      
  },
  
  extraReducers: (builder) => {
    
    builder.addCase(createNormativesThunk.pending, (state:INormativesCreateState, action: PayloadAction<any>) => {
        state.submit_status = statusEnum.loading
        setStatusPending(state, action)
    })
    
    builder.addCase(createNormativesThunk.rejected, (state:INormativesCreateState, action:  PayloadAction<any>) => {
        state.submit_status = statusEnum.failed
        state.submit_errors = action.payload.errors

    })
    
    builder.addCase(createNormativesThunk.fulfilled, (state:INormativesCreateState, action:  PayloadAction<any>) => {
        state.submit_status = statusEnum.succeeded
        setStatusSucceeded(state, action)
        state.data = action.payload.data
    })


    
  },
})


export const CreateNormativesActions = {
    ...CreateNormativesSlice.actions,
}


export const CreateNormativesData = (state:RootState) => state.NormativesReducer.CreateNormativesReducer
export const CreateNormativesReducer = CreateNormativesSlice.reducer