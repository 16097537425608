import React, { useContext, useEffect, useState } from "react"
import { editNormativesThunk, listNormativesThunk, viewNormativesThunk } from "./slices/Normatives.thunk";
import { faExclamationCircle, faSpinner } from "@fortawesome/free-solid-svg-icons"
import { useAppDispatch, useAppSelector } from "app/__shared__/components/store/store";

import { CustomInput } from "app/__shared__/components/common/input/CustomInput";
import { EditFormLoader } from "app/__shared__/components/common/form-loader/EditFormLoader";
import { EditNormativeActions } from "./slices/NormativesEdit.slice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ModalContext } from "app/__shared__/components/context/modal/ModalContext";
import Select from 'react-select';
import Swl from 'sweetalert2';
import { listPermissionsThunk } from "app/permissions/Permissions.thunk";
import { statusEnum } from "app/__shared__/model/enums/statusLoading"
import toast from "react-hot-toast";

interface INormativeEditForm {
    id:number
}

export const NormativesEditForm = ({id}:INormativeEditForm) => {
    const editNormativesData = useAppSelector(state => state.NormativesReducer.EditNormativesReducer)
    const {toggleModalClose} = useContext(ModalContext)
    const dispatch = useAppDispatch()

    const [formData, setFormData] = useState<{
        name: string,
        status: {
            id: number,
            label: string
        },
        isAllValidStatus: statusEnum,
    }>({
        name: '',
        status: {id: 0 , label: 'Deaktiv'},
        isAllValidStatus:statusEnum.idle
    })

    const {name, status, isAllValidStatus} = formData

    useEffect(() => {
        dispatch(viewNormativesThunk({id}))
        dispatch(listPermissionsThunk({}))
    }, [])

    useEffect(() => {
        if(editNormativesData.status === statusEnum.succeeded){
            console.log(editNormativesData.data.status)
            const status = {
                id: editNormativesData.data.status,
                label: editNormativesData.data.status === 1 ? 'Aktiv' : 'Deaktiv'
            }
            setFormData({
                ...formData, 
                name: editNormativesData.data.name, 
                status: status,
                isAllValidStatus: statusEnum.succeeded  
            })
        }
    }, [editNormativesData.status])

    useEffect(() => {
        if(editNormativesData.submit_status === statusEnum.succeeded){
            toggleModalClose()   
            dispatch(listNormativesThunk({}))
            toast.success('Normativ məlumatları yeniləndi!')
            dispatch(EditNormativeActions.resetNormativesEdit())
        }
    }, [editNormativesData.submit_status])

    const handleSubmit = (e:React.FormEvent) => {
        e.preventDefault()
        if(!name){
            setFormData({...formData, isAllValidStatus: statusEnum.failed})
            return 
        }else{
            setFormData({...formData, isAllValidStatus: statusEnum.succeeded})
        }


        dispatch(editNormativesThunk(
            {
                id: editNormativesData.data.id,
                data:{
                    name,
                    status:status.id
                }
            }
        ))

        
        
    }

    
    return (
        <div>
            {
                (editNormativesData.status === statusEnum.succeeded) &&
                <form  className="max-w-lg" onSubmit={handleSubmit}>
                    <div className="row pt-2">
                        <div className="col-md-6 mb-4">
                            <label className="text-muted">ID</label>
                            <input
                                disabled
                                name="id"
                                value={editNormativesData.data.id}
                                className='form-control'
                            />
                        </div>
                        <div className="col-md-6 mb-4">
                            <label  className="font-weight-bold">Ad</label>
                            <CustomInput
                                required={true}
                                isFormSubmitStatus={isAllValidStatus}
                                emptyError={'Normativ adını daxil edin.'}
                                value={name}
                                type='text'
                                placeHolder='Normativ adını daxil edin.'
                                onChange={
                                    (data)=> setFormData({
                                        ...formData,
                                        name: data
                                    })
                                }
                            />
                        </div>
                        
                        
                        <div className="col-md-12 mb-4">
                            <label>Status</label>
                            <Select
                                placeholder="Status"
                                value={status}
                                options={[{id:1, label:"Aktiv"}, {id:0, label:"Deaktiv"}]}
                                getOptionValue={(option:any) => option.id}
                                getOptionLabel={option => option.label}
                                onChange={(e:any) => {
                                    setFormData({
                                        ...formData,
                                        status: e
                                    })
                                }}
                            />
                        </div>

                    </div>
                    <div className="row">
                        <div className="col-md-12 text-md-right">
                            <button
                                type="button"
                                onClick={toggleModalClose}
                                className="btn btn-primary font-weight-bold ml-2 bg-gray-500 text-white"
                            >
                                Ləğv et
                            </button>
                            <button
                                type="submit"
                                className="btn btn-success  font-weight-bold ml-2 bg-green-500 text-white hover:text-white"
                            >
                                Təsdiq et
                            </button>
                        </div>
                    </div>
                    {
                        editNormativesData.submit_status === statusEnum.loading &&
                        <div className="flex w-full justify-center mt-2 items-center">
                            <div className="flex gap-4  items-center mt-4">
                                <FontAwesomeIcon icon={faSpinner} className='animate-spin'/>
                                <span>Yüklənir</span>
                            </div>
                        </div>
                    }

                    {
                        editNormativesData.submit_status === statusEnum.failed &&
                        <div className="flex w-full justify-center mt-2 items-center">
                            {Object.keys(editNormativesData.submit_errors).map((key, index) => (
                                <div key={index} className="flex gap-4  items-center mt-4">
                                    <FontAwesomeIcon icon={faExclamationCircle} className='text-red-500'/>
                                    {
                                        editNormativesData.submit_errors[key].map((error:any, index:any) => (
                                            <span key={index}>{error}</span>
                                        ))
                                    }
                                </div>
                            ))}
                        </div>
                    }
                </form>
            }

            {
                editNormativesData.status === statusEnum.failed &&
                <div className="row">
                    <div className="col-md-12">
                        <div className="alert alert-warning">
                            Məlumat tapılmadı
                        </div>
                    </div>
                </div>
            }

            {
                editNormativesData.status === statusEnum.loading &&
                <EditFormLoader/>
            }
        </div>
    )
}