import { statusEnum } from "app/__shared__/model/enums/statusLoading"

interface ISectionBasedStatusProps {
    loader_component:any,
    status:statusEnum,
    children:any,
}


export const SectionBasedStatus = (props:ISectionBasedStatusProps) => {
    
    if(props.status === statusEnum.loading){
        return props.loader_component
    }
    if(props.status === statusEnum.succeeded){
        return props.children
    }
    if(props.status === statusEnum.failed){
        return (
            <div className="row">
                <div className="col-md-12">
                    <div className="alert alert-warning">
                    Məlumat tapılmadı
                    </div>
                </div>
            </div>
        )
    }
    else{
        return (
            <div className="row">
                <div className="col-md-12">
                    <div className="alert alert-warning">
                    Məlumat tapılmadı
                    </div>
                </div>
            </div>
        )
    }
    
}