import { faExclamationCircle, faWarning } from "@fortawesome/free-solid-svg-icons";
import { sendResultForAppealsThunk, viewAppealsThunk } from "./slices/Appeals.thunk";
import { useAppDispatch, useAppSelector } from "app/__shared__/components/store/store";
import { useContext, useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ModalContext } from "app/__shared__/components/context/modal/ModalContext";
import ReactStars from 'react-stars'
import { SelectAppealsActions } from "./slices/AppealsSelect.slice";
import Swl from "sweetalert2";
import { listNormativesThunk } from "app/normatives/slices/Normatives.thunk";
import { statusEnum } from "app/__shared__/model/enums/statusLoading"
import toast from "react-hot-toast";
import { yesNoEnum } from "app/__shared__/model/enums/yesNoEnum";

export const AppealsResultForm = (props:any) => {
    const {id} = props;
    const listNormatives = useAppSelector(state => state.NormativesReducer.ListNormativesReducer)
    const selectAppealReducer = useAppSelector(state => state.AppealsReducer.SelectAppealsReducer)
    const {toggleModalClose} = useContext(ModalContext)
    const dispatch = useAppDispatch()

    const [formData, setFormData] = useState<{
        normatives:any
        isFormSubmitStatus:statusEnum,
        result:yesNoEnum
    }>({
        normatives:[],
        isFormSubmitStatus:statusEnum.idle,
        result:yesNoEnum.no
    })

    const {normatives, isFormSubmitStatus, result} = formData

    const starSelect = (id:number, count:number) => {
        setFormData({
            ...formData,
            normatives: normatives.map((item:any) => {
                if(item.id === id) {
                    return {
                        ...item,
                        starCount: count
                    }
                }
                return item
            })
        })
    }

    const handleSubmit = (e:any) => {
        e.preventDefault();

        const nonRated = formData.normatives.filter((item:any) => item.id && !item.starCount)
        if(nonRated.length)
        {
            setFormData({
                ...formData,
                isFormSubmitStatus:statusEnum.failed
            })
        }else{
            setFormData({
                ...formData,
                isFormSubmitStatus:statusEnum.succeeded
            })
            const data = {
                type:"RESULT",
                result:result,
                normatives:[
                    ...normatives.map((item:any) => {
                        return {    
                            id:item.id,
                            starCount:item.starCount,
                            name:item.name,
                        }
                    })
                ]
            }
            dispatch(sendResultForAppealsThunk({data, id}))
            
        }
        
    }





    useEffect(() => {
        if(listNormatives.status === statusEnum.idle) {
            dispatch(listNormativesThunk({}))
        }
    }, [listNormatives.status , dispatch])


    useEffect(() => {
        if(selectAppealReducer.send_result.status === statusEnum.succeeded) {
            (async ()=> {
                toggleModalClose()
                await dispatch(SelectAppealsActions.resetAppealsSendResult())
                dispatch(viewAppealsThunk({id}))
                toast.success('Normativ məlumatları göndərildi!',);
            })()
        }
    }, [dispatch, selectAppealReducer.send_result.status , toggleModalClose])

    useEffect(() => {
        if(listNormatives.status === statusEnum.succeeded) {
            setFormData({
                ...formData,
                normatives: listNormatives.response.data,
            })
        }
    }, [dispatch, listNormatives.status, listNormatives.response.data])


    return(
        <form onSubmit={handleSubmit}>
            {
                normatives.map((item:any) => {
                    return(
                        <div className="form-group">
                            <input type="text" className='form-control' value={item.name} disabled/>
                            <ReactStars
                                count={5}
                                value={item.starCount}
                                onChange={(new_rating) => starSelect(item.id, new_rating)}
                                size={24}
                                color2={'#ffd700'} 
                            />
                        </div>  
                    )           
                })
            }

            <div className="form-group flex gap-2 items-center">
                <label className="mb-0">Namizədi qəbul edin</label>
                <input type="checkbox" checked={result === yesNoEnum.yes ? true : false} value={result} onChange={
                    (e:any) => {
                        setFormData({
                            ...formData,
                            result: e.target.value === yesNoEnum.yes ? yesNoEnum.no : yesNoEnum.yes
                        })
                    }
                }/>
            </div>


            <div className="form-group text-red-600">
                <small className="flex gap-1 items-center">{isFormSubmitStatus === statusEnum.failed && 
                    <>
                        <FontAwesomeIcon icon={faExclamationCircle}/>
                        <span>
                            Bütün normativlər qiymətləndirilməlidir
                        </span>
                    </>
                }
                </small>
            </div>


            <div className="flex justify-end">
                <button className="btn btn-success font-weight-bold ml-2 bg-green-500 text-white hover:text-white">
                    Təsdiq et
                </button>
            </div>
            
        </form>
    )
}