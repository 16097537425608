import { combineReducers } from "@reduxjs/toolkit";
import { CreateAppealsReducer } from "./AppealsCreate.slice";
import { EditAppealsReducer } from "./AppealsEdit.slice";
import { ListAppealsReducer } from "./AppealsList.slice";
import { SelectAppealsReducer } from "./AppealsSelect.slice";


export const AppealsReducer = combineReducers({
    ListAppealsReducer:ListAppealsReducer,
    CreateAppealsReducer:CreateAppealsReducer,
    EditAppealsReducer:EditAppealsReducer,
    SelectAppealsReducer:SelectAppealsReducer,
})

