import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { deleteUsersThunk, listUsersThunk } from './Users.thunk'

import { PaginationType } from 'app/__shared__/model/types/pagination.type'
import { RequestType } from 'app/__shared__/model/types/request'
import { RootState } from 'app/__shared__/components/store/store'
import { UsersType } from 'app/__shared__/model/types/user.type'
import { statusEnum } from 'app/__shared__/model/enums/statusLoading'

export type ListUsersType = {
    pagination:PaginationType
} & RequestType<UsersType>

const ListUsersState: ListUsersType = {
    status: statusEnum.idle,
    response:{
        response: "",
        code: "",
        message: "",
        data: [],
        errors: {},
    },
    pagination:{
        pageLimit:8,
        currentPage:1,
        totalPages:1,
        totalRecords:0,
    }
}


export const ListUsersSlice = createSlice({
  name: 'list-users-slice',
  initialState: ListUsersState,
  reducers: {
    changeCurrentPage: (state:ListUsersType, action: PayloadAction<any>) => {
        console.log("first")
        state.pagination.currentPage = action.payload
    },
    
    handlePageLimit: (state:ListUsersType, action: PayloadAction<any>) => {
        // dispatch(ListAppealsActions.resetAllParameters())
        // dispatch(ListAppealsActions.changePageLimit(e.target.value))
        console.log(action.payload)

        state.pagination.currentPage = 1
        state.pagination.totalPages = 1
        state.pagination.totalRecords = 0
        state.pagination.pageLimit = action.payload
    },

    changePageLimit: (state:ListUsersType, action: PayloadAction<any>) => {
        state.pagination.pageLimit = action.payload.target.value
    },

    resetAllParameters: (state:ListUsersType) => {
        state.pagination.pageLimit = 8
        state.pagination.currentPage = 1
        state.pagination.totalPages = 1
        state.pagination.totalRecords = 0
    },

    changeParams:(state:ListUsersType, action: PayloadAction<any>) => {
        state.pagination = action.payload
    }
  },
  
  extraReducers: (builder) => {
    builder.addCase(listUsersThunk.pending, (state, action: PayloadAction<any>) => {
        state.status = statusEnum.loading
    })
    
    builder.addCase(listUsersThunk.rejected, (state, action:  PayloadAction<any>) => {
        state.status = statusEnum.failed
        state.response.errors = action.payload.errors
    })
    
    builder.addCase(listUsersThunk.fulfilled, (state, action:  PayloadAction<any>) => {
        state.status = statusEnum.succeeded
        state.response.data = action.payload.data.data
        state.pagination.currentPage = action.payload.data.current_page
        state.pagination.totalPages = action.payload.data.last_page
        state.pagination.totalRecords = action.payload.data.total
    })


    builder.addCase(deleteUsersThunk.pending, (state, action: PayloadAction<any>) => {
    })
    
    builder.addCase(deleteUsersThunk.rejected, (state, action:  PayloadAction<any>) => {
    })
    
    builder.addCase(deleteUsersThunk.fulfilled, (state, action:  PayloadAction<any>) => {
    })


    

    
  },
})


export const ListUsersActions = {
    ...ListUsersSlice.actions,
}


export const ListUsersData = (state:RootState) => state.UsersReducer.ListUsersReducer

export const ListUsersReducer = ListUsersSlice.reducer