import React, { useContext, useEffect, useState } from "react"
import { editRolesThunk, listRolesThunk, viewRolesThunk } from "./slices/Roles.thunk";
import { faExclamationCircle, faSpinner } from "@fortawesome/free-solid-svg-icons"
import { useAppDispatch, useAppSelector } from "app/__shared__/components/store/store";

import { CustomInput } from "app/__shared__/components/common/input/CustomInput";
import { EditFormLoader } from "app/__shared__/components/common/form-loader/EditFormLoader";
import { EditRolesActions } from "./slices/RolesEdit.slice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Label } from "reactstrap";
import { ModalContext } from "app/__shared__/components/context/modal/ModalContext";
import { MultiSelect } from "react-multi-select-component";
import Swl from 'sweetalert2';
import { listPermissionsThunk } from "app/permissions/Permissions.thunk";
import { statusEnum } from "app/__shared__/model/enums/statusLoading"
import toast from "react-hot-toast";

interface IRoleEditForm {
    id:any
}

export const RolesEditForm = ({id}:IRoleEditForm) => {
    const editRoles = useAppSelector(state => state.RolesReducer.EditRolesReducer)
    const selectRoles = useAppSelector(state => state.RolesReducer.SelectRolesReducer)
    const {toggleModalClose} = useContext(ModalContext)
    const dispatch = useAppDispatch()
    const allPermission = useAppSelector(state => state.PermissionReducer)
    const [formData, setFormData] = useState<{
        name: string,
        selectedPermissions: any[],
        isAllValidStatus:statusEnum
    }>({
        name: '',
        selectedPermissions: [],
        isAllValidStatus: statusEnum.idle,
    })


    const { name, selectedPermissions, isAllValidStatus } = formData

    const [optionsPermissions, setOptionsPermissions] = useState<any>([]);

    

    useEffect(() => {
        dispatch(viewRolesThunk({id}))
        dispatch(listPermissionsThunk({}))
    }, [dispatch])

    useEffect(() => {
        if(selectRoles.status === statusEnum.succeeded){
            const selectedPermissions = selectRoles.response.data.permissions.map((item:any) => {
                return  {
                    label: item.title,
                    value:item.name,
                }
            })
            setFormData({
                ...formData,
                selectedPermissions,
                name: selectRoles.response.data.name,
            })
        }
    }, [selectRoles.status])

    useEffect(() => {
        if(editRoles.status === statusEnum.succeeded){
            toggleModalClose()   
            dispatch(EditRolesActions.resetRolesEdit())
            dispatch(listRolesThunk({}))
            toast.success('Rol məlumatları yeniləndi!')
        }
    }, [editRoles , dispatch , toggleModalClose])
    

    useEffect(() => {
        if(allPermission.status === statusEnum.succeeded){
            const options = allPermission.data.map(item => {
                return {
                    label: item.title,
                    value:item.name,
                }
            })
            const removePermissionPermissionsRegex = /^(?!permission).*/
            const filteredOptions = options.filter(item => {
                return removePermissionPermissionsRegex.test(item.value)
            }) 
            setOptionsPermissions(filteredOptions)
        }
    }, [allPermission.data, allPermission.status])

    const handleSubmit = (e:React.FormEvent) => {
        e.preventDefault()
        if(!name){
            setFormData({
                ...formData,
                isAllValidStatus: statusEnum.failed,
            })
            return 
        }else{
            setFormData({
                ...formData,
                isAllValidStatus: statusEnum.loading,
            })
        }

        const permissions = selectedPermissions.map((item:any) => {
            return item.value
        })

        dispatch(editRolesThunk(
            {
                id: selectRoles.response.data.id,
                data:{
                    name,
                    permissions:[...permissions]
                }
            }
        ))

        
        
    }

    
    return (
        <div>
            {
                (selectRoles.status === statusEnum.succeeded) &&
                <form  className="max-w-lg" onSubmit={handleSubmit}>
                    <div className="row pt-2">
                        <div className="col-md-6 mb-4">
                            <Label className="text-muted">ID</Label>
                            <input
                                disabled
                                name="id"
                                value={selectRoles.response.data.id}
                                className="form-control"
                            />
                        </div>
                        <div className="col-md-6 mb-4">
                            <Label for="name" className="font-weight-bold">Ad</Label>
                            <CustomInput
                                required={true}
                                isFormSubmitStatus={isAllValidStatus}
                                emptyError={'Rol adını daxil edin.'}
                                value={name}
                                type='text'
                                placeHolder='Rol adını daxil edin.'
                                onChange={
                                    (data) => setFormData({
                                        ...formData,
                                        name: data,
                                    })
                                }
                            />
                        </div>
                        
                        
                        <div className="col-md-12 mb-4">
                            <Label for="password" className="font-weight-bold">İcazələr</Label>
                            <div className="mb-4 flex gap-2 items-center">
                            <MultiSelect
                                options={optionsPermissions}
                                value={selectedPermissions}
                                onChange={
                                    (data:any) => setFormData({
                                        ...formData,
                                        selectedPermissions: data,
                                    })
                                }
                                labelledBy="title"
                                className="w-full"
                                overrideStrings={
                                    {
                                        allItemsAreSelected: "Bütün icazələr seçilib.",
                                        clearSearch: "Axtarışı təmizlə",
                                        clearSelected: "Seçilmişləri təmizlə",
                                        noOptions: "Axtarış nəticəsi tapılmadı",
                                        search: "Axtarış",
                                        selectAll: "Hamısını seçin",
                                        selectAllFiltered: "Hamısını seçin",
                                        selectSomeItems: "İcazələri seçin",
                                        create: "Yarat",
                                    }
                                }
                            />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 text-md-right">
                            <button
                                type="button"
                                onClick={toggleModalClose}
                                className="btn btn-primary font-weight-bold ml-2 bg-gray-500 text-white"
                            >
                                Ləğv et
                            </button>
                            <button
                                className="btn btn-success font-weight-bold ml-2 bg-green-500 text-white hover:text-white"
                            >
                                Təsdiq et
                            </button>
                        </div>
                    </div>
                    {
                        editRoles.status === statusEnum.loading &&
                        <div className="flex w-full justify-center mt-2 items-center">
                            <div className="flex gap-4  items-center mt-4">
                                <FontAwesomeIcon icon={faSpinner} className='animate-spin'/>
                                <span>Yüklənir</span>
                            </div>
                        </div>
                    }

                    {
                        editRoles.status === statusEnum.failed &&
                        <div className="flex flex-col w-full justify-center mt-2 items-center">
                            {Object.keys(editRoles.response.errors).map((key, index) => (
                                <div key={index} className="w-full flex gap-4  items-center mt-4">
                                    <FontAwesomeIcon icon={faExclamationCircle} className='text-red-500'/>
                                    {
                                        editRoles.response.errors[key].map((error:any, index:any) => (
                                            <span key={index}>{error}</span>
                                        ))
                                    }
                                </div>
                            ))}
                        </div>
                    }
                </form>
            }

            {
                selectRoles.status === statusEnum.failed &&
                <div className="row">
                    <div className="col-md-12">
                        <div className="alert alert-warning">
                            Məlumat tapılmadı
                        </div>
                    </div>
                </div>
            }

            {
                selectRoles.status === statusEnum.loading &&
                <EditFormLoader/>
            }
        </div>
    )
}