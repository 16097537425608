import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { setStatusPending, setStatusRejected, setStatusSucceeded } from 'app/__shared__/components/store/thunkStatusHandler'

import { RequestType } from 'app/__shared__/model/types/request'
import { RootState } from 'app/__shared__/components/store/store'
import { UserType } from 'app/__shared__/model/types/user.type'
import { statusEnum } from 'app/__shared__/model/enums/statusLoading'
import { viewUsersThunk } from './Users.thunk'

export type SelectUsersType = {} & RequestType<UserType> 

const SelectUsersState: SelectUsersType = {
    status: statusEnum.idle,
    response:{
        data: {
            id:0,
            name:"",
            email:"",
            branches:[],
            permissions:[],
            role:{},
            status:0,
            surname:"",
        },
        errors:{}
    }
}


export const SelectUsersSlice = createSlice({
  name: 'select-users-slice',
  initialState: SelectUsersState,
  reducers: {
      
  },
  
  extraReducers: (builder) => {
    builder.addCase(viewUsersThunk.pending, (state:SelectUsersType, action: PayloadAction<any>) => {
        setStatusPending(state, action)
    })
    
    builder.addCase(viewUsersThunk.rejected, (state:SelectUsersType, action:  PayloadAction<any>) => {
        setStatusRejected(state, action)
    })
    
    builder.addCase(viewUsersThunk.fulfilled, (state:SelectUsersType, action:  PayloadAction<any>) => {
        setStatusSucceeded(state, action)
        state.response.data = action.payload.data
    })

    
  },
})


export const SelectUsersActions = {
    ...SelectUsersSlice.actions,
}


export const SelectUsersData = (state:RootState) => state.UsersReducer.SelectUsersReducer

export const SelectUsersReducer = SelectUsersSlice.reducer