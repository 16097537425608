import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { setStatusPending, setStatusRejected, setStatusSucceeded } from '../../__shared__/components/store/thunkStatusHandler'
import { editBranchesThunk, viewBranchesThunk } from './Branches.thunk'

import { RootState } from '../../__shared__/components/store/store'
import { statusEnum } from 'app/__shared__/model/enums/statusLoading'

export interface IBranchesEdit {
    status:statusEnum,
    data?:any,
    submit_status:statusEnum,
    submit_errors:any
} 

const EditBranchesState: IBranchesEdit = {
    status: statusEnum.idle,
    submit_status:statusEnum.idle,
    data:null,
    submit_errors:null
}


export const EditBranchesSlice = createSlice({
  name: 'edit-branches-slice',
  initialState: EditBranchesState,
  reducers: {
    resetBranchesEdit: (state:IBranchesEdit) => {
        state.status = statusEnum.idle
        state.data = null
        state.submit_status = statusEnum.idle
        state.submit_errors = null
    }
      
  },
  
  extraReducers: (builder) => {
    builder.addCase(viewBranchesThunk.pending, (state:IBranchesEdit, action: PayloadAction<any>) => {
        setStatusPending(state, action)
    })
    
    builder.addCase(viewBranchesThunk.rejected, (state:IBranchesEdit, action:  PayloadAction<any>) => {
        setStatusRejected(state, action)
        state.data = null
    })
    
    builder.addCase(viewBranchesThunk.fulfilled, (state:IBranchesEdit, action:  PayloadAction<any>) => {
        setStatusSucceeded(state, action)
        state.data = action.payload.data
    })



    builder.addCase(editBranchesThunk.pending, (state:IBranchesEdit, action: PayloadAction<any>) => {
        state.submit_status = statusEnum.loading
    })
    
    builder.addCase(editBranchesThunk.rejected, (state:IBranchesEdit, action:  PayloadAction<any>) => {
        state.submit_status = statusEnum.failed
        state.data = null
        state.submit_errors = action.payload.response.data
    })
    
    builder.addCase(editBranchesThunk.fulfilled, (state:IBranchesEdit, action:  PayloadAction<any>) => {
        state.submit_status = statusEnum.succeeded
        setStatusSucceeded(state, action)
        state.data = action.payload.data
    })


    


    
  },
})


export const EditBranchesActions = {
    ...EditBranchesSlice.actions,
}


export const EditBranchesData = (state:RootState) => state.BranchesReducer.EditBranchesReducer
export const EditBranchesReducer = EditBranchesSlice.reducer