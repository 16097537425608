import { AXIOS_CONFIG } from "../config"
import { axiosInstance } from "../interceptor"

export const listAppealsService = async (data:{
    params:{
        pageLimit:number,
        currentPage:number,
        totalPages:number,
        totalRecords:number,
        name?:string,
        surname?:string,
        father_name?:string,
        min_weight?:string,
        max_weight?:string,
        min_height?:string,
        max_height?:string,
        is_military?:null|{id:number, name:string},
        has_crime?:null|{id:number, name:string},
        has_invalidity?:null|{id:number, name:string},
        gender?:null|{id:number, name:string},
        email?:string,
        address?:string,
        fin?:string,
        serial_number?:string,
        status?:null|{id:number,name:string},
        selectedBranches?:any[],
    }     
}, {rejectWithValue}:any) => {
    try {
        const name_query = data.params.name ? `&name=${data.params.name}` : ``
        const surname_query = data.params.surname ? `&surname=${data.params.surname}` : ``
        const father_name_query = data.params.father_name ? `&father_name=${data.params.father_name}` : ``
        const min_weight_query = data.params.min_weight ? `&min_weight=${data.params.min_weight}` : ``
        const max_weight_query = data.params.max_weight ? `&max_weight=${data.params.max_weight}` : ``
        const min_height_query = data.params.min_height ? `&min_height=${data.params.min_height}` : ``
        const max_height_query = data.params.max_height ? `&max_height=${data.params.max_height}` : ``
        const is_military_query = data.params.is_military ? `&is_military=${data.params.is_military.id}` : ``
        const has_crime_query = data.params.has_crime ? `&has_crime=${data.params.has_crime.id}` : ``
        const has_invalidity_query = data.params.has_invalidity ? `&has_invalidity=${data.params.has_invalidity.id}` : ``
        const gender_query = data.params.gender ? `&gender=${data.params.gender.id}` : ``
        const email_query = data.params.email ? `&email=${data.params.email}` : ``
        const address_query = data.params.address ? `&address=${data.params.address}` : ``
        const fin_query = data.params.fin ? `&fin=${data.params.fin}` : ``
        const serial_number_query = data.params.serial_number ? `&serial_number=${data.params.serial_number}` : ``
        const status_query = data.params.status ? `&status_id=${data.params.status.id}` : ``
        console.log(data.params.selectedBranches)
        let branches_query = data.params.selectedBranches && (data.params.selectedBranches.length ? `&branch_id=` : "")

        AXIOS_CONFIG.APPEAL.LIST_APPEAL.url = `/appeals?type=list&per_page=${data.params.pageLimit}&page=${data.params.currentPage}&totalPages=${data.params.totalPages}`
        if(name_query){
            AXIOS_CONFIG.APPEAL.LIST_APPEAL.url += name_query
        }
        if(surname_query){
            AXIOS_CONFIG.APPEAL.LIST_APPEAL.url += surname_query
        }
        if(father_name_query){
            AXIOS_CONFIG.APPEAL.LIST_APPEAL.url += father_name_query
        }
        if(min_weight_query){
            AXIOS_CONFIG.APPEAL.LIST_APPEAL.url += min_weight_query
        }
        if(max_weight_query){
            AXIOS_CONFIG.APPEAL.LIST_APPEAL.url += max_weight_query
        }
        if(min_height_query){
            AXIOS_CONFIG.APPEAL.LIST_APPEAL.url += min_height_query
        }
        if(max_height_query){
            AXIOS_CONFIG.APPEAL.LIST_APPEAL.url += max_height_query
        }
        if(is_military_query){
            AXIOS_CONFIG.APPEAL.LIST_APPEAL.url += is_military_query
        }
        if(has_crime_query){
            AXIOS_CONFIG.APPEAL.LIST_APPEAL.url += has_crime_query
        }
        if(has_invalidity_query){
            AXIOS_CONFIG.APPEAL.LIST_APPEAL.url += has_invalidity_query
        }
        if(gender_query){
            AXIOS_CONFIG.APPEAL.LIST_APPEAL.url += gender_query
        }
        if(email_query){
            AXIOS_CONFIG.APPEAL.LIST_APPEAL.url += email_query
        }
        if(address_query){
            AXIOS_CONFIG.APPEAL.LIST_APPEAL.url += address_query
        }
        if(fin_query){
            AXIOS_CONFIG.APPEAL.LIST_APPEAL.url += fin_query
        }
        if(serial_number_query){
            AXIOS_CONFIG.APPEAL.LIST_APPEAL.url += serial_number_query
        }
        if(status_query){
            AXIOS_CONFIG.APPEAL.LIST_APPEAL.url += status_query
        }
        if(branches_query){
            let branches_id = data.params.selectedBranches!.map((item,index) => 
                data.params.selectedBranches!.length === (index+1) ? `${item.id}` : `${item.id}`
            )
            branches_query += branches_id.join(",")
            AXIOS_CONFIG.APPEAL.LIST_APPEAL.url += branches_query
        }
        const response = await axiosInstance(AXIOS_CONFIG.APPEAL.LIST_APPEAL.url)
        return response.data
    } catch (error:any) {
        console.log(error)
        return rejectWithValue(error.response.data)
    }
}

export const viewAppealsService = async (data:any, {rejectWithValue}:any) => {
    try {
        const response = await axiosInstance(`appeals/${data.id}`, AXIOS_CONFIG.APPEAL.SHOW_APPEAL)
        return response.data
    } catch (error:any) {
        return rejectWithValue(error.response.data)
    }
}

export const createAppealsService = async (data:any , {rejectWithValue}:any) => {
    try {
        const config:any = AXIOS_CONFIG.APPEAL.CREATE_APPEAL
        config.data = data.data
        const response = await axiosInstance(config)
        return response.data
    } catch (error:any) {
        return rejectWithValue(error.response.data)
    }
}

export const editAppealsService = async (data:any , {rejectWithValue}:any) => {
    try {
        const response = await axiosInstance(`appeals/${data.id}` , {...AXIOS_CONFIG.APPEAL.UPDATE_APPEAL, data:data.data})
        return response.data
    } catch (error:any) {
        return rejectWithValue(error.response.data)
    }
}

export const deleteAppealsService = async (data:any , {rejectWithValue}:any) => {
    try {
        const response = await axiosInstance(`/appeals/${data.id}`, AXIOS_CONFIG.APPEAL.DELETE_APPEAL)
        return response.data
    } catch (error:any) {
        return rejectWithValue(error.response.data)
    }
}

export const rejectAppealsService = async (data:any , {rejectWithValue}:any) => {
    try {
        const conf = AXIOS_CONFIG.APPEAL.REJECT_APPEAL
        conf.data.normatives = data.normatives
        const response = await axiosInstance(`/appeals/${data.id}`, AXIOS_CONFIG.APPEAL.REJECT_APPEAL)
        return response.data
    } catch (error:any) {
        return rejectWithValue(error.response.data)
    }
}

export const sendMeetForAppealsService = async (data:any , {rejectWithValue}:any) => {
    try {
        data.data.type = "MEET" 
        const response = await axiosInstance(`/appeals/${data.id}`, {...AXIOS_CONFIG.APPEAL.UPDATE_MEET_APPEAL, data:data.data})
        return response.data
    } catch (error:any) {
        return rejectWithValue(error.response.data)
    }
}


export const sendResultForAppealsService = async (data:any , {rejectWithValue}:any) => {
    try {
        console.log(data)
        data.data.type = "RESULT" 
        const response = await axiosInstance(`/appeals/${data.id}`, {...AXIOS_CONFIG.APPEAL.UPDATE_RESULT_APPEAL, data:data.data})
        return response.data
    } catch (error:any) {
        return rejectWithValue(error.response.data)
    }
}


