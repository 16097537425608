import "leaflet/dist/leaflet.css"

import { MapContainer, Marker, Popup, TileLayer, Tooltip } from 'react-leaflet'

import { Icon } from 'leaflet'
import logo from 'app/__shared__/assets/media/icon/location.png';

const myIcon = new Icon({
  iconUrl: logo,
  iconSize: [32,45]
 })

export interface MapProps  {
    positions: Array<{lat:number,lng:number,title?:string}>
}
 
export const MapMultipleLocation = ({positions}:MapProps) => {

    return (
        <div>
            <MapContainer className='h-96' center={[positions[0].lat, positions[0].lng]} zoom={7} scrollWheelZoom={true}>
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                {
                    positions.map((position, index) => {
                        if(position.lat && position.lng) 
                        return (
                            <Marker  title="Hello world" position={[position.lat, position.lng]} icon={myIcon}>
                                <Tooltip direction="bottom" offset={[0, 20]} opacity={1} permanent>
                                    {position.title}
                                </Tooltip>
                            </Marker>
                        )
                    })
                }    
            </MapContainer>
        </div>
    )
}