import { useNavigate } from "react-router-dom"

export const Page403 = () => {
    const navigate = useNavigate()

    const goLogin = () => {
        navigate("/")
    }
    
    return (
        <div className="w-full">
            <div className="mt-60 mx-auto w-max flex flex-col justify-center">
                <h1 className=" w-full text-center text-4xl">403 Forbidden</h1>
                <button 
                    onClick={goLogin}
                    className="btn btn-primary mx-auto mt-3" >Giriş səhifəsinə qayıt</button>
            </div>
        </div>
    )
}