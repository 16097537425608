import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/__shared__/components/store/store'
import { setStatusPending, setStatusRejected, setStatusSucceeded } from 'app/__shared__/components/store/thunkStatusHandler'

import { statusEnum } from 'app/__shared__/model/enums/statusLoading'
import { viewNormativesThunk } from './Normatives.thunk'

export interface ISelectNormativesState {
    status:statusEnum,
    data?:any,
} 

const SelectNormativesState: ISelectNormativesState = {
    status: statusEnum.idle,
    data:null
}


export const SelectNormativesSlice = createSlice({
  name: 'selected-normative-slice',
  initialState: SelectNormativesState,
  reducers: {
      
  },
  
  extraReducers: (builder) => {
    builder.addCase(viewNormativesThunk.pending, (state:ISelectNormativesState, action: PayloadAction<any>) => {
        setStatusPending(state, action)
    })
    
    builder.addCase(viewNormativesThunk.rejected, (state:ISelectNormativesState, action:  PayloadAction<any>) => {
        setStatusRejected(state, action)
        state.data = null
    })
    
    builder.addCase(viewNormativesThunk.fulfilled, (state:ISelectNormativesState, action:  PayloadAction<any>) => {
        setStatusSucceeded(state, action)
        state.data = action.payload.data
    })

    
  },
})


export const SelectedNormativeActions = {
    ...SelectNormativesSlice.actions,
}


export const SelectNormativesData = (state:RootState) => state.NormativesReducer.SelectNormativesReducer

export const SelectNormativesReducer = SelectNormativesSlice.reducer