import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { sendMeetForAppealsThunk, sendResultForAppealsThunk, viewAppealsThunk } from './Appeals.thunk'
import { setStatusPending, setStatusRejected, setStatusSucceeded } from '../../__shared__/components/store/thunkStatusHandler'

import { RootState } from '../../__shared__/components/store/store'
import { TErrors } from 'app/__shared__/model/types/error'
import { sendResultForAppealsService } from 'app/__shared__/helpers/api/services/appeals'
import { statusEnum } from 'app/__shared__/model/enums/statusLoading'

export interface IAppealsSelect{
    status:statusEnum,
    data?:any,
    send_result:{
        data:any,
        status:statusEnum,
        errors:TErrors
    },
    create_meet:{
        data:any,
        status:statusEnum,
        errors:TErrors
    }
} 

const SelectAppealsState: IAppealsSelect = {
    status: statusEnum.idle,
    data:null,
    send_result:{
        data:null,
        status:statusEnum.idle,
        errors:{}
    },
    create_meet:{
        data:null,
        status:statusEnum.idle,
        errors:{}
    }
}


export const SelectAppealsSlice = createSlice({
  name: 'select-appeals-slice',
  initialState: SelectAppealsState,
  reducers: {
    resetAppealsSendResult: (state:IAppealsSelect) => {
        state.send_result.data = null
        state.send_result.status = statusEnum.idle
        state.send_result.errors = {}
    },
    resetAppealsMeetResult: (state:IAppealsSelect) => {
        state.create_meet.data = null
        state.create_meet.status = statusEnum.idle
        state.create_meet.errors = {}
    }
  },
  
  extraReducers: (builder) => {
    builder.addCase(viewAppealsThunk.pending, (state:IAppealsSelect, action: PayloadAction<any>) => {
        setStatusPending(state, action)
    })
    
    builder.addCase(viewAppealsThunk.rejected, (state:IAppealsSelect, action:  PayloadAction<any>) => {
        setStatusRejected(state, action)
        state.data = null
    })
    
    builder.addCase(viewAppealsThunk.fulfilled, (state:IAppealsSelect, action:  PayloadAction<any>) => {
        setStatusSucceeded(state, action)
        state.data = action.payload.data
    })

    builder.addCase(sendMeetForAppealsThunk.pending, (state:IAppealsSelect, action: PayloadAction<any>) => {
        state.create_meet.status = statusEnum.loading
    })
    
    builder.addCase(sendMeetForAppealsThunk.rejected, (state:IAppealsSelect, action:  PayloadAction<any>) => {
        state.create_meet.status = statusEnum.failed
        state.create_meet.errors = action.payload.errors
    })
    
    builder.addCase(sendMeetForAppealsThunk.fulfilled, (state:IAppealsSelect, action:  PayloadAction<any>) => {
        state.create_meet.status = statusEnum.succeeded
        state.create_meet.data = action.payload.data
    })


    builder.addCase(sendResultForAppealsThunk.pending, (state:IAppealsSelect, action: PayloadAction<any>) => {
        state.send_result.status = statusEnum.loading
    })
    
    builder.addCase(sendResultForAppealsThunk.rejected, (state:IAppealsSelect, action:  PayloadAction<any>) => {
        state.send_result.status = statusEnum.failed
    })
    
    builder.addCase(sendResultForAppealsThunk.fulfilled, (state:IAppealsSelect, action:  PayloadAction<any>) => {
        state.send_result.status = statusEnum.succeeded
        console.log(action.payload)
        state.send_result.data = action.payload.data
    })
    
  },
})


export const SelectAppealsActions = {
    ...SelectAppealsSlice.actions,
}


export const SelectAppealsData = (state:RootState) => state.AppealsReducer.SelectAppealsReducer

export const SelectAppealsReducer = SelectAppealsSlice.reducer