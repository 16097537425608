import React from 'react'

export const Home = () => {
    return (
        <div>
            <h1 className="mb-2 font-weight-bold text-center text-5xl mt-2">Gesco proqram təminatı</h1>
            <div className="row mt-0">
                <div className="alert alert-info w-full ml-3 mr-3">
                    Sol menyudan səhifələrə keçid edərək Gesco proqram təminatını istifadə edə bilərsiz.
                </div>
            </div>
        </div>
    )
}


