import { FormEvent, useContext, useEffect, useState } from "react"
import { IAppealsSelect, SelectAppealsActions } from "./slices/AppealsSelect.slice";
import { listAllBranchesThunk, listBranchesThunk } from "app/branches/slices/Branches.thunk";
import { sendMeetForAppealsThunk, viewAppealsThunk } from "./slices/Appeals.thunk";
import { useAppDispatch, useAppSelector } from "app/__shared__/components/store/store";

import { CustomInput } from "app/__shared__/components/common/input/CustomInput"
import { ModalContext } from "app/__shared__/components/context/modal/ModalContext";
import Select from 'react-select';
import Swl from "sweetalert2";
import { statusEnum } from "app/__shared__/model/enums/statusLoading"
import toast from "react-hot-toast";

export const AppealsMeetForm = (props:{
    id:any,
    appealsData:any
}) => {
    const {id,appealsData} = props;
    
    console.log(appealsData)
    const branchesListData = useAppSelector(state => state.BranchesReducer.ListBranchesReducer)
    const selectAppealsReducer = useAppSelector(state => state.AppealsReducer.SelectAppealsReducer)
    const dispatch = useAppDispatch()
    const {toggleModalClose } = useContext(ModalContext)

    useEffect(() => {
        if(branchesListData.status === statusEnum.idle) {
            dispatch(listAllBranchesThunk({}))
        }
    }, [branchesListData.status])

    const [formData, setFormData] = useState<{
        meet_date: string,
        selected_branch:any,
        isFormSubmitStatus:statusEnum
    }>({
        meet_date:appealsData ? appealsData.meeting_date : '',
        selected_branch:appealsData ? appealsData.branch : '',
        isFormSubmitStatus:statusEnum.idle
    })

    const {meet_date , selected_branch, isFormSubmitStatus} = formData 
    
    useEffect(() => {
        if(selectAppealsReducer.create_meet.status === statusEnum.succeeded) {
            toggleModalClose()
            dispatch(viewAppealsThunk({id}))
            dispatch(SelectAppealsActions.resetAppealsMeetResult())
            toast.success('Görüş vaxtı təyin edildi!',);
        }
    }, [selectAppealsReducer.create_meet.status , toggleModalClose , dispatch])
    

    const handleSubmit = (e:FormEvent) => {
        e.preventDefault()
        if(!meet_date || !selected_branch) {
            setFormData({
                ...formData,
                isFormSubmitStatus:statusEnum.failed
            })
        }else{ 
            setFormData({
                ...formData,
                isFormSubmitStatus:statusEnum.succeeded
            })
            const data = {
                type:"MEET",
                meeting_date:formData.meet_date,
                branch_id:formData.selected_branch.id
            }
            dispatch(sendMeetForAppealsThunk({data , id}))
        }
    }  
    const tomorrowDate = new Date(Date.now() + (24 * 60 * 60 * 1000)).toISOString().slice(0,16)
    
    return(
        <form onSubmit={handleSubmit}>
            <div className="form-group">
                <label>Görüşün tarixini daxil edin.</label>
                <CustomInput
                    required={true}
                    isFormSubmitStatus={isFormSubmitStatus}
                    emptyError={'Görüşün tarixini daxil edin.'}
                    value={meet_date}
                    minDate={tomorrowDate}
                    type='datetime-local'
                    onChange={(data) => {
                        setFormData({
                            ...formData,
                            meet_date: data,
                        })
                    }}
                />
            </div>


            <div className="form-group">
                <label className="">Aid olduğu filiallar</label>
                <Select
                    className="w-full"
                    isLoading={branchesListData.list_all.status === statusEnum.loading}
                    placeholder="Fiallialı seçin"
                    value={selected_branch}
                    options={branchesListData.list_all.data}
                    getOptionValue={(option:any) => option.id}
                    getOptionLabel={(option:any) => option.name}
                    onChange={(e:any) => {
                        setFormData({
                            ...formData,
                            selected_branch: e
                        })
                    }}
                />
            </div>
                
            <div className="flex justify-end"> 
                <button className="btn btn-primary text-white ml-auto">
                    Təsdiq et
                </button>
            </div>
            
        </form>
    )
}