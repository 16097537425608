import { APPLICATION_API_PATH, APPLICATION_PATH } from './services/path';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

import { getCookie } from 'app/__shared__/helpers/utils/cookie/cookie';

export const axiosInstance = axios.create({
  baseURL: APPLICATION_API_PATH.BASE,
})

// Request interceptor for API calls
axiosInstance.interceptors.request.use(
  async (config:AxiosRequestConfig) => {
    config.headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    }
    const jwt = getCookie('jwt')
    if (jwt === null) {
        return config
    }
    config.baseURL = APPLICATION_API_PATH.BASE
    config.headers['Authorization'] = `Bearer ${jwt}`
    return config
  },
  (error) => {
    Promise.reject(error)
  },
)


//interceptor error handling for API calls
axiosInstance.interceptors.response.use(
    (response:AxiosResponse) => {
        return response
    }
    ,
    (error) => {
        // const navigate = useNavigate()
        if (error.response.status === 401) {
            window.location.href = (APPLICATION_PATH.UNAUTHORIZED)
        }
        if (error.response.status === 429) {
            window.location.href = (APPLICATION_PATH.TOO_MANY_REQUEST)
        }
        if (error.response.status === 403) {
            window.location.href = APPLICATION_PATH.FORBIDDEN
        }
        if (error.response.status === 500) {
            window.location.href = APPLICATION_PATH.SERVER_ERROR
        }
        return Promise.reject(error)
    }
)
