import { combineReducers } from "@reduxjs/toolkit";
import { CreateRolesReducer } from "./RolesCreate.slice";
import { EditRolesReducer } from "./RolesEdit.slice";
import { ListRolesReducer } from "./RolesList.slice";
import { SelectRolesReducer } from "./RolesSelect.slice";


export const RolesReducer = combineReducers({
    ListRolesReducer:ListRolesReducer,
    CreateRolesReducer:CreateRolesReducer,
    EditRolesReducer:EditRolesReducer,
    SelectRolesReducer:SelectRolesReducer,
})

