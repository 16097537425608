import { deleteBranchesThunk, listBranchesThunk } from "./slices/Branches.thunk"
import { useAppDispatch, useAppSelector } from "app/__shared__/components/store/store"

import { ModalContext } from "app/__shared__/components/context/modal/ModalContext"
import Swl from 'sweetalert2';
import toast from "react-hot-toast";
import { useContext } from "react"

interface IbranchDelete {
    id:number
}

export const BranchesDeleteForm = ({id}:IbranchDelete) => {
    const listBranches = useAppSelector(state => state.BranchesReducer.ListBranchesReducer)

    const {toggleModalClose} = useContext(ModalContext)
    const dispatch = useAppDispatch()
    
    const deleteUser = async () => {
        toggleModalClose()
        await dispatch(deleteBranchesThunk({id:id}))
        toast.success('Filial məlumatları silindi!')
        dispatch(listBranchesThunk({...listBranches.pagination}))
    }
    
    return (
        <div className="flex flex-col ">
            <div className="row pt-2">
                <div className="col-md-12 text-center mb-4">
                    <h5 className="text-lg">Filialı silmək istədiyinizdən əminsinizmi?</h5>
                </div>
            </div>

            <div className="justify-between gap-4 flex ">
                <button color="light" className="btn btn-primary w-full" onClick={toggleModalClose}>Ləğv et</button>
                <button color="danger" className="btn btn-danger font-weight-bold w-full" onClick={deleteUser}>Sil</button>
            </div>
        </div>
    )
}