import { combineReducers } from "@reduxjs/toolkit";
import { CreateNormativesReducer } from "./NormativesCreate.slice";
import { EditNormativesReducer } from "./NormativesEdit.slice";
import { ListNormativesReducer } from "./NormativesList.slice";
import { SelectNormativesReducer } from "./NormativesSelect.slice";


export const NormativesReducer = combineReducers({
    ListNormativesReducer:ListNormativesReducer,
    CreateNormativesReducer:CreateNormativesReducer,
    EditNormativesReducer:EditNormativesReducer,
    SelectNormativesReducer:SelectNormativesReducer,
})

