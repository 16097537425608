import 'app/__shared__/assets/vendor/bootstrap/bootstrap.scss';
import 'app/__shared__/assets/style/scss/style.scss';

import toast, { Toaster } from 'react-hot-toast';

import { GlobalContextProvider } from 'app/__shared__/components/context/global/Global.context';
import { ModalContextProvider } from 'app/__shared__/components/context/modal/ModalContext';
import { Provider } from 'react-redux'
import React from 'react';
import { Routes } from 'routes/Routes';
import { store } from 'app/__shared__/components/store/store';

function App() {

  return (
    <GlobalContextProvider>
        <Provider store={store}>
            <ModalContextProvider>
                <Toaster position='top-right'/>
                <Routes/>
            </ModalContextProvider>
        </Provider>
    </GlobalContextProvider>
  );
}

export default App;
