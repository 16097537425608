import { combineReducers } from "@reduxjs/toolkit";
import { CreateBranchesReducer } from "./BranchesCreate.slice";
import { EditBranchesReducer } from "./BranchesEdit.slice";
import { ListBranchesReducer } from "./BranchesList.slice";
import { SelectBranchesReducer } from "./BranchesSelect.slice";


export const BranchesReducer = combineReducers({
    ListBranchesReducer:ListBranchesReducer,
    CreateBranchesReducer:CreateBranchesReducer,
    EditBranchesReducer:EditBranchesReducer,
    SelectBranchesReducer:SelectBranchesReducer,
})

