import { createRolesService, deleteRolesService, editRolesService, listRolesService, viewRolesService, } from "app/__shared__/helpers/api/services/roles"

import { createAsyncThunk } from "@reduxjs/toolkit"

export const listRolesThunk = createAsyncThunk("roles/list", listRolesService)
export const viewRolesThunk = createAsyncThunk("roles/show", viewRolesService)
export const editRolesThunk = createAsyncThunk("roles/edit", editRolesService)
export const createRolesThunk = createAsyncThunk("roles/create", createRolesService)
export const deleteRolesThunk = createAsyncThunk("roles/delete", deleteRolesService)

