import { useAppDispatch, useAppSelector } from "../__shared__/components/store/store"

import { ListUsersActions } from "./slices/UsersList.slice"
import { PaginationFooter } from "app/__shared__/components/common/pagination-footer/PaginationFooter"
import { TableLoader } from "app/__shared__/components/common/loader/TableLoader"
import { UsersCustomTableButton } from "./UsersCustomTableButton"
import { listAppealsThunk } from "app/appeals/slices/Appeals.thunk"
import { listUsersThunk } from "./slices/Users.thunk"
import { useEffect } from "react"

interface ICustomTableProps {
}

export const UsersCustomTable = (props:ICustomTableProps) => {
    const listUsers = useAppSelector(state => state.UsersReducer.ListUsersReducer)
    const listUsersData = listUsers.response
    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(listUsersThunk({...listUsers.pagination}))
    }, [listUsers.pagination, dispatch])

    
    return(
        <div className='flex-col w-full mt-10'>
            
            <table className="table table-striped">
                <thead>
                    <tr>
                    <th scope="col">ID</th>
                    <th scope="col">Ad</th>
                    <th scope="col">Soyad</th>
                    <th scope="col">Elektron Poçt</th>
                    <th scope="col">Status</th>
                    <th scope="col">Əməliyyat</th>
                    </tr>
                </thead>
                <tbody>
                    <TableLoader status={listUsers.status}>
                        {
                            listUsersData.data.map((user) => 
                                <tr key={user.id}>
                                    <th scope="row">
                                        {user.id}
                                    </th>
                                    <td >
                                        {user.name}
                                    </td>
                                    <td >
                                        {user.surname}
                                    </td>
                                    <td >
                                        {user.email}
                                    </td>
                                    <td className={(user.status ? ' text-green-500' : ' text-red-600')}>
                                        {user.status ? 'Aktiv' : 'Deaktiv'}
                                    </td>
                                    <td>
                                        <UsersCustomTableButton id={user.id}/>
                                    </td>
                                </tr>
                        )}
                    </TableLoader>
                </tbody>
            </table>

            <PaginationFooter 
                status={listUsers.status}
                params={
                    {
                        totalPages: listUsers.pagination.totalPages,
                        currentPage: listUsers.pagination.currentPage,
                        pageLimit: listUsers.pagination.pageLimit,
                    }
                }
                actions={ListUsersActions}
            />
        </div>
    )
}



// const tableHeaders:Array<{ 
//     name: string , 
//     type: 'text' | 'component' ,
//     component?: ()=> JSX.Element,
// }> = [
//     {
//         name: "No.",
//         type: "text",
//     },
//     {
//         name: "Ad",
//         type: "text",
//     },
//     {
//         name: "Soyad",
//         type: "text",
//     },
//     {
//         name: "Elektron Poçt",
//         type: "text",
//     },
//     {
//         name: "Status",
//         type: "component",
//         component:UserEdit
//     },
//     {
//         name: "",
//         type: "component",
//         component:UserEdit
//     },
// ]