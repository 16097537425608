
import { createAsyncThunk } from "@reduxjs/toolkit"
import { deletePermissionsService, editPermissionsService, listPermissionsService, viewPermissionsService } from "app/__shared__/helpers/api/services/permissions"

export const listPermissionsThunk   =   createAsyncThunk("permission/list", listPermissionsService)
export const viewPermissionsThunk   =   createAsyncThunk("permission/view", viewPermissionsService)
export const deletePermissionsThunk =   createAsyncThunk("permission/delete", deletePermissionsService)
export const editPermissionThunk  =   createAsyncThunk("permission/edit", editPermissionsService)
// export const showPermissionsThunk = createAsyncThunk("permission/show", (id:number) => permissionsShowService(id))

