import { useAppDispatch, useAppSelector } from "app/__shared__/components/store/store"

import { AuthData } from "app/auth/Auth.slice"
import { FormLoader } from "app/__shared__/components/common/form-loader/FormLoader"
import { VACANCY_SETTINGS_PERMISSIONS } from "app/__shared__/config/permissions"
import { VacancySettingsEditButton } from "../VacancySettingsEditButton"
import { statusEnum } from "app/__shared__/model/enums/statusLoading"
import { useEffect } from "react"
import { viewVacancySettingsThunk } from "../slices/VacancySettings.thunk"

export const VacancySettings = () => {
    const {user:{permissions}} = useAppSelector(AuthData);

    const vacancySettings = useAppSelector(state => state.VacancySettingsReducer)
    const dispatch = useAppDispatch()

    useEffect( () => {
        dispatch(viewVacancySettingsThunk({}))    
    } , [])

    return (
        <div className="bg-white rounded-md p-6">
            <div className="w-full flex justify-between items-center mb-10">
                <h1 className="text-4xl align-top">Vakansiya Sazlamaları</h1>
                {permissions[VACANCY_SETTINGS_PERMISSIONS.update] &&  <VacancySettingsEditButton/>}
            </div>

            {vacancySettings.status === statusEnum.loading && <FormLoader/>}
            <form>
                {
                    vacancySettings.status === statusEnum.succeeded  &&
                    <>
                        <div className="row mb-4 pt-2">
                            <div className="col-md-4 mb-8">
                                <label>Cins Seçimi</label>
                                <input
                                    className="form-control"
                                    disabled
                                    defaultValue={vacancySettings.data.gender === "1" ? "Kişi" : "Qadın"}
                                />
                            </div>
                            <div className="col-md-4 mb-8">
                                <label>Hərbi bilet seçimi</label>
                                <input
                                    className="form-control"
                                    disabled
                                    defaultValue={vacancySettings.data.required_military ? "Hərbi xidməti başa vurmuş" : "Hərbi xidməti başa vurmamış."}
                                />
                            </div>

                            <div className="col-md-4 mb-8">
                                <label>Minimum boy həddi (sm)</label>
                                <input
                                    className="form-control"
                                    disabled
                                    defaultValue={vacancySettings.data.min_height}
                                />
                            </div>

                            <div className="col-md-4 mb-8">
                                <label>Minimum yaş həddi</label>
                                <input
                                    className="form-control"
                                    disabled
                                    defaultValue={vacancySettings.data.min_age}
                                />
                            </div>

                            <div className="col-md-4 mb-8">
                                <label>Maksimum yaş həddi</label>
                                <input
                                    className="form-control"
                                    disabled
                                    defaultValue={vacancySettings.data.max_age}
                                />
                            </div>
                        </div>
                    </>
                }

                
                {
                    vacancySettings.status === statusEnum.failed &&
                    <div className="row">
                        <div className="col-md-12">
                            <div className="alert alert-warning">
                                Məlumat tapılmadı
                            </div>
                        </div>
                    </div>
                }
            </form>

        </div>
    )
}
