import { useAppDispatch, useAppSelector } from "../__shared__/components/store/store"

import { BranchesCustomTableButton } from "./BranchesCustomTableButton"
import { ListBranchesActions } from "./slices/BranchesList.slice"
import { PaginationFooter } from "app/__shared__/components/common/pagination-footer/PaginationFooter"
import { TableLoader } from "app/__shared__/components/common/loader/TableLoader"
import { listBranchesThunk } from "./slices/Branches.thunk"
import { useEffect } from "react"

interface ICustomTableProps {
}

export const BranchesCustomTable = (props:ICustomTableProps) => {
    const listBranches = useAppSelector(state => state.BranchesReducer.ListBranchesReducer)
    const listBranchesData = listBranches.response
    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(listBranchesThunk({...listBranches.pagination}))
    }, [listBranches.pagination, dispatch])
    
   
    return(
        <div className='flex-col w-full mt-10'>
            <table className="table table-striped">
                <thead>
                    <tr>
                    <th scope="col">ID</th>
                    <th scope="col">Məkan Adı</th>
                    <th scope="col">Məsul şəxs</th>
                    <th scope="col">Elektron Poçt</th>
                    <th scope="col">Status</th>
                    <th scope="col">Əməliyyat</th>
                    </tr>
                </thead>
                <tbody>
                    <TableLoader status={listBranches.status}>
                        {
                            listBranchesData.data.map((element) => 
                                <tr key={element.id}>
                                    <th scope="row">
                                        {element.id}
                                    </th>
                                    <td >
                                        {element.name}
                                    </td>
                                    <td >
                                        {element.chief}
                                    </td>
                                    <td >
                                        {element.chief_email}
                                    </td>
                                    <td className={(element.status ? ' text-green-500' : ' text-red-600')}>
                                        {element.status ? 'Aktiv' : 'Deaktiv'}
                                    </td>
                                    <td>
                                        <BranchesCustomTableButton id={element.id}/>
                                    </td>
                                </tr>
                        )}
                    </TableLoader>
                </tbody>
            </table>

            <PaginationFooter 
                status={listBranches.status}
                params={
                    {
                        totalPages: listBranches.pagination.totalPages,
                        currentPage: listBranches.pagination.currentPage,
                        pageLimit: listBranches.pagination.pageLimit,
                    }
                }
                actions={ListBranchesActions}
            />
        </div>
    )
}


