import React, { useContext, useState } from 'react';
import {
  faAlignLeft,
  faBars,
  faSignOutAlt,
  faUser
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GlobalContext } from '../../context/global/Global.context';
import { useAppDispatch, useAppSelector } from '../../store/store';
import { AuthData } from 'app/auth/Auth.slice';
import { logoutThunk } from 'app/auth/Auth.thunk';

export const Navbar = () => {
    const {state , dispatchGlobalContext} = useContext(GlobalContext)
    const dispatch = useAppDispatch()
    
    const [isOpen, setIsOpen] = useState(true)
    const toggle = () => {
        setIsOpen(!isOpen);
    }
    const authData = useAppSelector(AuthData)

    return (
    <div className="navbar p-3 mb-3 w-100 h-24 flex justify-between">
        <button
            className="btn mr-2 bg-white"
            onClick={() => dispatchGlobalContext({type: "TOGGLE_SIDEBAR"})}
        >
            <FontAwesomeIcon icon={faAlignLeft} />
        </button>
        
        <button 
            onClick={toggle} 
            className="btn d-block d-sm-none">
            <FontAwesomeIcon icon={faBars} />
        </button>
        
        <div className="d-flex align-items-center  rounded-md gap-x-3">
            <div className="text-white mr-2">
                <p className="font-weight-bold text-right m-0">
                    {authData.user.name} {authData.user.surname} 
                </p>
            </div>
                
            <button className='btn bg-white' onClick={toggle}>
                <FontAwesomeIcon icon={faUser} />
            </button>

            <button   className='btn bg-white text-black' onClick={() => dispatch(logoutThunk())}>
                <FontAwesomeIcon icon={faSignOutAlt} className="mr-2" />Çıxış
            </button>
        </div>
        
            
    
    </div>
    )
}