
export const SECONDS = 1000;
export const MINUTES = 60 * SECONDS;
export const HOURS = 60 * MINUTES;
export const DAY = 24 * HOURS;
export const WEEK = 7  * DAY;
export const MONTH_30 = 30  * DAY;
export const MONTH_31 = 31  * DAY;
export const YEAR_365 = 365 * DAY;
export const YEAR_366 = 366 * DAY;



export const BAKU_CORDINATES:{
    lat:number,
    lng:number
} = { 
    lat: 40.37061262953358,
    lng: 49.86889724731445
}

