import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

const Loader = () => {
  return (
    <div className="text-center my-5">
        <h1 className="font-weight-light mb-4 mx-auto mt-96 text-4xl">
            <FontAwesomeIcon icon={faSpinner}  className="animate-spin"/>
            <span> Məlumatlar yüklənir...</span>
        </h1>
    </div>
  );
};

export default Loader;