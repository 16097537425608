import { AXIOS_CONFIG } from "../../config"
import { LoginPostInterface } from "app/__tests__/login/Login.type"
import { axiosInstance } from "../../interceptor"
import { notifyError } from "app/__shared__/helpers/utils/notify/notifyError"

export const loginService = async (data:LoginPostInterface, {rejectWithValue}:any) => {
    try {
        const response:any = await axiosInstance({...AXIOS_CONFIG.AUTH.LOGIN, data})
        return response.data
    } catch (error:any) {
        return rejectWithValue(error.response.data)
    }
}
