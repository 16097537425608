import { objectCRUDGenerator } from "app/__shared__/helpers/utils/objectUtils/objectGeneratorCRUD";

export const INDEX  = 'index';
export const CREATE = 'create';
export const UPDATE = 'update';
export const SHOW   = 'show';
export const DELETE = 'delete';


export const USERS_PERMISSIONS = {
    create: `user.${CREATE}`,
    update: `user.${UPDATE}`,
    delete:   `user.${DELETE}`,
    show: `user.${SHOW}`,
    index: `user.${INDEX}`,
};

export const BRANCHES_PERMISSIONS = {
    create: `branch.${CREATE}`,
    update: `branch.${UPDATE}`,
    delete:   `branch.${DELETE}`,
    show: `branch.${SHOW}`,
    index: `branch.${INDEX}`,
};

export const ROLE_PERMISSIONS = {
    create:    `role.${CREATE}`,
    update:    `role.${UPDATE}`,
    delete:    `role.${DELETE}`,
    show:      `role.${SHOW}`,
    index:     `role.${INDEX}`,
};

export const APPEAL_PERMISSIONS = {
    create:    `appeal.${CREATE}`,
    update:    `appeal.${UPDATE}`,
    delete:    `appeal.${DELETE}`,
    show:      `appeal.${SHOW}`,
    index:     `appeal.${INDEX}`,
};

export const EMAIL_PERMISSIONS = {
    create:    `email.${CREATE}`,
    update:    `email.${UPDATE}`,
    delete:    `email.${DELETE}`,
    show:      `email.${SHOW}`,
    index:     `email.${INDEX}`,
};

export const VACANCY_SETTINGS_PERMISSIONS = {
    create:    `vacancy_settings.${CREATE}`,
    update:    `vacancy_settings.${UPDATE}`,
    delete:    `vacancy_settings.${DELETE}`,
    show:      `vacancy_settings.${SHOW}`,
    index:     `vacancy_settings.${INDEX}`,
};

export const NORMATIVES_PERMISSIONS = {
    create:    `normative.${CREATE}`,
    update:    `normative.${UPDATE}`,
    delete:    `normative.${DELETE}`,
    show:      `normative.${SHOW}`,
    index:     `normative.${INDEX}`,
};


export const PERMISSION_PERMISSIONS = {
    create:    `permission.${CREATE}`,
    update:    `permission.${UPDATE}`,
    delete:    `permission.${DELETE}`,
    show:      `permission.${SHOW}`,
    index:     `permission.${INDEX}`,
};



export const permissions = {
    USERS_PERMISSIONS: USERS_PERMISSIONS,
    ROLES_PERMISSIONS: ROLE_PERMISSIONS,
    APPEAL_PERMISSIONS:APPEAL_PERMISSIONS,
    EMAIL_PERMISSIONS:EMAIL_PERMISSIONS,
    VACANCY_SETTINGS_PERMISSIONS:VACANCY_SETTINGS_PERMISSIONS,
    PERMISSION_PERMISSIONS:PERMISSION_PERMISSIONS,
}

export const all_permissions = {
    USERS_PERMISSIONS: USERS_PERMISSIONS,
    ROLES_PERMISSIONS: ROLE_PERMISSIONS,
    APPEAL_PERMISSIONS:APPEAL_PERMISSIONS,
    EMAIL_PERMISSIONS:EMAIL_PERMISSIONS,
    VACANCY_SETTINGS_PERMISSIONS:VACANCY_SETTINGS_PERMISSIONS,
    PERMISSION_PERMISSIONS:PERMISSION_PERMISSIONS,
    BRANCHES_PERMISSIONS:BRANCHES_PERMISSIONS,
    NORMATIVES_PERMISSIONS:NORMATIVES_PERMISSIONS
}



