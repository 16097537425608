import React, {useEffect} from 'react'
import { faAngleLeft, faAngleRight, faBan, faCalendar, faCheck, faClock } from '@fortawesome/free-solid-svg-icons'
import { useAppDispatch, useAppSelector } from '../__shared__/components/store/store'

import { AppealsCustomTableButton } from './AppealsCustomTableButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ListAppealsActions } from './slices/AppealsList.slice'
import { PaginationFooter } from 'app/__shared__/components/common/pagination-footer/PaginationFooter'
import { TableRowLoader } from '../__shared__/components/common/table-loader/TableDataLoader'
import { listAppealsThunk } from './slices/Appeals.thunk'
import { statusEnum } from 'app/__shared__/model/enums/statusLoading'

interface IPaginatedTableProps {
}

export const AppealsPaginatedTable = (props:IPaginatedTableProps) => {
    const dispatch = useAppDispatch()
    const listAppealsData = useAppSelector(state=> state.AppealsReducer.ListAppealsReducer)
    
    useEffect(() => {
        dispatch(listAppealsThunk({
            params:{
                ...listAppealsData.pageParams
            }
        }))
    }, [listAppealsData.pageParams, dispatch])

    // const handlePageLimit = (e:any) => {
    //     dispatch(ListAppealsActions.resetAllParameters())
    //     dispatch(ListAppealsActions.changePageLimit(e.target.value))
    // }
    
    // const changeCurrentPage = (page:number) => {
    //     dispatch(ListAppealsActions.changeCurrentPage(page))
    // }

    return (
        <div className='flex-col w-full mt-10'>
            <div className='w-full flex flex-col'>
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th scope="col">ID</th>
                            <th scope="col">Ad Soyad</th>
                            <th scope="col">Status</th>
                            <th scope="col">Filial</th>
                            <th scope="col">Müraciət Tarixi</th>
                            <th scope="col">Görüş Təyinatı</th>
                            <th scope="col">Əməliyyat</th>
                        </tr>
                    </thead>
                    <tbody>
                        {listAppealsData.status === statusEnum.loading &&  <TableRowLoader count={15}/>}
                        {listAppealsData.status === statusEnum.succeeded && listAppealsData.data.map((element:any) => {
                            return (
                                <tr key={element.id}>
                                    <th scope="row">
                                        {element.id}
                                    </th>
                                    <td>
                                        {element.name + " " + element.surname } 
                                    </td>
                                    <td>
                                        <div className={`flex gap-2 ${
                                            element.status.id === 1 ? "text-blue-500" :
                                            element.status.id === 2 ? "text-yellow-500" :
                                            element.status.id === 3 ? "text-green-500" :
                                            element.status.id === 4 ? "text-red-500" :""
                                        }`}>
                                            <span>
                                                {
                                                    element.status.id === 1 ? 
                                                    <FontAwesomeIcon icon={faClock}/> 
                                                    :
                                                    element.status.id === 2 ?
                                                    <FontAwesomeIcon icon={faCalendar}/> 
                                                    :
                                                    element.status.id === 3 ?
                                                    <FontAwesomeIcon icon={faCheck}/> 
                                                    :
                                                    element.status.id === 4 ?
                                                    <FontAwesomeIcon icon={faBan}/> 
                                                    :
                                                    ""
                                                }
                                            </span>
                                            <span>
                                                {element.status.name}
                                            </span>
                                        </div>
                                        
                                    </td>
                                    <td>
                                        {element?.branch?.name}
                                    </td>
                                    <td>
                                        <input type="date" value={element.created_at.slice(0,10)} disabled={true} className="bg-none" style={{background:"none"}}/>
                                    </td>
                                    <td>
                                        
                                        {
                                            element.meeting_date ? 
                                                <input type="datetime-local"  value={element.meeting_date.slice(0,16)} disabled={true} className="bg-none" style={{background:"none"}}/>
                                                 
                                                :  
                                                <span className='text-gray-400'>Təyin edilməyib</span>
                                        }
                                    </td>
                                    <td>
                                        <AppealsCustomTableButton element={element} id={element.id}/>
                                    </td>
                                </tr>
                            )})
                        }
                    </tbody>
                </table>
            </div>

            <PaginationFooter
                status={listAppealsData.status}
                params={
                    {
                        totalPages: listAppealsData.pageParams.totalPages,
                        currentPage: listAppealsData.pageParams.currentPage,
                        pageLimit: listAppealsData.pageParams.pageLimit,
                    }
                }
                actions={ListAppealsActions}
            />

            {/* <div className='flex w-full justify-center mt-9 mb-9'>
                <div className='flex gap-4'>
                    {
                        (listAppealsData.status === statusEnum.succeeded && listAppealsData.pageParams.totalPages !== null) &&
                        <>
                            <div  className='bg-[#1A67B4] text-lg px-3 py-1 text-white rounded-md'>
                                <select name="" id="" className='bg-[#1A67B4] focus:outline-0' onChange={handlePageLimit} value={listAppealsData.pageParams.pageLimit}>
                                    <option value="8">8</option>
                                    <option value="16">16</option>
                                    <option value="32">32</option>
                                    <option value="54">54</option>
                                    <option value="96">96</option>
                                </select>
                            </div>
                            <div className='flex justify-start items-center gap-3'>
                                <button disabled={listAppealsData.pageParams.currentPage < 2} className={'py-2 px-4 bg-[#1A67B4] rounded-lg text-white' + (listAppealsData.pageParams.currentPage===1 ? ' bg-gray-300 ' : '')} onClick={() => changeCurrentPage(listAppealsData.pageParams.currentPage-1)}>
                                    <FontAwesomeIcon icon={faAngleLeft} />
                                </button>
                                <button className={'py-2 px-4  rounded-lg text-white' + (listAppealsData.pageParams.currentPage === 1 ? " border border-[#1A67B4] border-solid text-[#1A67B4] " : " bg-[#1A67B4]")} onClick={() => changeCurrentPage(1)}>1</button>
                                
                                { listAppealsData.pageParams.currentPage >= 5 && <div className='py-2 px-4 bg-[#1A67B4] rounded-lg text-white'>...</div>}
                                
                                {
                                    listAppealsData.pageParams.currentPage < 5 &&
                                    <>
                                        {listAppealsData.pageParams.totalPages > 1 && <button className={'py-2 px-4  rounded-lg text-white' + (listAppealsData.pageParams.currentPage === 2 ? " border border-[#1A67B4] border-solid text-[#1A67B4] " : " bg-[#1A67B4]")} onClick={() => changeCurrentPage(2)}>2</button>}
                                        {listAppealsData.pageParams.totalPages > 2 && <button className={'py-2 px-4  rounded-lg text-white' + (listAppealsData.pageParams.currentPage === 3 ? " border border-[#1A67B4] border-solid text-[#1A67B4] " : " bg-[#1A67B4]")} onClick={() => changeCurrentPage(3)}>3</button>}
                                        {listAppealsData.pageParams.totalPages > 3 &&<button className={'py-2 px-4  rounded-lg text-white' + (listAppealsData.pageParams.currentPage === 4 ? " border border-[#1A67B4] border-solid text-[#1A67B4] " : " bg-[#1A67B4]")} onClick={() => changeCurrentPage(4)}>4</button>}
                                        {listAppealsData.pageParams.totalPages > 4 && <button className={'py-2 px-4  rounded-lg text-white' + (listAppealsData.pageParams.currentPage === 5 ? " border border-[#1A67B4] border-solid text-[#1A67B4] " : " bg-[#1A67B4]")} onClick={() => changeCurrentPage(5)}>5</button>}
                                    </>
                                }
                                
                                {
                                    (listAppealsData.pageParams.currentPage >= 5 && (listAppealsData.pageParams.totalPages - listAppealsData.pageParams.currentPage) > 3) &&
                                    <>
                                        <button className={'py-2 px-4 bg-[#1A67B4] rounded-lg text-white'} onClick={() => changeCurrentPage(listAppealsData.pageParams.currentPage - 1)}>{listAppealsData.pageParams.currentPage - 1}</button>
                                        <button className='py-2 px-4  rounded-lg text-white  border border-[#1A67B4] border-solid text-[#1A67B4]' onClick={() => changeCurrentPage(listAppealsData.pageParams.currentPage)}>{listAppealsData.pageParams.currentPage}</button>
                                        <button className={'py-2 px-4 bg-[#1A67B4] rounded-lg text-white'} onClick={() => changeCurrentPage(listAppealsData.pageParams.currentPage + 1)}>{listAppealsData.pageParams.currentPage + 1}</button>
                                        <div className='py-2 px-4 bg-[#1A67B4] rounded-lg text-white'>...</div>
                                    </>
                                }
                                
                                {(listAppealsData.pageParams.currentPage < 5 && (listAppealsData.pageParams.totalPages - listAppealsData.pageParams.currentPage) > 3)    && <div className='py-2 px-4 bg-[#1A67B4] rounded-lg text-white'>...</div>}

                                
                                {
                                    (listAppealsData.pageParams.currentPage >= 5 && (listAppealsData.pageParams.totalPages - listAppealsData.pageParams.currentPage) < 4) &&
                                    <>
                                        <button className={'py-2 px-4  rounded-lg text-white' + ((listAppealsData.pageParams.totalPages - listAppealsData.pageParams.currentPage - 4) === 0 ? " border border-[#1A67B4] border-solid text-[#1A67B4] " : " bg-[#1A67B4]")} onClick={() => changeCurrentPage(listAppealsData.pageParams.totalPages - 4)}>{listAppealsData.pageParams.totalPages - 4}</button>
                                        <button className={'py-2 px-4  rounded-lg text-white' + ((listAppealsData.pageParams.totalPages - listAppealsData.pageParams.currentPage - 3) === 0 ? " border border-[#1A67B4] border-solid text-[#1A67B4] " : " bg-[#1A67B4]")} onClick={() => changeCurrentPage(listAppealsData.pageParams.totalPages - 3)}>{listAppealsData.pageParams.totalPages - 3}</button>
                                        <button className={'py-2 px-4  rounded-lg text-white' + ((listAppealsData.pageParams.totalPages - listAppealsData.pageParams.currentPage - 2) === 0 ? " border border-[#1A67B4] border-solid text-[#1A67B4] " : " bg-[#1A67B4]")} onClick={() => changeCurrentPage(listAppealsData.pageParams.totalPages - 2)}>{listAppealsData.pageParams.totalPages - 2}</button>
                                        <button className={'py-2 px-4  rounded-lg text-white' + ((listAppealsData.pageParams.totalPages - listAppealsData.pageParams.currentPage - 1) === 0 ? " border border-[#1A67B4] border-solid text-[#1A67B4] " : " bg-[#1A67B4]")} onClick={() => changeCurrentPage(listAppealsData.pageParams.totalPages - 1)}>{listAppealsData.pageParams.totalPages - 1}</button>
                                    </>
                                }

                                {
                                    listAppealsData.pageParams.totalPages >= 5 &&
                                    <>
                                        <button className={'py-2 px-4  rounded-lg text-white' + (listAppealsData.pageParams.currentPage === listAppealsData.pageParams.totalPages ? " border border-[#1A67B4] border-solid text-[#1A67B4] " : " bg-[#1A67B4]")} onClick={() => changeCurrentPage(listAppealsData.pageParams.totalPages)}>{listAppealsData.pageParams.totalPages}</button>
                                        <button disabled={listAppealsData.pageParams.currentPage===listAppealsData.pageParams.totalPages} className={'py-2 px-4 bg-[#1A67B4] rounded-lg text-white' + (listAppealsData.pageParams.currentPage===listAppealsData.pageParams.totalPages ? ' bg-gray-300 ' : '')} onClick={() => changeCurrentPage(listAppealsData.pageParams.currentPage+1)}>
                                            <FontAwesomeIcon icon={faAngleRight} />
                                        </button>
                                    </>
                                }
                                
                                
                            </div>
                        </>
                    }
                </div>
                

            </div> */}
        </div>
    )
}



