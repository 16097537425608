import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { RootState } from '../../__shared__/components/store/store'
import { createAppealsThunk } from './Appeals.thunk'
import { statusEnum } from 'app/__shared__/model/enums/statusLoading'
import { viewVacancySettingsThunk } from 'app/vacancy-settings/slices/VacancySettings.thunk'

export interface ICreateAppeals{
    status:statusEnum,
    data?:any,
    settings_data:any,
    settings_status:statusEnum,
    submit_status:statusEnum,
    submit_errors:any
} 

const CreateAppealsState: ICreateAppeals = {
    status: statusEnum.idle,
    data:null,
    settings_data:null,
    settings_status:statusEnum.idle,
    submit_status:statusEnum.idle,
    submit_errors:null
}


export const CreateAppealsSlice = createSlice({
  name: 'create-appeals-slice',
  initialState: CreateAppealsState,
  reducers: {
    resetAppealsCreate: (state:ICreateAppeals) => {
        state.status = statusEnum.idle
        state.data = null
        state.submit_status = statusEnum.idle
        state.submit_errors = null
    }
      
  },
  
  extraReducers: (builder) => {
    builder.addCase(viewVacancySettingsThunk.pending, (state:ICreateAppeals, action: PayloadAction<any>) => {
        state.settings_status = statusEnum.loading
    })

    builder.addCase(viewVacancySettingsThunk.rejected, (state:ICreateAppeals, action:  PayloadAction<any>) => {
        state.settings_status = statusEnum.failed
    })

    builder.addCase(viewVacancySettingsThunk.fulfilled, (state:ICreateAppeals, action:  PayloadAction<any>) => {
        state.settings_status = statusEnum.succeeded
        state.settings_data = action.payload.data
    })

    
    builder.addCase(createAppealsThunk.pending, (state:ICreateAppeals, action: PayloadAction<any>) => {
        state.submit_status = statusEnum.loading
    })
    
    builder.addCase(createAppealsThunk.rejected, (state:ICreateAppeals, action:  PayloadAction<any>) => {
        state.submit_status = statusEnum.failed
        state.submit_errors = action.payload.errors
    })
    
    builder.addCase(createAppealsThunk.fulfilled, (state:ICreateAppeals, action:  PayloadAction<any>) => {
        state.submit_status = statusEnum.succeeded
        state.data = action.payload.data
    })


    
  },
})


export const CreateAppealsActions = {
    ...CreateAppealsSlice.actions,
}


export const CreateAppealsData = (state:RootState) => state.AppealsReducer.CreateAppealsReducer
export const CreateAppealsReducer = CreateAppealsSlice.reducer