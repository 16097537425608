import { statusEnum } from "app/__shared__/model/enums/statusLoading";
import { useCustomInputLogic } from "./useCustomInputLogic";
import { useInputErrorHandle } from "./useInputErrorHandle";
import { useState } from "react";

export interface CustomInputProps {
    value: any;
    onChange: (value: string) => void;
    required?: boolean;
    isFormSubmitStatus?:statusEnum
    accept?: string;
    regex?: RegExp;
    emptyError?: string;
    invalidError?: string;
    type?: string;
    placeHolder?: string;
    onlyNumber?: boolean;
    maxLength?: number;
    fileMessage?: string;
    minDate?: any;
    maxDate?: string;
    url?:string,
    file_name?:string,
    id?:any
}


export const CustomInput = (props: CustomInputProps) => {
    const { 
        required, 
        isFormSubmitStatus, 
        emptyError , 
        invalidError,
        value ,
        type, 
        placeHolder,
        maxLength,
        onlyNumber,
        minDate,
        maxDate,
        fileMessage,
        accept,
        url,
        file_name,
        id
    } = props
    
    const {
        isBlured , 
        isInValid , 
        isEmpty ,
        isClicked, 
        handleFocus, 
        handleBlur , 
        handleChange,
        hasFileName
    } = useCustomInputLogic(props)
    
    const {
        error, 
        errorClass, 
        errorSpanText
    } = useInputErrorHandle({
        isFormSubmitStatus , 
        isBlured , 
        isEmpty, 
        isInValid , 
        emptyError , 
        invalidError, 
        isClicked, 
        value
    })

    


    
    return (
        <>
            <div className="relative ">
                {
                    type === "file" ?
                    <>
                        <input
                            type={type}
                            name='file'
                            className={`form-control ${errorClass} absolute w-full h-full opacity-0`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            onFocus={handleFocus}
                            required={required}
                            placeholder={placeHolder}
                            maxLength={maxLength}
                            accept={accept}
                            id={id}
                        />
                        {url && <div className={`w-full h-full form-control ${errorClass}`}>{file_name ? file_name : hasFileName ? hasFileName : ''}</div>}
                        { !url && <div className={`w-full h-full form-control ${errorClass}`}>{fileMessage}</div>}
                    </>
                    :
                    <input
                        type={type}
                        className={`form-control ${errorClass}`}
                        value={value}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        onFocus={handleFocus}
                        required={required}
                        placeholder={placeHolder}
                        maxLength={maxLength}

                        min={minDate ? minDate : ""}
                        max={maxDate ? maxDate : ""}
                    />
                }
                
            </div>
            {errorSpanText && <small className="text-danger text-sm invalid">{error}</small>}
        </>
    );
};
