import { AXIOS_CONFIG } from "../config"
import { axiosInstance } from "../interceptor"

export const viewVacancySettingsService = async ({rejectWithValue}:any) => {
    try {
        const response = await axiosInstance(AXIOS_CONFIG.VACANCY_SETTINGS.VIEW_VACANCY_SETTINGS)
        return response.data
    } catch (error:any) {
        return rejectWithValue(error.response.data)
    }
}


export const editVacancySettingsService = async (data:any , {rejectWithValue}:any) => {
    try {
        const config = AXIOS_CONFIG.VACANCY_SETTINGS.UPDATE_VACANCY_SETTINGS
        config.data = data.data
        const response = await axiosInstance(config)
        return response.data
    } catch (error:any) {
        return rejectWithValue(error.response.data)
    }
}
