import { BranchesCreateForm } from '../BranchesCreateForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { faList } from '@fortawesome/free-solid-svg-icons';

export const BranchesCreate = () => {
    
    

    return (
        <div className="bg-white rounded p-4">
            <div className="row mb-4">
                <div className="col-md-12">
                    <div className="page-header">
                        <h1 className="font-weight-bold">Filial Məlumatlarını daxil edib təsdiqləyin.</h1>
                    </div>
                </div>
            </div>
            
            <div>
                <BranchesCreateForm/>
                
                
                <div className="row mb-5 mt-5">
                    <div className="col-md-12 text-center">
                        <Link
                            to="/branches"
                            className='btn btn-light'
                        >
                            <FontAwesomeIcon icon={faList} className="mr-2" />
                            Listə geri qayıt
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}
 