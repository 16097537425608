export const TableRowLoader = ({count}:{count:number}) => {
    return (
        <>
            {
                Array.from(Array(4).keys()).map((item, index) => {
                    return (
                        <>
                            <tr key={index} className="border-spacing-x-2.5	h-12">
                                <td className="animate-pulse bg-gray-200 "></td>
                                <td className="animate-pulse bg-gray-200 "></td>
                                <td className="animate-pulse bg-gray-200 "></td>
                                <td className="animate-pulse bg-gray-200 "></td>
                                <td className="animate-pulse bg-gray-200 "></td>
                                <td className="animate-pulse bg-gray-200 "></td>
                                <td className="animate-pulse bg-gray-200 "></td>
                            </tr>

                            <tr key={index + 100} className="border-spacing-x-2.5 h-12">
                                <td className="animate-pulse bg-white"></td>
                                <td className="animate-pulse bg-white"></td>
                                <td className="animate-pulse bg-white"></td>
                                <td className="animate-pulse bg-white"></td>
                                <td className="animate-pulse bg-white"></td>
                                <td className="animate-pulse bg-white"></td>
                                <td className="animate-pulse bg-white"></td>
                            </tr>
                        </>
                    )
                })
            }
            
        </>
        
    )
} 