import { Route, Routes } from "react-router-dom"
import { VacancySettings } from "./VacancySettings"
import { VacancySettingsEdit } from "./VacancySettingsEdit"


export const VacancySettingsRoute = () => {
    return (
        <Routes>
            <Route path="/" element={<VacancySettings/>} />
            <Route path="/update" element={<VacancySettingsEdit/>} />
        </Routes>
    )
}