import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

import { CustomInput } from 'app/__shared__/components/common/input/CustomInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import gescoLogo from 'app/__shared__/assets/media/logos/gesco.png';
import { useLoginState } from './useLoginState';

export const Login = () => {
    const {
        isPasswordVisible,
        emailValue,
        passwordValue,  
        emailRegex,
        isAllValidStatus,
        setPasswordVisible,
        setEmailValue,
        setPasswordValue,
        handleLoginSubmit
    } = useLoginState()
    
    
    
    
    return (
        <div className="login relative flex justify-center">
            <div className='create-appeal w-full absolute h-full'></div>

            <div className="login-wrapper p-4 p-md-5 py-10 ">
                <div className="form-logo">
                    <img width={100} src={gescoLogo} alt="logo" />
                </div>
                <form onSubmit={handleLoginSubmit}>
                    <div className="form-group">
                        <label  className="font-weight-bold text-xl text-white">Email</label>
                        <CustomInput 
                            value={emailValue}
                            onChange={setEmailValue}
                            required={true}
                            isFormSubmitStatus={isAllValidStatus}
                            regex={emailRegex}
                            placeHolder="Elektron poçt daxil edin"
                            emptyError={"Elektron poçt daxil edin"}
                            invalidError="Daxil etdiyiniz elektron poçt üçün düzgün deyil"
                        />
                    </div>
                    <div className="form-group position-relative">
                        <label  className="font-weight-bold  text-xl text-white">Şifrə</label>
                        <FontAwesomeIcon
                            icon={true ? faEye : faEyeSlash}
                            className="eye-icon -mt-1"
                            onClick={() => {
                                setPasswordVisible(!isPasswordVisible)
                            }}
                        
                        /> 
                        <CustomInput 
                            value={passwordValue}
                            onChange={setPasswordValue}
                            required={true}
                            isFormSubmitStatus={isAllValidStatus}
                            emptyError="Şifrəni daxil edin"
                            type={isPasswordVisible ? "text" : "password"}
                            placeHolder="Şifrəni daxil edin"
                        />
                        
                    </div>
                    <div className='w-full'>
                        <button className="w-full mx-auto block btn btn-primary font-weight-bold mt-5">
                        Daxil ol
                        </button>
                    </div>
                </form>
            </div>
        </div>
)
};
