import React, { useState } from "react";

import { CustomModal } from "../../common/modal/CustomModal";
import { modalEnum } from "app/__shared__/model/enums/modalEnum";

export interface IModalContext {
    modalStatus: modalEnum;
    toggleModalClose: () => void;
    toggleModalShow: any
    modalComponent: any;
    modalTitle: string;
}


export const ModalContext = React.createContext<IModalContext>({
    modalStatus: modalEnum.closed,
    toggleModalClose: () => { },
    toggleModalShow: null,
    modalComponent: <div>Component</div>,
    modalTitle:""
});


export const ModalContextProvider = ({children}:any) => {
    const [modalStatus ,setModalStatus] = useState<modalEnum>(modalEnum.closed);
    const [modalComponent, setModalComponent] = useState<JSX.Element>(<div>Component</div>);
    const [modalTitle, setModalTitle] = useState<string>("");

    const toggleModalClose = () => {
        setTimeout(() => {
            setModalComponent(<></>);
        }, 500);
        setModalStatus(modalEnum.closed);
    }


    const toggleModalShow = (component:JSX.Element , modalTitle:string) => {
        setModalTitle(modalTitle)
        setModalStatus(modalEnum.show);
        setModalComponent(component);
    }

    return (
        <ModalContext.Provider value={{ modalStatus, toggleModalClose, toggleModalShow, modalComponent, modalTitle }}>
            <CustomModal/>
            {children}
        </ModalContext.Provider>
    )
}