import { Link, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'app/__shared__/components/store/store';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormLoader } from 'app/__shared__/components/common/form-loader/FormLoader';
import { SectionBasedStatus } from 'app/__shared__/components/common/loader/SectionBasedStatus';
import { faList } from '@fortawesome/free-solid-svg-icons';
import { useEffect } from 'react';
import { viewUsersThunk } from '../slices/Users.thunk';

export const UsersView = () => {
    const selectUsersResponse = useAppSelector(state => state.UsersReducer.SelectUsersReducer)
    const selectUsersData = selectUsersResponse.response
    const dispatch = useAppDispatch()
    let { id } = useParams();

    useEffect(() => {
        dispatch(viewUsersThunk({id}))    
    }, [])
    
    

    return (
        <>
                <div className="bg-white rounded p-4">
                    <div className="row mb-4">
                        <div className="col-md-12">
                            <div className="page-header">
                                <h1 className="font-weight-bold">Baxış</h1>

                            </div>
                        </div>
                    </div>

                    <SectionBasedStatus 
                        loader_component={<FormLoader/>}
                        status={selectUsersResponse.status}
                    >
                        <form>
                            <div className="p-4 row mb-4 pt-2">
                                <div className="col-md-4 mb-2">
                                    <label className="text-muted">ID</label>
                                    <input
                                        className="form-control"
                                        name="id"
                                        placeholder="id"
                                        disabled
                                        defaultValue={selectUsersData.data.id}
                                    />
                                </div>
                                <div className="col-md-4 mb-2">
                                    <label>Ad</label>
                                    <input
                                        name="name"
                                        placeholder="Ad "
                                        disabled
                                        className="form-control"
                                        defaultValue={selectUsersData.data.name}
                                    />
                                </div>
                                <div className="col-md-4 mb-2">
                                    <label>Soyad</label>
                                    <input
                                        name="name"
                                        placeholder="Soyad"
                                        disabled
                                        className="form-control"
                                        defaultValue={selectUsersData.data.surname}
                                    />
                                </div>
                                <div className="col-md-4 mb-2">
                                    <label>Elektron poçt</label>
                                    <input
                                        name="name"
                                        placeholder="Elektron poçt"
                                        disabled
                                        className="form-control"
                                        defaultValue={selectUsersData.data.email}
                                    />
                                </div>

                                {
                                    selectUsersData.data.role && Object.keys(selectUsersData.data.role).length > 0 &&
                                    <table className="mt-10 table table-striped">
                                        <thead>
                                            <tr>
                                                <th scope="col">ID</th>
                                                <th scope="col">Aid olduğu rol adı</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                Object.keys(selectUsersData.data!.role).map((key:any) => {
                                                    return (
                                                        <tr key={selectUsersData.data.role[key]}>
                                                            <th scope="row">{key}</th>
                                                            <td >
                                                                <Link to={`/roles/${key}`}>
                                                                    {selectUsersData.data.role[key]}
                                                                </Link>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                }

                                {
                                    selectUsersData.data.branches.length > 0 && 
                                    <table className="table table-striped">
                                        <thead>
                                            <tr>
                                                <th scope="col">ID</th>
                                                <th scope="col">Bağlı olduğu filial adı</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                selectUsersData.data.branches.map((item:any) => {
                                                    return (
                                                        <tr key={item.id}>
                                                            <th scope="row">{item.id}</th>
                                                            <td>
                                                                <Link to={`/branches/${item.id}`}>{item.name}</Link> 
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                }
                            </div>
                            <div className="row">
                                <div className="col-md-12 text-center">
                                    <Link
                                        to="/users"
                                        className='btn btn-light'
                                    >
                                        <FontAwesomeIcon icon={faList} className="mr-2" />
                                        Listə geri qayıt
                                    </Link>
                                </div>
                            </div>
                        </form>
                    </SectionBasedStatus>
                    
                </div>
            </>
    )
}