import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { setStatusPending, setStatusRejected, setStatusSucceeded } from '../../__shared__/components/store/thunkStatusHandler'

import { RootState } from '../../__shared__/components/store/store'
import { createBranchesThunk } from './Branches.thunk'
import { statusEnum } from 'app/__shared__/model/enums/statusLoading'

export interface ICreateBranches {
    status:statusEnum,
    data?:any,
    submit_status:statusEnum,
    submit_errors:any
} 

const CreateBranchesState: ICreateBranches = {
    status: statusEnum.idle,
    submit_status:statusEnum.idle,
    data:null,
    submit_errors:null
}


export const CreateBranchesSlice = createSlice({
  name: 'create-branches-slice',
  initialState: CreateBranchesState,
  reducers: {
    resetBranchesCreate: (state:ICreateBranches) => {
        state.status = statusEnum.idle
        state.data = null
        state.submit_status = statusEnum.idle
        state.submit_errors = null
    }
      
  },
  
  extraReducers: (builder) => {
    
    builder.addCase(createBranchesThunk.pending, (state:ICreateBranches, action: PayloadAction<any>) => {
        state.submit_status = statusEnum.loading
    })
    
    builder.addCase(createBranchesThunk.rejected, (state:ICreateBranches, action:  PayloadAction<any>) => {
        state.submit_status = statusEnum.failed
        state.submit_errors = action.payload.errors
        console.log(action.payload)
    })
    
    builder.addCase(createBranchesThunk.fulfilled, (state:ICreateBranches, action:  PayloadAction<any>) => {
        state.submit_status = statusEnum.succeeded
        state.data = action.payload.data
    })


    
  },
})


export const CreateBranchesActions = {
    ...CreateBranchesSlice.actions,
}


export const CreateBranchesData = (state:RootState) => state.BranchesReducer.CreateBranchesReducer
export const CreateBranchesReducer = CreateBranchesSlice.reducer