import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSpinner } from "@fortawesome/free-solid-svg-icons"

export const EditFormLoader = () => {

    return (
       <>
            <div className="flex flex-col border-t-2 mx-auto gap-4" >
                <div className='animate-pulse flex gap-2 items-center py-2 w-full px-4 bg-slate-200 rounded-lg text-black justify-center'> 
                    <FontAwesomeIcon icon={faSpinner} className="animate-spin "/> 
                    <span>Məlumatlar yüklənir ...</span>
                </div>
                <div className="animate-pulse flex w-full">
                    <div className="flex w-full gap-4">
                        <div className='bg-slate-200 rounded  flex w-full justify-start items-center p-4 '></div>
                    </div>
                </div>
                <div className="animate-pulse flex w-full">
                    <div className="flex w-full gap-4">
                        <div className='bg-slate-200 rounded  flex w-full justify-start items-center p-4 '></div>
                    </div>
                </div>
                <div className="animate-pulse flex w-full">
                    <div className="flex w-full gap-4">
                        <div className='bg-slate-200 rounded  flex w-full justify-start items-center p-4 '></div>
                    </div>
                </div>
            </div>
       </> 
    )
}