import { useAppDispatch, useAppSelector } from "app/__shared__/components/store/store"

import { NormativesCustomTableButton } from "./NormativesCustomButton"
import { PaginationTableLoader } from "app/__shared__/components/common/table-loader/TableLoader"
import { TableRowLoader } from "app/__shared__/components/common/table-loader/TableDataLoader"
import { listNormativesThunk } from "./slices/Normatives.thunk"
import { statusEnum } from "app/__shared__/model/enums/statusLoading"
import { useEffect } from "react"

interface ICustomTableProps {
}

export const NormativesCustomTable = (props:ICustomTableProps) => {
    const normativesResponse = useAppSelector(state => state.NormativesReducer.ListNormativesReducer)
    const listNormatives = normativesResponse.response
    
    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(listNormativesThunk({}))
    }, [dispatch])
    
   
    return(
        <div className='flex-col w-full mt-10'>
            <table className="table table-striped">
                <thead>
                    <tr>
                    <th scope="col">ID</th>
                    <th scope="col">Ad</th>
                    <th scope="col">Status</th>
                    <th scope="col">Əməliyyat</th>
                    </tr>
                </thead>
                <tbody>
                    {normativesResponse.status === statusEnum.loading &&  <TableRowLoader count={15}/>}
                    {(normativesResponse.status === statusEnum.succeeded) && listNormatives.data.map((element:any) => {
                        return (
                            <tr key={element.id}>
                                <th scope="row">
                                    {element.id}
                                </th>
                                <td >
                                    {element.name}
                                </td>
                                <td className={(element.status ? ' text-green-500' : ' text-red-600')}>
                                    {element.status ? 'Aktiv' : 'Deaktiv'}
                                </td>
                                <td>
                                    <NormativesCustomTableButton id={element.id}/>
                                </td>
                            </tr>
                        )})
                    }
                </tbody>
            </table>
        </div>
    )
}


