import { deleteUsersThunk, listUsersThunk } from "./slices/Users.thunk"
import { useAppDispatch, useAppSelector } from "app/__shared__/components/store/store"
import { useContext, useEffect } from "react"

import { ModalContext } from "app/__shared__/components/context/modal/ModalContext"
import Swl from 'sweetalert2';
import toast from "react-hot-toast";

interface IUserDelete {
    id:number
}

export const UserDeleteForm = ({id}:IUserDelete) => {
    const listUsers = useAppSelector(state => state.UsersReducer.ListUsersReducer)
    const {toggleModalClose} = useContext(ModalContext)
    const dispatch = useAppDispatch()
    
    const deleteUser = async () => {
        toggleModalClose()
        await dispatch(deleteUsersThunk({id:id}))
        toast.success('İstadəçi məlumatları silindi!',);
        dispatch(listUsersThunk({...listUsers.pagination}))
    }

    return (
        <div className="flex flex-col ">
            <div className="row pt-2">
                <div className="col-md-12 text-center mb-4">
                    <h5 className="text-lg">Istifadəçini silmək istədiyinizdən əminsinizmi?</h5>
                </div>
            </div>

            <div className="justify-between gap-4 flex ">
                <button color="light" className="btn btn-primary w-full" onClick={toggleModalClose}>Ləğv et</button>
                <button color="danger" className="btn btn-danger font-weight-bold w-full" onClick={deleteUser}>Sil</button>
            </div>
        </div>
    )
}