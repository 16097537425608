import {  faPencil} from "@fortawesome/free-solid-svg-icons"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useNavigate } from "react-router-dom"

export const VacancySettingsEditButton = () => {
    const navigate = useNavigate()
    const goVacancySettingsUpdate = () => {
        navigate('update')
    }
    return (
        <button onClick={goVacancySettingsUpdate} className="flex rounded-md px-4 py-1 bg-[#1A67B4] text-lg text-white btn gap-3 items-center justify-center"  >
            <span > Yenilə</span>
            <FontAwesomeIcon icon={faPencil} className="mb-1"/>
        </button>
    )
}
