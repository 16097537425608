import { Collapse, NavLink as Link, NavItem } from 'reactstrap';
import { Icon, IconDefinition } from '@fortawesome/fontawesome-svg-core';
import React, {useState} from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLink } from "react-router-dom";

const SubMenu = ({items,title,mainIcon}:{items:{link:string,title:string,icon:IconDefinition,permission:boolean}[],title:string,mainIcon:IconDefinition}) => {
  const [isMenuOpen , setMenuOpen] = useState(false)

  const  toggleNavbar =() => {
    setMenuOpen(!isMenuOpen)
  }

  return (
      <div className=''>
        <NavItem onClick={toggleNavbar} className={`${isMenuOpen ? 'menu-open' : ''}`}>
          <Link className="nav-link dropdown-toggle"><FontAwesomeIcon icon={mainIcon} className="mr-2" />{title}</Link>
        </NavItem>
        <Collapse isOpen={isMenuOpen} navbar className={` ml-3 items-menu ${isMenuOpen ? 'd-block' : ''}`}>
          {
            items.map(
                item => item.permission ? (
                <NavItem key={item.link}>
                  <NavLink  className="nav-link" to={item.link}>{item.title}</NavLink>
                </NavItem>
              ): null
            )
          }
        </Collapse>
      </div>
  )
}

export default SubMenu;