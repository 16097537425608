import { Route, Routes } from "react-router-dom"

import { BranchesCreate } from "./BranchesCreate"
import { BranchesEdit } from "./BranchesEdit"
import { BranchesList } from "./BranchesList"
import { BranchesView } from "./BranchesView"

export const BranchesRoute = () => {
    return (
        <Routes>
            <Route path=":id/edit/" element={<BranchesEdit/>} />
            <Route path=":id" element={<BranchesView/>} />
            <Route path="/create" element={<BranchesCreate/>} />
            <Route path="/" element={<BranchesList/>} />
        </Routes>
    )
}