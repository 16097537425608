import { Nav, NavItem } from 'reactstrap';
import { NavLink, useLocation } from "react-router-dom";
import {
    faExclamationTriangle,
    faGear,
    faMapLocation,
    faNewspaper,
    faUserShield,
    faUsers
} from '@fortawesome/free-solid-svg-icons';
import { useContext, useLayoutEffect, useState } from "react";

import { AuthData } from '../../../../auth/Auth.slice';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GlobalContext } from '../../context/global/Global.context';
import SubMenu from '../../common/submenu/Submenu';
import { all_permissions } from 'app/__shared__/config/permissions';
import gescoLogo from "app/__shared__/assets/media/logos/gesco.png";
import { useAppSelector } from '../../store/store';

export const Sidebar = () => {
    const {user:{permissions}} = useAppSelector(AuthData);
    const {state, dispatchGlobalContext} = useContext(GlobalContext);
    const {USERS_PERMISSIONS, ROLES_PERMISSIONS, APPEAL_PERMISSIONS, BRANCHES_PERMISSIONS,VACANCY_SETTINGS_PERMISSIONS , NORMATIVES_PERMISSIONS } = all_permissions
    const toggle = () => { 
        dispatchGlobalContext({type: "TOGGLE_SIDEBAR"});
    }
    const location = useLocation()

    const [firstPathPart, setFirstPathPart] = useState(location.pathname.split('/')[1]);
    

    useLayoutEffect(() => {
        setFirstPathPart(location.pathname.split('/')[1])
    }, [location])
    
      

    return (
        <div className={`sidebar ${state.isSidebarOpen ? "is-open" : ""}`}>
            
            <div className="fixed">
                <div className="p-3 flex justify-center items-center relative">
                    <button className="times absolute left-0 top-0 hidden" onClick={toggle} >&times;</button>
                    <img src={gescoLogo} style={{width:'110px', height:"auto"}} alt="logo" />
                </div>
                <Nav vertical className="list-unstyled flex-column mt-5 p-4">
                    {
                        permissions[USERS_PERMISSIONS.show] &&
                        <NavLink to="/users"  className={`${firstPathPart === "users"? "text-blue-900" : "hover:translate-x-1 hover:text-blue-900"}`}>
                            <NavItem className="px-5 py-2 mt-2   transition-transform	">
                                <FontAwesomeIcon icon={faUsers} className="mr-1" /> İstifadəçilər
                            </NavItem>
                        </NavLink>
                    }

                    {
                        permissions[ROLES_PERMISSIONS.show] &&
                        <NavLink to="/roles" className={`${firstPathPart === "roles"? "text-blue-900" : "hover:translate-x-1 hover:text-blue-900"}`}>
                            <NavItem className="px-5 py-2 mt-2   transition-transform	">
                                <FontAwesomeIcon icon={faUserShield} className="mr-2" /> Rollar
                            </NavItem>
                        </NavLink>
                    }

                    {
                        permissions[APPEAL_PERMISSIONS.show] &&
                        <NavLink to="/appeals" className={`${firstPathPart === "appeals"? "text-blue-900" : "hover:translate-x-1 hover:text-blue-900"}`}>
                            <NavItem className="px-5 py-2 mt-2   transition-transform	">
                                <FontAwesomeIcon icon={faNewspaper} className="mr-2"/> Müraciətlər
                            </NavItem>
                        </NavLink>
                    }


                    {/* {
                        permissions[BRANCHES_PERMISSIONS.show] &&
                        <NavLink to="/branches" className={`${firstPathPart === "branches"? "text-blue-900" : "hover:translate-x-1 hover:text-blue-900"}`}>
                            <NavItem className="px-5 py-2 mt-2   transition-transform	">
                                <FontAwesomeIcon icon={faMapLocation} className="mr-2"/> Filiallar
                            </NavItem>
                        </NavLink>
                    } */}
                

                    {/* {
                        permissions[VACANCY_SETTINGS_PERMISSIONS.update] &&
                        <NavLink to="/settings" className={`${firstPathPart === "settings"? "text-blue-900" : "hover:translate-x-1 hover:text-blue-900"}`}>
                            <NavItem className="px-5 py-2 mt-2   transition-transform	">
                                <FontAwesomeIcon icon={faGear} className="mr-2"/> Sazlamalar
                            </NavItem>
                        </NavLink>
                    } */}
                
                
                    {/* {
                        permissions[VACANCY_SETTINGS_PERMISSIONS.update] &&
                        <NavLink to="/normatives" className={`${firstPathPart === "normatives"? "text-blue-900" : "hover:translate-x-1 hover:text-blue-900"}`}>
                            <NavItem className="px-5 py-2 mt-2   transition-transform	">
                                    <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2"/> Normativlər
                            </NavItem>
                        </NavLink>
                    } */}

                    {
                        // permissions[VACANCY_SETTINGS_PERMISSIONS.update] &&
                        <SubMenu 
                            title="Sazlamalar" 
                            mainIcon={faGear}
                            items={[{
                                title: "Vakansiya",
                                link: "/settings",
                                icon: faMapLocation,
                                permission:permissions[VACANCY_SETTINGS_PERMISSIONS.update]
                            },
                            {
                                title: "Filiallar",
                                link: "/branches",
                                icon: faMapLocation,
                                permission:permissions[BRANCHES_PERMISSIONS.show]
                            },
                            
                            {
                                title: "Normativlər",
                                link: "/normatives",
                                icon: faExclamationTriangle,
                                permission:permissions[VACANCY_SETTINGS_PERMISSIONS.update]
                            },
                        ]}
                        />
                    }
                </Nav>
            </div>
      </div >
    )
}