import "leaflet/dist/leaflet.css"

import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet'

import { Icon } from 'leaflet'
import logo from 'app/__shared__/assets/media/icon/location.png';

const myIcon = new Icon({
  iconUrl: logo,
  iconSize: [32,45]
 })


export const MapObject = ({lat,long}:{lat:number,long:number}) => {

    return (
        <div>
            <MapContainer className='h-96' center={[lat, long]} zoom={13} scrollWheelZoom={true}>
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <Marker position={[lat, long]} icon={myIcon}>
                    <Popup>
                        A pretty CSS3 popup. <br /> Easily customizable.
                    </Popup>
                </Marker>
            </MapContainer>
        </div>
    )
}