import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { deleteBranchesThunk, listAllBranchesThunk, listBranchesThunk } from './Branches.thunk'
import { setStatusPending, setStatusRejected, setStatusSucceeded } from '../../__shared__/components/store/thunkStatusHandler'

import { BranchesType } from 'app/__shared__/model/types/branches.type'
import { PaginationType } from 'app/__shared__/model/types/pagination.type'
import { RequestType } from 'app/__shared__/model/types/request'
import { RootState } from '../../__shared__/components/store/store'
import { statusEnum } from 'app/__shared__/model/enums/statusLoading'

export type ListBranchesType = {
    pagination:PaginationType
    list_all:{
        data:BranchesType,
        status:statusEnum
    }
} & RequestType<BranchesType>


const ListBranchesState: ListBranchesType = {
    status: statusEnum.idle,
    response:{
        response: "",
        code: "",
        message: "",
        data: [],
        errors: {},
    },
    pagination:{
        pageLimit:8,
        currentPage:1,
        totalPages:1,
        totalRecords:0,
    },
    list_all:{
        status:statusEnum.idle,
        data:[]
    }
}


export const ListBranchesSlice = createSlice({
  name: 'list-branches-slice',
  initialState: ListBranchesState,
  reducers: {
    changeCurrentPage: (state:ListBranchesType, action: PayloadAction<any>) => {
        state.pagination.currentPage = action.payload
    },
    
    handlePageLimit: (state:ListBranchesType, action: PayloadAction<any>) => {
        state.pagination.currentPage = 1
        state.pagination.totalPages = 1
        state.pagination.totalRecords = 0
        state.pagination.pageLimit = action.payload
    },

    changePageLimit: (state:ListBranchesType, action: PayloadAction<any>) => {
        state.pagination.pageLimit = action.payload.target.value
    },

    resetAllParameters: (state:ListBranchesType) => {
        state.pagination.pageLimit = 8
        state.pagination.currentPage = 1
        state.pagination.totalPages = 1
        state.pagination.totalRecords = 0
    },

    changeParams:(state:ListBranchesType, action: PayloadAction<any>) => {
        state.pagination = action.payload
    }
  },
  
  extraReducers: (builder) => {
    builder.addCase(listBranchesThunk.pending, (state:ListBranchesType, action: PayloadAction<any>) => {
        setStatusPending(state, action)
    })
    
    builder.addCase(listBranchesThunk.rejected, (state:ListBranchesType, action:  PayloadAction<any>) => {
        setStatusRejected(state, action)
        state.response.data = []
    })
    
    builder.addCase(listBranchesThunk.fulfilled, (state:ListBranchesType, action:  PayloadAction<any>) => {
        state.status = statusEnum.succeeded
        state.response.data = action.payload.data.data
        state.pagination.currentPage = action.payload.data.current_page
        state.pagination.totalPages = action.payload.data.last_page
        state.pagination.totalRecords = action.payload.data.total
    })


    builder.addCase(listAllBranchesThunk.pending, (state:ListBranchesType, action: PayloadAction<any>) => {
        state.list_all.status = statusEnum.loading
    })
    
    builder.addCase(listAllBranchesThunk.rejected, (state:ListBranchesType, action:  PayloadAction<any>) => {
        state.list_all.status = statusEnum.failed
    
    })
    
    builder.addCase(listAllBranchesThunk.fulfilled, (state:ListBranchesType, action:  PayloadAction<any>) => {
        state.list_all.status = statusEnum.succeeded
        state.list_all.data = action.payload.data
    })

    builder.addCase(deleteBranchesThunk.pending, (state:ListBranchesType, action: PayloadAction<any>) => {
    })
    
    builder.addCase(deleteBranchesThunk.rejected, (state:ListBranchesType, action:  PayloadAction<any>) => {
    })
    
    builder.addCase(deleteBranchesThunk.fulfilled, (state:ListBranchesType, action:  PayloadAction<any>) => {
    })
    
  },
})


export const ListBranchesActions = {
    ...ListBranchesSlice.actions,
}


export const ListBranchesData = (state:RootState) => state.BranchesReducer.ListBranchesReducer

export const ListBranchesReducer = ListBranchesSlice.reducer