import { Card, Collapse } from "reactstrap";
import React, { useCallback, useEffect, useState } from "react";
import { faBroom, faSearch } from "@fortawesome/free-solid-svg-icons";
import { listAllBranchesThunk, listBranchesThunk } from "app/branches/slices/Branches.thunk";
import { useAppDispatch, useAppSelector } from "app/__shared__/components/store/store";

import { BranchType } from "app/__shared__/model/types/branches.type";
import { CustomInput } from "app/__shared__/components/common/input/CustomInput";
import { CustomSelect } from "app/__shared__/components/common/select/CustomSelect";
import { CustomSelectNotValidated } from "app/__shared__/components/common/select/CustomSelectNotValidated";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ListAppealsActions } from "./slices/AppealsList.slice";
import Select from "react-select";
import { statusEnum } from "app/__shared__/model/enums/statusLoading";

export const AppealsSearch = () => {
    const dispatch = useAppDispatch()

    const listBranches = useAppSelector(state=>state.BranchesReducer.ListBranchesReducer)
    const listAppeals = useAppSelector(state=>state.AppealsReducer.ListAppealsReducer)
    const [filters, setFilters] = useState<{
        name:string,
        surname:string,
        father_name:string
        email:string,
        branches:BranchType[],
        selectedBranches:BranchType[],
        gender:{
            id:number,
            name:string
        }|null,
        is_military:{
            id:number,
            name:string
        }|null,
        has_crime:{
            id:number,
            name:string
        }|null,
        has_invalidity:{
            id:number,
            name:string
        }|null,
        status:{
            id:number,
            name:string
        }|null,
        min_height:string,
        max_height:string,
        min_weight:string,
        max_weight:string,
        fin:string,
        serial_number:string,
        address:string,
        filterStatus:statusEnum
    }>({
        name: "",
        surname: "",
        father_name: "",
        email:"",
        branches:[],
        selectedBranches:[],
        gender:null,
        is_military:null,
        has_crime:null,
        has_invalidity:null,
        status:null,
        min_height:"",
        max_height:"",
        min_weight:"",
        max_weight:"",
        fin:"",
        serial_number:"",
        address:"",
        filterStatus:statusEnum.idle,
    });

    const {
        name,
        surname,
        father_name,
        email,
        branches,
        selectedBranches,
        gender,
        is_military,
        has_crime,
        has_invalidity,
        status,
        min_height,
        max_height,
        min_weight,
        max_weight,
        fin,
        serial_number,
        address,
        filterStatus,
    } = filters

    useEffect(() => {
        dispatch(listAllBranchesThunk({}))
    }, [filters.status])
    

    useEffect(()=> {
        if(listBranches.status === statusEnum.succeeded){
            setFilters({
                ...filters,
                branches:listBranches.list_all.data
            })
        }
    }, [listBranches.status])
    
    
    const handleSearchClick = () => {
        dispatch(ListAppealsActions.changeParams({
            pageLimit:8,
            currentPage:1,
            totalPages:1,
            totalRecords:1,
            name,
            surname,
            father_name,
            min_weight,
            max_weight,
            min_height,
            max_height,
            is_military,
            has_crime,
            has_invalidity,
            gender,
            email,
            address,
            fin,
            serial_number,
            status,
            selectedBranches
        }))
    }
    
    const handleClear = async () => {
        await setFilters({
            name: "",
            surname: "",
            father_name: "",
            email:"",
            branches:[],
            selectedBranches:[],
            gender:null,
            is_military:null,
            has_crime:null,
            has_invalidity:null,
            status:null,
            min_height:"",
            max_height:"",
            min_weight:"",
            max_weight:"",
            fin:"",
            serial_number:"",
            address:"",
            filterStatus:statusEnum.idle,
        })

        dispatch(ListAppealsActions.changeParams({
            pageLimit:8,
            currentPage:1,
            totalPages:1,
            totalRecords:1,
        }))
        
    }

    const handleFormChange = (e:React.FormEvent) => {
        const { name, value } = e.target as HTMLInputElement
        setFilters({
            ...filters,
            filterStatus:statusEnum.succeeded,
            [name]: value
        })
        
    }

    return (
        <div className="w-full row mt-5">
            <Collapse className="w-full mt-5" isOpen={listAppeals.toggle_search}>
                <div className="w-full row mr-0 ml-0">
                    <div className="form-group col-md-3">
                        <input
                            className="form-control"
                            value={name}
                            placeholder='Adı daxil edin.'
                            name="name"
                            onChange={handleFormChange}
                        />
                    </div>
                    <div className="form-group col-md-3">
                        <input
                            className="form-control"
                            value={surname}
                            placeholder='Soyadı daxil edin.'
                            name="surname"
                            onChange={handleFormChange}
                        />
                    </div>

                    <div className="form-group col-md-3">
                        <input
                            className="form-control"
                            value={father_name}
                            placeholder='Ata adını daxil edin.'
                            name="father_name"
                            onChange={handleFormChange}
                        />
                    </div>

                    

                    <div className="form-group col-md-3">
                        <input
                            className="form-control"
                            value={min_weight}
                            placeholder='Minimum çəki daxil edin.'
                            name="min_weight"
                            onChange={handleFormChange}
                        />
                    </div>

                    <div className="form-group col-md-3">
                        <input
                            className="form-control"
                            value={max_weight}
                            placeholder='Maksimum çəki daxil edin.'
                            name="max_weight"
                            onChange={handleFormChange}
                        />
                    </div>

                    <div className="form-group col-md-3">
                        <input
                            className="form-control"
                            value={min_height}
                            placeholder='Minimum boy hündürlüyü.'
                            name="min_height"
                            onChange={handleFormChange}
                        />
                    </div>

                    <div className="form-group col-md-3">
                        <input
                            className="form-control"
                            value={max_height}
                            placeholder='Maksimum boy hündürlüyü.'
                            name="max_height"
                            onChange={handleFormChange}
                        />
                    </div>

                    <div className="form-group col-md-3">
                        <input
                            className="form-control"
                            value={email}
                            placeholder='Elektron poçtu daxil edin.'
                            name="email"
                            onChange={handleFormChange}
                        />
                    </div>

                    <div className="form-group col-md-3">
                        <input
                            className="form-control"
                            value={fin}
                            placeholder='Fin kodu daxil edin.'
                            name="fin"
                            onChange={handleFormChange}
                        />
                    </div>

                    <div className="form-group col-md-3">
                        <input
                            className="form-control"
                            value={serial_number}
                            placeholder='Şəxsiyyətin seriya nömrəsi'
                            name="serial_number"
                            onChange={handleFormChange}
                        />
                    </div>

                    <div className="form-group col-md-3">
                        <input
                            className="form-control"
                            value={address}
                            placeholder='Ünvanı daxil edin'
                            name="address"
                            onChange={handleFormChange}
                        />
                    </div>

                    <div className="form-group col-md-3">
                        <Select
                            placeholder="Cinsi seçin"
                            value={gender}
                            options={[{id:1, name:"Kişi"}, {id:2, name:"Qadın"}]}
                            getOptionValue={(option:any) => option.id}
                            getOptionLabel={option => option.name}
                            onChange={
                                (e:any) => {
                                    setFilters({
                                        ...filters, 
                                        gender:e, 
                                        filterStatus:statusEnum.succeeded,
                                    });
                                }
                            } 
                            isClearable
                            className="w-full"
                        />  
                    </div>

                    <div className="form-group col-md-3">
                        <small>Hərbi mükəlləfiyyət</small>
                        <Select
                            placeholder="Hərbi mükəlləfiyyəti seçin"
                            value={is_military}
                            options={[{id:0,name:'Xidmətdə olmamış.'},{id:1,name:'Xidməti başa vurmuş.'}]}
                            getOptionValue={(option:any) => option.id}
                            getOptionLabel={option => option.name}
                            onChange={
                                (e:any) => {
                                    setFilters({
                                        ...filters, 
                                        is_military:e,
                                        filterStatus:statusEnum.succeeded,
                                    });
                                }
                            } 
                            isClearable
                            className="w-full"
                        />
                    </div>

                    {/* <div className="form-group col-md-3">
                        <small>Əllilik</small>
                        <Select
                            placeholder="Əllilik növünü seçin"
                            value={has_invalidity}
                            options={[{id:1,name:'Var'},{id:0,name:'Yoxdur'}]}
                            getOptionValue={(option:any) => option.id}
                            getOptionLabel={option => option.name}
                            onChange={
                                (e:any) => {
                                    setFilters({
                                        ...filters, 
                                        has_invalidity:e,
                                        filterStatus:statusEnum.succeeded,
                                    });
                                }
                            } 
                            isClearable
                            className="w-full"
                        />
                    </div> */}

                    {/* <div className="form-group col-md-3">
                        <small>Məhkumluq</small>
                        <Select
                            placeholder="Məhkumluq növünü seçin"
                            value={has_crime}
                            options={[{id:1,name:'Var'},{id:0,name:'Yoxdur'}]}
                            getOptionValue={(option:any) => option.id}
                            getOptionLabel={option => option.name}
                            onChange={(e:any) => {
                                setFilters({
                                    ...filters, 
                                    has_crime:e,
                                    filterStatus:statusEnum.succeeded,
                                });
                            }} 
                            isClearable
                            className="w-full"
                        />   
                    </div> */}

                    <div className="form-group col-md-3">
                        <small>Müraciət statusu</small>
                        <Select
                            placeholder="Müraciətin statusu"
                            value={status}
                            options={[{id:1,name:'Gözləmədə'},{id:2,name:'Görüş təyin edilmiş'},{id:3,name:'İşə qəbul'},{id:4,name:'İmtina'}]}
                            getOptionValue={(option:any) => option.id}
                            getOptionLabel={option => option.name}
                            onChange={(e:any) => {
                                setFilters({
                                    ...filters, 
                                    status:e,
                                    filterStatus:statusEnum.succeeded,
                                });
                            }} 
                            isClearable
                            className="w-full"
                        />   
                    </div>

                    <div className="form-group col-md-12">
                        <small>Filialları seçin</small>
                        <Select
                            placeholder="Filialları seçin"
                            value={selectedBranches}
                            isLoading={listBranches.list_all.status === statusEnum.loading}
                            options={listBranches.list_all.data}
                            getOptionValue={(option:any) => option.id}
                            getOptionLabel={option => option.name}
                            onChange={
                                (e:any) => {
                                    setFilters({
                                        ...filters, 
                                        selectedBranches:e,
                                        filterStatus:statusEnum.succeeded,
                                    });
                                }
                            } 
                            isClearable
                            isMulti
                            className="w-full"
                        />  
                    </div>
                    

                    

                    <div className="form-group col-md-12 gap-2 flex ">
                        <button onClick={handleSearchClick} type="submit" className="btn bg-blue-600 btn-primary flex justify-start items-center gap-2">
                            Axtar 
                            <FontAwesomeIcon icon={faSearch} className={'text-sm'}/>
                        </button>
                        <button onClick={handleClear} type="button" className="btn bg-blue-600 btn-primary flex justify-start items-center gap-2">
                            Təmizlə 
                            <FontAwesomeIcon icon={faBroom} className={'text-sm'}/>                           
                        </button>
                    </div>
                </div>
                    
            </Collapse>
        </div>
    )
}