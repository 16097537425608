import { Route, Routes } from 'react-router-dom'

import React from 'react'
import { UsersRoute } from 'app/users/routes'
import { RolesRoute } from 'app/roles/routes'
import { AppealsRoute } from 'app/appeals/routes'
import { BranchesRoute } from 'app/branches/routes'
import { NormativesRoute } from 'app/normatives/routes'
import { VacancySettingsRoute } from 'app/vacancy-settings/routes'
import { Home } from 'app/dashboard/Home'
import { Navbar } from '../../layout/navbar/Navbar'

export const Content = () => {
    
    return (
        <div className='content'>
            <Navbar/>
            <Routes>
                <Route path="/users/*" element={<UsersRoute/>} />
                <Route path="/roles/*" element={<RolesRoute/>} />
                <Route path="/appeals/*" element={<AppealsRoute/>} />
                <Route path="/branches/*" element={<BranchesRoute/>} />
                <Route path="/settings/*" element={<VacancySettingsRoute/>} />
                <Route path="/normatives/*" element={<NormativesRoute/>} />
                <Route path="/" element={<Home/>} />
            </Routes>
        </div>
    )
}