import { Dispatch, createContext, useReducer } from "react";

interface initialStateInterface {
    theme: string,
    isSidebarOpen: boolean,
};


const initialState:initialStateInterface = {
    theme: "light",
    isSidebarOpen: true,
};


export const  GlobalContext = createContext<{state:initialStateInterface, dispatchGlobalContext:Dispatch<any>}>({
    state:{
        theme: "light",
        isSidebarOpen: true,
    },
    dispatchGlobalContext:()=>{},
});

const reducer = (state:initialStateInterface, action:any) => {
    switch (action.type) {
        case "TOGGLE_THEME":
            return {
                ...state,
                theme: state.theme === "light" ? "dark" : "light",
            };
        case "TOGGLE_SIDEBAR":
            console.log(state)
            return {
                ...state,
                isSidebarOpen: !state.isSidebarOpen,
            };
        default:
            return state;
    }
};



export const GlobalContextProvider = ({children}:any) => {
    const [state, dispatchGlobalContext] = useReducer(reducer, initialState);
    
    return (
        <GlobalContext.Provider value={{ state, dispatchGlobalContext }}>
            {children}
        </GlobalContext.Provider>
    );
};