import { Link, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'app/__shared__/components/store/store';

import { BranchesEditForm } from '../BranchesEditForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormLoader } from 'app/__shared__/components/common/form-loader/FormLoader';
import { faList } from '@fortawesome/free-solid-svg-icons';
import { statusEnum } from 'app/__shared__/model/enums/statusLoading';
import { useEffect } from 'react';
import { viewBranchesThunk } from '../slices/Branches.thunk';

export const BranchesEdit = () => {
    const dispatch = useAppDispatch()
    const selectedBranchData = useAppSelector(state => state.BranchesReducer.SelectBranchesReducer)
    let { id } = useParams();
    
    useEffect(() => {
        dispatch(viewBranchesThunk({id:id}))
    }, [])

    return (
        <div className="bg-white rounded p-4">
            <div className="row mb-4">
                <div className="col-md-12">
                    <div className="page-header">
                        <h1 className="font-weight-bold">Filial məlumatlarını yeniləyib təsdiqləyin.</h1>
                    </div>
                </div>
            </div>
            
            {selectedBranchData.status === statusEnum.loading && <FormLoader/>}
            <div>
                {
                    selectedBranchData.status === statusEnum.succeeded  &&
                    <>
                        <BranchesEditForm id={id} selectBranchesData={selectedBranchData}/>
                        <div className="row">
                            <div className="col-md-12 text-center">
                                <Link
                                    to="/branches"
                                    className='btn btn-light'
                                >
                                    <FontAwesomeIcon icon={faList} className="mr-2" />
                                    Listə geri qayıt
                                </Link>
                            </div>
                        </div>
                    </>
                }

                
                {
                    selectedBranchData.status === statusEnum.failed &&
                    <div className="row">
                        <div className="col-md-12">
                            <div className="alert alert-warning">
                                Məlumat tapılmadı
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}
 