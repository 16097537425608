import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { RequestType } from 'app/__shared__/model/types/request'
import { RoleType } from 'app/__shared__/model/types/role.type'
import { RootState } from '../../__shared__/components/store/store'
import { createRolesThunk } from './Roles.thunk'
import { statusEnum } from 'app/__shared__/model/enums/statusLoading'

export type CreateRolesType = {
    
} & RequestType<RoleType | null>

const CreateRolesState: CreateRolesType = {
    status: statusEnum.idle,
    response:{
        data:null,
        errors: {},
    }
}


export const CreateRolesSlice = createSlice({
  name: 'create-roles-slice',
  initialState: CreateRolesState,
  reducers: {
    resetRolesCreate: (state:CreateRolesType) => {
        state.status = statusEnum.idle
        state.response.data = null
        state.response.errors = {}
    }
      
  },
  
  extraReducers: (builder) => {
    
    builder.addCase(createRolesThunk.pending, (state, action: PayloadAction<any>) => {
        state.status = statusEnum.loading
    })
    
    builder.addCase(createRolesThunk.rejected, (state, action:  PayloadAction<any>) => {
        state.status = statusEnum.failed
        state.response.errors = action.payload.errors
    })
    
    builder.addCase(createRolesThunk.fulfilled, (state, action:  PayloadAction<any>) => {
        state.status = statusEnum.succeeded
        state.response.data = action.payload.data
    })
  },
})


export const CreateRolesActions = {
    ...CreateRolesSlice.actions,
}


export const CreateRolesData = (state:RootState) => state.RolesReducer.CreateRolesReducer
export const CreateRolesReducer = CreateRolesSlice.reducer