import { Route, Routes } from "react-router-dom"
import { UsersList } from "./UsersList"
import { UsersView } from "./UsersView"

export const UsersRoute = () => {
    return (
        <Routes>
            <Route path=":id" element={<UsersView/>} />
            <Route path="/" element={<UsersList/>} />
        </Routes>
    )
}