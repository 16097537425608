import { editVacancySettingsThunk, viewVacancySettingsThunk } from "../slices/VacancySettings.thunk";
import { faExclamationCircle, faSpinner } from "@fortawesome/free-solid-svg-icons"
import { useAppDispatch, useAppSelector } from "app/__shared__/components/store/store";
import { useEffect, useState } from "react"

import { CustomInput } from "app/__shared__/components/common/input/CustomInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FormLoader } from "app/__shared__/components/common/form-loader/FormLoader";
import Select from 'react-select';
import Swl from 'sweetalert2';
import { VacancySettingsActions } from "../slices/VacancySettings.slice";
import { VacancySettingsBackToButton } from "../VacancySettingsBackToButton";
import { statusEnum } from "app/__shared__/model/enums/statusLoading"
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom"

export const VacancySettingsEdit = () => {
    const vacancySettings = useAppSelector(state => state.VacancySettingsReducer)
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const [formData, setFormData] = useState<{
        gender:{
            id:number,
            name:string
        },
        required_military:{
            id:number,
            name:string
        },
        min_height:string,
        max_height:string,
        min_age:string,
        max_age:string,
        isAllValidStatus:statusEnum
    }>({
        gender:{
            id:1,
            name:"Kişi"
        },
        required_military:{
            id:1,
            name:"Aktiv"
        },
        min_height:"",
        max_height:"",
        min_age:"",
        max_age:"",
        isAllValidStatus:statusEnum.idle,
    })

    const {
        gender,
        required_military,
        min_height,
        min_age,
        max_age,
        isAllValidStatus,
    } = formData

    useEffect(()=> {
        if(vacancySettings.status === statusEnum.idle) {
            dispatch(viewVacancySettingsThunk({}))
        }
    }, [])


    useEffect(()=> {
        if(vacancySettings.update_status === statusEnum.succeeded) {
            toast.success('Vakansiya məlumatları yeniləndi!')
            dispatch(VacancySettingsActions.resetUpdate())
        }
    }, [vacancySettings.update_status])

    useEffect(()=> {
        if(vacancySettings.status === statusEnum.succeeded) {
            setFormData({
                gender:vacancySettings.data.gender === "1" ? {
                    id:1,
                    name:"Kişi"
                } :
                {
                    id:2,
                    name:"Qadın"
                },
                required_military:vacancySettings.data.required_military ? {
                    id:1,
                    name:"Hərbi xidməti başa vurmuş."
                } :
                {
                    id:0,
                    name:"Hərbi xidməti başa vurmamış."
                },
                min_height:vacancySettings.data.min_height,
                max_height:vacancySettings.data.max_height,
                min_age:vacancySettings.data.min_age,
                max_age:vacancySettings.data.max_age,
                isAllValidStatus:statusEnum.succeeded,
            })
        }
    }, [vacancySettings.status])
    
    const goSettings = () => {
        navigate('/settings')
    }


    const handleSubmit = (e:any) => {
        e.preventDefault()
        const data = {
            gender:formData.gender.id,
            required_military:formData.required_military.id,
            min_height:formData.min_height,
            min_age:formData.min_age,
            max_age:formData.max_age
        }
        console.log(data)
        dispatch(editVacancySettingsThunk({data:data}))
    }
    

    return (
        <div className="bg-white rounded-md p-6">
            
            <div className="w-full flex justify-between items-center mb-10">
                <h1 className="text-4xl align-top">Vakansiya Sazlamaları Yeniləmə</h1>
                <VacancySettingsBackToButton/>
            </div>

            {vacancySettings.status === statusEnum.loading && <FormLoader/>}
            <form onSubmit={handleSubmit}>
                {
                    vacancySettings.status === statusEnum.succeeded  &&
                    <>
                        <div className="row mb-4 pt-2">
                            <div className="col-md-4 mb-8">
                                <label>Cins Seçimi</label>
                                <Select
                                    placeholder="Rol növünü seçin"
                                    value={gender}
                                    options={[{id:1, name:"Kişi"}, {id:2, name:"Qadın"}]}
                                    getOptionValue={(option:any) => option.id}
                                    getOptionLabel={option => option.name}
                                    onChange={(e:any) => {
                                        setFormData({...formData, gender:e});
                                    }}
                                />   
                            </div>
                            <div className="col-md-4 mb-8">
                                <label>Hərbi bilet seçimi</label>
                                <Select
                                    placeholder="Hərbi bilet seçimi"
                                    value={required_military}
                                    options={[{id:1, name:"Hərbi xidməti başa vurmuş"}, {id:0, name:"Hərbi xidməti başa vurmamış."}]}
                                    getOptionValue={(option:any) => option.id}
                                    getOptionLabel={(option:any) => option.name}
                                    onChange={(e:any) => {
                                        setFormData({...formData, required_military:e});
                                    }}
                                />
                            </div>

                            <div className="col-md-4 mb-8">
                                <label>Minimum boy həddi</label>
                                <CustomInput
                                    required={true}
                                    isFormSubmitStatus={isAllValidStatus}
                                    emptyError={'Minimum boy həddini daxil edin.'}
                                    value={min_height}
                                    type='text'
                                    placeHolder='Minimum boy həddini daxil edin.'
                                    onChange={(data) => setFormData({...formData, min_height:data})}
                                />
                            </div>
                            
                            
                            <div className="col-md-4 mb-8">
                                <label>Minimum yaş həddi </label>
                                <CustomInput
                                    required={true}
                                    isFormSubmitStatus={isAllValidStatus}
                                    emptyError={'Minimum yaş həddini daxil edin.'}
                                    value={min_age}
                                    type='text'
                                    placeHolder='Minimum yaş həddini daxil edin.'
                                    onChange={(data) => setFormData({...formData, min_age:data})}
                                />
                            </div>
                            

                            <div className="col-md-4 mb-8">
                                <label>Maksimum yaş həddi</label>
                                <CustomInput
                                    required={true}
                                    isFormSubmitStatus={isAllValidStatus}
                                    emptyError={'Maksimum yaş həddini daxil edin.'}
                                    value={max_age}
                                    type='text'
                                    placeHolder='Maksimum yaş həddini daxil edin.'
                                    onChange={(data) => setFormData({...formData, max_age:data})}
                                />
                            </div>
                        </div>


                        <div className="row">
                        <div className="col-md-12 text-md-right">
                            <button
                                type="button"
                                onClick={goSettings}
                                className="btn btn-primary font-weight-bold ml-2 bg-gray-500 text-white"
                            >
                                Ləğv et
                            </button>
                            <button
                                type="submit"
                                className="btn btn-success font-weight-bold ml-2 bg-green-500 text-white hover:text-white"
                            >
                                Təsdiq et
                            </button>
                        </div>
                    </div>
                        
                    </>
                }
            {
                vacancySettings.update_status === statusEnum.loading &&
                <div className="flex w-full justify-center mt-2 items-center">
                    <div className="flex gap-4  items-center mt-4">
                        <FontAwesomeIcon icon={faSpinner} className='animate-spin'/>
                        <span>Yüklənir</span>
                    </div>
                </div>
            }
                
            {
                vacancySettings.update_status === statusEnum.failed &&
                <div className="flex w-full justify-center mt-2 items-center">
                    {Object.keys(vacancySettings.update_errors).map((key, index) => (
                        <div key={index} className="flex gap-4  items-center mt-4">
                            <FontAwesomeIcon icon={faExclamationCircle} className='text-red-500'/>
                            {
                                vacancySettings.update_errors[key].map((error:any, index:any) => (
                                    <span key={index}>{error}</span>
                                ))
                            }
                        </div>
                    ))}
                </div>
            }
                
                {
                    vacancySettings.status === statusEnum.failed &&
                    <div className="row">
                        <div className="col-md-12">
                            <div className="alert alert-warning">
                                Məlumat tapılmadı
                            </div>
                        </div>
                    </div>
                }
            </form>
        </div>
    )
}
