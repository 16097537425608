import {
    Button,
    Form,
} from 'reactstrap';
import { EditAppealsActions, IAppeal } from './slices/AppealsEdit.slice';
import React, { FormEvent, useEffect, useState } from "react"
import { azNumberRegexWithout994, emailRegex } from 'app/__shared__/data/regexs';
import { editAppealsThunk, viewAppealsThunk } from './slices/Appeals.thunk';
import { faExclamationCircle, faSpinner } from "@fortawesome/free-solid-svg-icons"
import { useAppDispatch, useAppSelector } from 'app/__shared__/components/store/store';

import { BAKU_CORDINATES } from 'app/__shared__/data/contants';
import { CustomInput } from 'app/__shared__/components/common/input/CustomInput';
import { CustomSelect } from 'app/__shared__/components/common/select/CustomSelect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormLoader } from 'app/__shared__/components/common/form-loader/FormLoader';
import { MapObjectSelectLocation } from 'app/__shared__/components/common/map/MapObjectSelectLocation';
import Select from 'react-select';
import Swl from 'sweetalert2';
import { statusEnum } from "app/__shared__/model/enums/statusLoading"
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { viewVacancySettingsThunk } from 'app/vacancy-settings/slices/VacancySettings.thunk';

export interface IAppealEditForm {
    id: any,
    selectedAppealData:any
}

export const AppealsEditForm = ({id, selectedAppealData}:IAppealEditForm) => {
    const editAppealData = useAppSelector(state => state.AppealsReducer.EditAppealsReducer);
    const settingsData = useAppSelector(state => state.VacancySettingsReducer);
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    console.log(selectedAppealData)
    const [formData, setFormAppealData] = useState<any>({
        name: selectedAppealData.data.name,
        surname: selectedAppealData.data.surname,
        father_name: selectedAppealData.data.father_name,
        email: selectedAppealData.data.email,
        serial_number: selectedAppealData.data.serial_number,
        fin_number: selectedAppealData.data.fin,
        address: selectedAppealData.data.address,
        position:{
            lat:selectedAppealData.data.lat,
            lng:selectedAppealData.data.long
        },
        phone: selectedAppealData.data.phone,
        gender:{id:selectedAppealData.data.gender, name: selectedAppealData.data.gender === "1" ? "Kişi": "Qadın"},
        is_military: {id:selectedAppealData.data.is_military , name:selectedAppealData.data.is_military  === 1 ? "Xidməti başa vurmuş" : "Xidmətdə olmamış"},
        has_crime: {id:selectedAppealData.data.has_crime , name:selectedAppealData.data.has_crime === 1 ? "Bəli" : "Xeyr"},
        has_invalidity: {id:selectedAppealData.data.has_invalidity , name:selectedAppealData.data.has_invalidity === 1 ? "Bəli" : "Xeyr"},
        birthday: selectedAppealData.data.birthday,
        image: selectedAppealData.data.image,
        video: selectedAppealData.data.video,
        weight: selectedAppealData.data.weight,
        height: selectedAppealData.data.height,
        isAllValidStatus: statusEnum.succeeded
    })
    const {name, surname, father_name, address,birthday,email,fin_number,gender,has_crime,has_invalidity,height,image,isAllValidStatus,is_military,phone,position,serial_number,video,weight} = formData

    useEffect(() => {
        dispatch(viewVacancySettingsThunk({}))
    }, [])

    useEffect(() => {
        if(editAppealData.submit_status === statusEnum.succeeded){
            toast.success('Məlumatlar bazada yeniləndi!')
            dispatch(EditAppealsActions.resetAppealsEdit())
            // navigate('/appeals')
        }
    }, [editAppealData.submit_status])
    

    const handleSubmit = (e:FormEvent) => {
        e.preventDefault()
        if(
            !name  || 
            !surname || 
            !father_name || 
            !address || 
            !birthday || 
            !emailRegex.test(email) || 
            !fin_number || 
            !serial_number || 
            !azNumberRegexWithout994.test(phone) || 
            !position.lat || 
            !position.lng || 
            !weight || 
            !height || 
            gender===null ||
            is_military===null ||
            has_crime===null ||
            has_invalidity===null) {
            setFormAppealData(
                {
                    ...formData,
                    isAllValidStatus:statusEnum.failed
                }
            )
        }
        else{
            setFormAppealData(
                {
                    ...formData,
                    isAllValidStatus:statusEnum.succeeded
                }
            )
            
            dispatch(editAppealsThunk({
                id:id,
                data:{
                    type: "UPDATE",
                    name,
                    surname,
                    father_name,
                    email,
                    address,
                    phone,
                    serial_number,
                    fin:fin_number,
                    // height,
                    // birthday: new Date(birthday).toISOString(),
                    // lat: position.lat,
                    // long: position.lng,
                    // weight,
                    // is_military: `${is_military.id}`,
                    // has_crime: `${has_crime.id}`,
                    // has_invalidity:`${has_invalidity.id}`,
                    // gender:`${gender.id}`
                }
            }))
        }
    }
    
    return (
        <div >
            {editAppealData.status === statusEnum.loading && <FormLoader/>}
            <Form   onSubmit={handleSubmit} className="row">
                <div className="form-group col-md-6">
                    <label >Adınızı daxil edin</label>
                    <CustomInput
                        required={true}
                        isFormSubmitStatus={isAllValidStatus}
                        emptyError={'Adınızı daxil edin.'}
                        value={name}
                        type='text'
                        placeHolder='Adınızı daxil edin.'
                        onChange={(data) => {
                            setFormAppealData({
                                ...formData,
                                name: data,
                            })
                        }}
                    />
                </div>

                <div className="form-group  col-md-6">
                    <label >Soyadınızı daxil edin</label>
                    <CustomInput
                        required={true}
                        isFormSubmitStatus={isAllValidStatus}
                        emptyError={'Soyadınızı daxil edin.'}
                        value={surname}
                        type='text'
                        placeHolder='Soyadınızı daxil edin.'
                        onChange={(data) => {
                            setFormAppealData({
                                ...formData,
                                surname: data,
                            })
                        }}
                    />
                </div>

                <div className="form-group  col-md-6">
                    <label >Ata adını daxil edin</label>
                    <CustomInput
                        required={true}
                        isFormSubmitStatus={isAllValidStatus}
                        emptyError={'Ata adını daxil edin.'}
                        value={father_name}
                        type='text'
                        placeHolder='Ata adını daxil edin.'
                        onChange={(data) => {
                            setFormAppealData({
                                ...formData,
                                father_name: data,
                            })
                        }}
                    />
                </div>

                <div className="form-group  col-md-6">
                    <label >Elektron poçtunuzu daxil edin</label>
                    <CustomInput
                        required={true}
                        isFormSubmitStatus={isAllValidStatus}
                        emptyError={'Elektron poçtunuzu daxil edin.'}
                        invalidError={"Elektron poçtunuzu düzgün daxil edin."}
                        value={email}
                        type='email'
                        placeHolder='Elektron poçtunuzu daxil edin.'
                        onChange={(data) => {
                            setFormAppealData({
                                ...formData,
                                email: data,
                            })
                        }}
                        regex={emailRegex}
                    />
                </div>

                <div className="form-group  col-md-6">
                    <label >Yaşadığınız yeri qeyd edin</label>
                    <CustomInput
                        required={true}
                        isFormSubmitStatus={isAllValidStatus}
                        emptyError={'Yaşadığınız yeri qeyd edin'}
                        value={address}
                        type='text'
                        placeHolder='Yaşadığınız yeri qeyd edin'
                        onChange={(data) => {
                            setFormAppealData({
                                ...formData,
                                address: data,
                            })
                        }}
                    />
                </div>

                <div className="form-group  col-md-6">
                    <label >Şəxsiyyətin seriya nömrəsini daxil edin</label>
                    <CustomInput
                        required={true}
                        isFormSubmitStatus={isAllValidStatus}
                        emptyError={'Şəxsiyyətin seriya nömrəsini daxil edin.'}
                        value={serial_number}
                        type='text'
                        onlyNumber={false}
                        placeHolder='Şəxsiyyətin seriya nömrəsini daxil edin.'
                        onChange={(data) => {
                            setFormAppealData({
                                ...formData,
                                serial_number: data,
                            })
                        }}
                    />
                </div>

                <div className="form-group  col-md-6">
                    <label >Şəxsiyyətin fin nömrəsini daxil edin</label>
                    <CustomInput
                        required={true}
                        isFormSubmitStatus={isAllValidStatus}
                        emptyError={'Şəxsiyyətin fin nömrəsini daxil edin.'}
                        value={fin_number}
                        type='text'
                        placeHolder='Şəxsiyyətin fin nömrəsini daxil edin.'
                        onChange={(data) => {
                            setFormAppealData({
                                ...formData,
                                fin_number: data,
                            })
                        }}
                    />
                </div>

                <div className="form-group  col-md-6">
                    <label >Telefon nömrəsini daxil edin</label>
                    <CustomInput
                        required={true}
                        isFormSubmitStatus={isAllValidStatus}
                        emptyError={'Telefon nömrəsini daxil edin.'}
                        invalidError={'Telefon nömrəsi düzgün deyil.'}
                        value={phone}
                        type='text'
                        placeHolder='0701234567'
                        regex={azNumberRegexWithout994}
                        maxLength={10}
                        onlyNumber={true}
                        onChange={(data) => {
                            setFormAppealData({
                                ...formData,
                                phone: data,
                            })
                        }}
                    />
                </div>

                {/* <div className="form-group">
                    <label >Hərbi mükəlləfiyyətinizi seçin</label>
                    <Select
                        placeholder="Hərbi mükəlləfiyyəti seçin"
                        value={is_military}
                        options={[{id:0,name:'Xidmətdə olmamış.'},{id:1,name:'Xidməti başa vurmuş.'}]}
                        getOptionValue={(option:any) => option.id}
                        getOptionLabel={option => option.name}
                        onChange={
                            (e:any) => {
                                setFormAppealData({
                                    ...formData,
                                    is_military:e
                                })
                            }
                        } 
                        isClearable
                        className="w-full"
                    />
                </div> */}

                {/* <div className="form-group">
                    <label >Cinsi seçin</label>
                    <Select
                        placeholder="Cinsi seçin"
                        value={gender}
                        options={[{id:'1', name:"Kişi"}, {id:'2', name:"Qadın"}]}
                        getOptionValue={(option:any) => option.id}
                        getOptionLabel={option => option.name}
                        onChange={
                            (e:any) => {
                                setFormAppealData({
                                    ...formData,
                                    gender:e
                                })
                            }
                        } 
                        isClearable
                        className="w-full"
                    />  
                </div> */}
                

                {/* <div className="form-group">
                    <label>Məhkumluğunuz varmı ?</label>
                    <Select
                        placeholder="Məhkumluq növünü seçin"
                        value={has_crime}
                        options={[{id:1,name:'Bəli'},{id:0,name:'Xeyr'}]}
                        getOptionValue={(option:any) => option.id}
                        getOptionLabel={option => option.name}
                        onChange={(e:any) => {
                            setFormAppealData({
                                ...formData,
                                has_crime:e
                            })
                        }} 
                        isClearable
                        className="w-full"
                    /> 
                </div> */}
                
                {/* <div className="form-group">
                    <label>Əlliliyiniz varmı ?</label>
                    <Select
                        placeholder="Əllilik növünü seçin"
                        value={has_invalidity}
                        options={[{id:1,name:'Bəli'},{id:0,name:'Xeyr'}]}
                        getOptionValue={(option:any) => option.id}
                        getOptionLabel={option => option.name}
                        onChange={
                            (e:any) => {
                                setFormAppealData({
                                    ...formData,
                                    has_invalidity:e
                                })
                            }
                        } 
                        isClearable
                        className="w-full"
                    />
                </div> */}

                {/* <div className="form-group">
                    <label>Boy hündürlüyünüzü daxil edin. (santimetrlə)</label>
                    <CustomInput
                        required={true}
                        isFormSubmitStatus={isAllValidStatus}
                        emptyError={'Boy hündürlüyünüzü daxil edin.'}
                        value={height}
                        type='text'
                        placeHolder='180'
                        onlyNumber={true}
                        onChange={(data) => {
                            setFormAppealData({
                                ...formData,
                                height: data,
                            })
                        }}
                    />
                    <small>*Minimum boy hündürlüyü minimum 180 olmalıdır.</small>
                </div> */}

                {/* <div className="form-group">
                    <label>Kütlənizi daxil edin.</label>
                    <CustomInput
                        required={true}
                        isFormSubmitStatus={isAllValidStatus}
                        emptyError={'Kütlənizi daxil edin.'}
                        value={weight}
                        type='text'
                        placeHolder='50'
                        onlyNumber={true}
                        onChange={(data) => {
                            setFormAppealData({
                                ...formData,
                                weight: data,
                            })
                        }}
                    />
                </div> */}
                
                {/* <div className="form-group">
                    <label>Təvəllüdünüzü daxil edin.</label>
                    <CustomInput
                        required={true}
                        isFormSubmitStatus={isAllValidStatus}
                        emptyError={'Təvəllüdünüzü daxil edin.'}
                        value={birthday}
                        type='date'
                        onChange={(data) => {
                            setFormAppealData({
                                ...formData,
                                birthday: data,
                            })
                        }}
                    />
                </div> */}


                {/* <div className="form-group">
                    <label >Yaşadığınız məkana uyğun addressi xəritə üzərində seçin</label>
                    <MapObjectSelectLocation position={position} setPosition={
                        (data:any) => {
                            setFormAppealData({
                                ...formData,
                                position: data,
                            })
                        }
                    }/>
                </div> */}

                <div className="col-md-12 mt-5  w-full">
                    <div className="text-md-right flex justify-end w-full">
                        <Button
                            type="submit"
                            color="success"
                            className="font-weight-bold  bg-green-500 text-white hover:text-white"
                        >
                            Təsdiq et
                        </Button>
                    </div>
                </div>    


                {
                    editAppealData.submit_status === statusEnum.loading &&
                    <div className="flex w-full justify-center mt-2 items-center">
                        <div className="flex gap-4  items-center mt-5 mb-2">
                            <FontAwesomeIcon icon={faSpinner} className='animate-spin'/>
                            <span>Yüklənir</span>
                        </div>
                    </div>
                }

                {
                    editAppealData.submit_status === statusEnum.failed &&
                    <div className="flex flex-col w-full justify-center mt-2 items-center">
                        {Object.keys(editAppealData.submit_errors).map((key, index) => (
                            <div key={index} className="w-full flex gap-4  items-center mt-4">
                                <FontAwesomeIcon icon={faExclamationCircle} className='text-red-500'/>
                                {
                                    editAppealData.submit_errors[key].map((error:any, index:any) => (
                                        <span key={index}>{error}</span>
                                    ))
                                }
                            </div>
                        ))}
                    </div>
                }
            </Form>
        </div>
    )
}