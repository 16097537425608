import {
    Button,
    Form,
    Label,
} from 'reactstrap';
import React, { useContext, useEffect, useState } from "react"
import { faCircleExclamation, faExclamationCircle, faSpinner } from "@fortawesome/free-solid-svg-icons"
import { useAppDispatch, useAppSelector } from 'app/__shared__/components/store/store';

import { BAKU_CORDINATES } from 'app/__shared__/data/contants';
import { CreateAppealsActions } from './slices/AppealsCreate.slice';
import { CustomInput } from 'app/__shared__/components/common/input/CustomInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MapObjectSelectLocation } from 'app/__shared__/components/common/map/MapObjectSelectLocation';
import { ModalContext } from 'app/__shared__/components/context/modal/ModalContext';
import Select from 'react-select';
import Swl from 'sweetalert2';
import { createAppealsThunk } from './slices/Appeals.thunk';
import { statusEnum } from "app/__shared__/model/enums/statusLoading"
import toast from 'react-hot-toast';

export const AppealsCreateForm = () => {
    const createappealData = useAppSelector(state => state.AppealsReducer.CreateAppealsReducer)
    const {toggleModalClose} = useContext(ModalContext)
    const dispatch = useAppDispatch()
    const [appealCreateData, setAppealCreateData] = useState<
    {
        name: string,
        address: string,
        chief_email: string,
        chief: string,
        position:{
            lat:number,
            lng:number
        },
        status:any,
        isAllValidStatus:statusEnum
    }
    >({
        name: "",
        address: "",
        chief:"",
        chief_email: "",
        position:{
            lat:BAKU_CORDINATES.lat,
            lng:BAKU_CORDINATES.lng
        },
        status:{id:1, label:"Aktiv"},
        isAllValidStatus:statusEnum.idle
    })

    const {
        name,
        address,
        chief_email,
        chief,
        position,
        status,
        isAllValidStatus
    } = appealCreateData



    useEffect(() => {
        if(createappealData.submit_status === statusEnum.succeeded){
            toast.success("Müraciət bazaya əlavə edildi!")
            dispatch(CreateAppealsActions.resetAppealsCreate())
        }
    }, [createappealData.submit_status])

   

    const handleSubmit = (e:React.FormEvent) => {
        e.preventDefault()

        if(
            !appealCreateData.name ||
            !appealCreateData.address ||
            !appealCreateData.chief ||
            !appealCreateData.chief_email ||
            (
                appealCreateData.position.lat === BAKU_CORDINATES.lat && 
                appealCreateData.position.lng === BAKU_CORDINATES.lng
            )
        )   
        {
            setAppealCreateData({
                ...appealCreateData,
                isAllValidStatus:statusEnum.failed
            })
            return 
        }else{
            setAppealCreateData({
                ...appealCreateData,
                isAllValidStatus:statusEnum.succeeded
            })
        }


        dispatch(createAppealsThunk(
            {
                data:{
                    name: appealCreateData.name,
                    address: appealCreateData.address,
                    chief: appealCreateData.chief,
                    chief_email: appealCreateData.chief_email,
                    status: appealCreateData.status.id,
                    lat: appealCreateData.position.lat,
                    long: appealCreateData.position.lng
                }
            }
        ))

        
        
    }
    
    return (
        <div className='mb-5'>
            <Form   onSubmit={handleSubmit}>
                <div className="row pt-2">
                    
                    <div className="col-md-6">
                        <Label for="name" className="font-weight-bold">Müraciət Adı</Label>
                        <CustomInput
                            required={true}
                            isFormSubmitStatus={isAllValidStatus}
                            emptyError={'Müraciət adını daxil edin.'}
                            value={name}
                            type='text'
                            placeHolder='Müraciət adını daxil edin.'
                            onChange={(data) => setAppealCreateData({...appealCreateData, name: data})}
                        />
                    </div>
                    
                    <div className="col-md-6">
                        <Label>Status</Label>
                        <Select
                            placeholder="Status"
                            value={status}
                            options={[{id:1, label:"Aktiv"}, {id:0, label:"Deaktiv"}]}
                            getOptionValue={option => option.id}
                            getOptionLabel={option => option.label}
                            onChange={(e) => {
                                setAppealCreateData({...appealCreateData, status: e})
                            }}
                        />
                    </div>
                    
                    

                    <div className="col-md-6 mt-4">
                        <Label>Məsul şəxs</Label>
                        <CustomInput
                            required={true}
                            isFormSubmitStatus={isAllValidStatus}
                            emptyError={'Məsul şəxsin adını daxil edin.'}
                            value={chief}
                            type='text'
                            placeHolder='Məsul şəxsin adı'
                            onChange={(data) => setAppealCreateData({...appealCreateData, chief: data})}
                        />
                    </div>

                    <div className="col-md-6 mt-4">
                        <Label>Elektron Poçt</Label>
                        <CustomInput
                            required={true}
                            isFormSubmitStatus={isAllValidStatus}
                            emptyError={'Elektron poçtu daxil edin.'}
                            value={chief_email}
                            type='email'
                            placeHolder='Elektron poçt'
                            onChange={
                                (data) => setAppealCreateData({...appealCreateData, chief_email: data})
                            }
                        />
                    </div>

                    

                    <div className="col-md-12 mt-5">
                        <Label>Müraciətın detallı adresi</Label>
                        <CustomInput
                            required={true}
                            isFormSubmitStatus={isAllValidStatus}
                            emptyError={'Məkanın detallı adresini daxil edin.'}
                            value={address}
                            type='text'
                            placeHolder='Məkanın detallı adresini daxil edin.'
                            onChange={
                                (data) => setAppealCreateData({...appealCreateData, address: data})
                            }
                        />
                    </div>

                    

                    <div className="col-md-12 mt-5">
                        <Label>Müraciətı xəritə üzərindəki uyğun məkanını seçmək üçün ikona klikləyərək sürükləyin.</Label>
                        <MapObjectSelectLocation position={position} setPosition={
                            (data:any) => setAppealCreateData({...appealCreateData, position: data})}
                        />
                        <div className='h-3'>
                            {
                                (isAllValidStatus === statusEnum.failed && (appealCreateData.position.lat === BAKU_CORDINATES.lat && appealCreateData.position.lng === BAKU_CORDINATES.lng)) && 
                                <div className="text-danger text-sm invalid flex gap-3 mt-2"> 
                                    <FontAwesomeIcon icon={faCircleExclamation}/>
                                    <span>Müraciəta uyğun məkan seçimi edin.</span> 
                                </div>
                            }
                        </div>
                    </div>
                    
                </div>
                <div className="row mt-5">
                    <div className="col-md-12 text-md-right">
                        <Button
                            type="button"
                            color="default"
                            onClick={toggleModalClose}
                            className="font-weight-bold ml-2 bg-gray-500 text-white"
                        >
                            Ləğv et
                        </Button>
                        <Button
                            type="submit"
                            color="success"
                            className="font-weight-bold ml-2 bg-green-500 text-white hover:text-white"
                        >
                            Təsdiq et
                        </Button>
                    </div>
                </div>
                {
                    createappealData.submit_status === statusEnum.loading &&
                    <div className="flex w-full justify-center mt-2 items-center">
                        <div className="flex gap-4  items-center mt-4">
                            <FontAwesomeIcon icon={faSpinner} className='animate-spin'/>
                            <span>Yüklənir</span>
                        </div>
                    </div>
                }

                {
                    createappealData.submit_status === statusEnum.failed &&
                    <div className="flex flex-col w-full justify-center mt-2 items-center">
                        {Object.keys(createappealData.submit_errors).map((key, index) => (
                            <div key={index} className="w-full flex gap-4  items-center mt-4">
                                <FontAwesomeIcon icon={faExclamationCircle} className='text-red-500'/>
                                {
                                    createappealData.submit_errors[key].map((error:any, index:any) => (
                                        <span key={index}>{error}</span>
                                    ))
                                }
                            </div>
                        ))}
                    </div>
                }
            </Form>
        </div>
    )
}