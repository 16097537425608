import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { setStatusPending, setStatusRejected, setStatusSucceeded } from '../../__shared__/components/store/thunkStatusHandler'

import { RootState } from '../../__shared__/components/store/store'
import { viewBranchesThunk } from './Branches.thunk'
import { statusEnum } from 'app/__shared__/model/enums/statusLoading'

export interface ISelectBranches {
    status:statusEnum,
    data?:any,
} 

const SelectBanchesState: ISelectBranches = {
    status: statusEnum.idle,
    data:null
}


export const SelectBranchesSlice = createSlice({
  name: 'select-branches-slice',
  initialState: SelectBanchesState,
  reducers: {
      
  },
  
  extraReducers: (builder) => {
    builder.addCase(viewBranchesThunk.pending, (state:ISelectBranches, action: PayloadAction<any>) => {
        setStatusPending(state, action)
    })
    
    builder.addCase(viewBranchesThunk.rejected, (state:ISelectBranches, action:  PayloadAction<any>) => {
        setStatusRejected(state, action)
        state.data = null
    })
    
    builder.addCase(viewBranchesThunk.fulfilled, (state:ISelectBranches, action:  PayloadAction<any>) => {
        setStatusSucceeded(state, action)
        state.data = action.payload.data
    })

    
  },
})


export const SelectBranchActions = {
    ...SelectBranchesSlice.actions,
}


export const SelectBranchesData = (state:RootState) => state.BranchesReducer.SelectBranchesReducer

export const SelectBranchesReducer = SelectBranchesSlice.reducer