import { useContext, useEffect, useMemo, useState } from "react";

import { Modal } from "reactstrap";
import { ModalContext } from "../../context/modal/ModalContext";
import { modalEnum } from "app/__shared__/model/enums/modalEnum";

export const CustomModal = () => {
    const {modalComponent, toggleModalClose, modalTitle} = useContext(ModalContext);
    const modalStatus = useContext(ModalContext).modalStatus;

    return (
        <Modal isOpen={modalStatus === modalEnum.show}>
            <div className="flex justify-between p-5 gap-5 pb-0">
                <div className="text-2xl text-center w-full">{modalTitle}</div>
                <button className="text-2xl text-gray-400 hover:text-black transition-colors focus:outline-none" onClick={toggleModalClose}>×</button>  
            </div>
            <div  className="flex flex-col justify-between p-5">
                {modalComponent}
            </div>
        </Modal>
    )
}




export const AnimatedModal = ({children}:any) => {
    const modalStatus = useContext(ModalContext).modalStatus;
    const [isModalOpen , setIsModalOpen] = useState(false);
    const [modalAnimation , setModalAnimation] = useState(false);

    const close_modal_wrapper_animation_classes = "bg-transparent";
    const open_modal_wrapper_animation_classes  = "bg-opacity-50 bg-gray-900";
    
    
    
    useEffect(() => {
        if(modalStatus === modalEnum.show){
            setIsModalOpen(true);
            setTimeout(() => {
                setModalAnimation(true);
            }, 10);
        }
        if(modalStatus === modalEnum.closed){
            setModalAnimation(false);
            setTimeout(() => {
                setIsModalOpen(false);
            }, 300);
        }
    }, [modalStatus]);
    
    const memoModalWrapperClasses = useMemo(() => {
        if(isModalOpen){
            return " visible "
        }else{
            return " hidden "
        }
    }, [isModalOpen]);
    
    const memoModalWrapperAnimateClasses = useMemo(() => {
        if(modalAnimation){
            return open_modal_wrapper_animation_classes
        }else{
            return close_modal_wrapper_animation_classes
        }
    }, [modalAnimation]);
    
    const memoModalAnimateClasses = useMemo(() => {
        if(modalAnimation){
            return "-translate-y-0 opacity-1"
        }else{
            return "-translate-y-14 opacity-0"
        }
    }, [modalAnimation]);
    
   
    
    return (
        <div className={`overflow-auto  transition-all duration-300 z-50  w-full h-full p-0 m-0 fixed` + memoModalWrapperClasses + memoModalWrapperAnimateClasses}>
            <div className={"p-4 h-max overflow-auto transition-all delay-100 duration-200 w-max mx-auto mt-36 relative  flex flex-col rounded-xl  bg-white " + memoModalAnimateClasses}>
                {children}
            </div>
        </div>
    )
}

