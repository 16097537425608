import { faBan, faCalendar, faEdit, faEye, faTrash } from "@fortawesome/free-solid-svg-icons"
import { useAppDispatch, useAppSelector } from "app/__shared__/components/store/store";
import { useContext, useState } from "react";

import { APPEAL_PERMISSIONS } from "app/__shared__/config/permissions";
import { AppealsDeleteForm } from "./AppealsDeleteForm";
import { AppealsMeetForm } from "./AppealsMeetForm";
import { AppealsRejectFrom } from "./AppealsRejectForm";
import { AuthData } from "app/auth/Auth.slice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ModalContext } from "../__shared__/components/context/modal/ModalContext";
import { useNavigate } from "react-router-dom";

interface ITableButton {
    id: any,
    element?:any
}

export const AppealsCustomTableButton = ({id,element}:ITableButton) => {
    let navigate = useNavigate();
    const {user:{permissions}} = useAppSelector(AuthData);
    const dispatch = useAppDispatch()
    const [showButtons , setShowButtons] = useState<boolean>(false)
    const [buttonsHovered, setButtonsHovered] = useState<boolean>(false)
    const {toggleModalShow} = useContext(ModalContext)

    const goAppealView = () => {
        navigate(`/appeals/${id}`)
    }

    const goAppealViewAndCreateMeet = () => {
        navigate(`/appeals/${id}`)
        console.log(element)
        dispatch(toggleModalShow(<AppealsMeetForm appealsData={element}  id={id} /> , "Görüş təyin edin."))        
    }
    
    const directlyReject = () => {
        dispatch(toggleModalShow(<AppealsRejectFrom id={id}/> , ""))        
    }

    const openEditModal = () => {
        setShowButtons(false)
        navigate(`/appeals/${id}/edit`)
    }

    const openDeleteModal = () => {
        setShowButtons(false)
        toggleModalShow(<AppealsDeleteForm id={id}/>)
    }
    
    return(
        <div className="relative">
            <button className='btn  bg-[#1A67B4] rounded-md text-white w-5 h-5 justify-center items-center flex' 
                onBlur={() => setShowButtons(buttonsHovered)}
                onClick={() => setShowButtons(!showButtons)}
            >
                ···
            </button>
            {
                showButtons &&
                <div 
                    onMouseEnter={() => setButtonsHovered(true)}
                    onMouseLeave={() => setButtonsHovered(false)}
                    className="shadow-md absolute flex flex-col top-8 z-10 bg-white py-4 rounded-md w-48 right-0 mr-4">
                    {
                        permissions[APPEAL_PERMISSIONS.show] && 
                        <button
                            onClick={goAppealView}  
                            className="flex items-center p-1 gap-2 pl-4 hover:bg-[#1A67B4] hover:text-white transition-all duration-200">
                            <FontAwesomeIcon icon={faEye}/> 
                            <span>Bax</span>
                        </button>
                    }
                    {
                        (permissions[APPEAL_PERMISSIONS.show] && (element.status.id === 1 || element.status.id === 2) ) && 
                        <button
                            onClick={goAppealViewAndCreateMeet}  
                            className="flex items-center p-1 gap-2 pl-4 hover:bg-[#1A67B4] hover:text-white transition-all duration-200">
                            <FontAwesomeIcon icon={faCalendar}/> 
                            <span>{element.meeting_date ?  "Görüşü redaktə et" : "Görüş təyin et" }</span>
                        </button>
                    }
                    {
                        (permissions[APPEAL_PERMISSIONS.show] && (element.status.id === 1 ||  element.status.id === 2)  ) && 
                        <button
                            onClick={directlyReject}  
                            className="flex items-center p-1 gap-2 pl-4 hover:bg-[#1A67B4] hover:text-white transition-all duration-200">
                            <FontAwesomeIcon icon={faBan}/> 
                            <span>İmtina et</span>
                        </button>
                    }
                    {
                        permissions[APPEAL_PERMISSIONS.update] && 
                        <button onClick={openEditModal} className="flex items-center p-1 gap-2 pl-4 hover:bg-[#1A67B4] hover:text-white transition-all duration-200">
                            <FontAwesomeIcon icon={faEdit}/> 
                            <span>Redaktə et</span>    
                        </button>
                    }
                    {
                        permissions[APPEAL_PERMISSIONS.delete] && 
                        <button onClick={openDeleteModal} className="flex items-center p-1 gap-2 pl-4 hover:bg-[#1A67B4] hover:text-white transition-all duration-200">
                            <FontAwesomeIcon icon={faTrash}/> 
                            <span>Sil</span>           
                        </button>
                    }
                </div>
            }
        </div>
    )
}