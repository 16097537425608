import { Route, Routes } from 'react-router-dom';

import { Content } from 'app/__shared__/components/common/content/Content';
import { DashboardProps } from './Dashboard.types';
import React from 'react';
import { Sidebar } from 'app/__shared__/components/layout/sidebar/Sidebar';

export const Dashboard: React.FC<DashboardProps> = () => {

    return (
        <div className='wrapper'>
            <Sidebar/>
            
            <Routes>
                <Route path="/*"  element={<Content/>} /> 
            </Routes>
        </div>
    );
};
