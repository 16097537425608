import { TableRowLoader } from "../table-loader/TableDataLoader"
import { statusEnum } from "app/__tests__/declerations/enums/statusLoading"

interface ITableLoaderProps {
    status:statusEnum,
    children:any,
}

export const TableLoader = ({status, children}:ITableLoaderProps) => {
    
    if(status === statusEnum.loading)
    {
        return <TableRowLoader count={15}/>
    }
    if(status === statusEnum.failed)
    {
        return <div>Məlumat tapılmadı, yenidən cəhd edin.</div>
    }
    if(status === statusEnum.succeeded)
    {
        return <>
            {children}
        </>
    }else{
        return <></>
    }
}