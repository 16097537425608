import { statusEnum } from "app/__shared__/model/enums/statusLoading";
import { useAppDispatch } from "app/__shared__/components/store/store";
import { FormEvent, useState } from "react";
import { emailRegex, passwordRegex } from "app/__shared__/data/regexs";
import { loginThunk } from "app/auth/Auth.thunk";

export const useLoginState = () => {
    const dispatch = useAppDispatch()
    const [isPasswordVisible, setPasswordVisible] = useState(false);
    const [isFormSubmit, setFormSubmit] = useState(false);
    const [isAllValidStatus, setIsAllValidStatus] = useState<statusEnum>(statusEnum.idle);
    const [emailValue, setEmailValue] = useState('');
    const [passwordValue, setPasswordValue] = useState('');
    
    
    
    const handleLoginSubmit = (e:FormEvent) => {
        e.preventDefault();


        if(!emailRegex.test(emailValue) && !passwordRegex.test(passwordValue)){
            setIsAllValidStatus(statusEnum.failed)
            return 
        }else{
            setIsAllValidStatus(statusEnum.succeeded)
        }
       
        dispatch(loginThunk({
            email: emailValue,
            password: passwordValue
        }))
    }

    return {isPasswordVisible,setPasswordVisible,isFormSubmit,setFormSubmit,emailValue,setEmailValue,passwordValue,setPasswordValue,emailRegex,passwordRegex, isAllValidStatus, setIsAllValidStatus, handleLoginSubmit};
}