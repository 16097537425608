import { AXIOS_CONFIG } from "../config"
import { axiosInstance } from "../interceptor"
// import { handleCatchStatusError } from "app/helpers/utils/error/handleCatchStatusError"

export const listRolesService = async ({rejectWithValue}:any) => {
    try {
        const response = await axiosInstance({...AXIOS_CONFIG.ROLE.LIST_ROLE})
        return response.data
    } catch (error:any) {
        return rejectWithValue(error.response.data)
    }
}


export const viewRolesService = async (data:any , {rejectWithValue}:any) => {
    try {
        const response = await axiosInstance(`role/${data.id}`, AXIOS_CONFIG.ROLE.SHOW_ROLE)
        return response.data
    } catch (error:any) {
        return rejectWithValue(error.response.data)
    }
}

export const createRolesService = async (data:any, {rejectWithValue}:any) => {
    try {
        const config:any = AXIOS_CONFIG.ROLE.CREATE_ROLE
        config.data = data.data
        const response = await axiosInstance(config)
        return response.data
    } catch (error:any) {
        return rejectWithValue(error.response.data)
    }
}

export const editRolesService = async (data:any, {rejectWithValue}:any) => {
    try {
        const response = await axiosInstance(`role/${data.id}` , {...AXIOS_CONFIG.ROLE.UPDATE_ROLE, data:data.data})
        return response.data
    } catch (error:any) {
        return rejectWithValue(error.response.data)
    }
}

export const deleteRolesService = async (data:any, {rejectWithValue}:any) => {
    try {
        const response = await axiosInstance(`/role/${data.id}`, AXIOS_CONFIG.ROLE.DELETE_ROLE)
        return response.data
    } catch (error:any) {
        return rejectWithValue(error.response.data)
    }
}


