import { Route, Routes } from "react-router-dom"
import { NormativesList } from "./NormativesList"
import { NormativesView } from "./NormativesView"


export const NormativesRoute = () => {
    return (
        <Routes>
            <Route path=":id" element={<NormativesView/>} />
            <Route path="/" element={<NormativesList/>} />
        </Routes>
    )
}