import { listAppealsThunk, rejectAppealsThunk, viewAppealsThunk } from "./slices/Appeals.thunk"
import { useAppDispatch, useAppSelector } from "app/__shared__/components/store/store"
import { useContext, useEffect } from "react"

import { ModalContext } from "app/__shared__/components/context/modal/ModalContext"
import { listNormativesThunk } from "app/normatives/slices/Normatives.thunk"
import { statusEnum } from "app/__shared__/model/enums/statusLoading"
import toast from "react-hot-toast"

interface IAppealRejectFormProps {
    id:any
}

export const AppealsRejectFrom = ({id}:IAppealRejectFormProps) => {
    const listAppealsData = useAppSelector(state=> state.AppealsReducer.ListAppealsReducer)
    const listNormatives = useAppSelector(state=> state.NormativesReducer.ListNormativesReducer)
    const {toggleModalClose} = useContext(ModalContext)
    const dispatch = useAppDispatch()
    
    useEffect(() => {
        if(listNormatives.status === statusEnum.idle) {
            dispatch(listNormativesThunk({}))
        }
    }, [listNormatives])

    const rejectUser = async () => {
        toggleModalClose()
        const normativesWithZero = listNormatives.response.data.map((item:any) => {
            return {    
                id:item.id,
                starCount:0,
                name:item.name,
            }
        })

        await dispatch(rejectAppealsThunk({id:id, normatives:normativesWithZero}))
        toast.error('Namizəd imtina edildi!',);
        dispatch(listAppealsThunk({
            params:{
                ...listAppealsData.pageParams
            }
        }))
        dispatch(viewAppealsThunk({
            id:id
        }))
    }

    return (
        <div className="flex flex-col ">
            <div className="row pt-2">
                <div className="col-md-12 text-center mb-4">
                    <h5 className="text-lg">Namizədi imtina etmək istədiyinizdən əminsinizmi ? </h5>
                </div>
            </div>

            <div className="justify-between gap-4 flex ">
                <button color="light" className="btn btn-primary w-full" onClick={toggleModalClose}>Geri</button>
                <button color="danger" className="btn btn-danger font-weight-bold w-full" onClick={rejectUser}>Bəli</button>
            </div>
        </div>
    )
}