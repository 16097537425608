import { Link, useParams } from 'react-router-dom';
import { faList, faLocationArrow } from '@fortawesome/free-solid-svg-icons';
import { useAppDispatch, useAppSelector } from 'app/__shared__/components/store/store';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormLoader } from 'app/__shared__/components/common/form-loader/FormLoader';
import { MapObject } from 'app/__shared__/components/common/map/MapObject';
import { statusEnum } from 'app/__shared__/model/enums/statusLoading';
import { useEffect } from 'react';
import { viewBranchesThunk } from '../slices/Branches.thunk';

export const BranchesView = () => {
    const dispatch = useAppDispatch()
    const selectBranchesData = useAppSelector(state => state.BranchesReducer.SelectBranchesReducer)
    
    
    let { id } = useParams();

    useEffect(() => {
        dispatch(viewBranchesThunk({id}))
    }, [])
    

    return (
        <div className="bg-white rounded p-4">
            <div className="row mb-4">
                <div className="col-md-12">
                    <div className="page-header">
                        <h1 className="font-weight-bold">Baxış</h1>
                    </div>
                </div>
            </div>
            
            {selectBranchesData.status === statusEnum.loading && <FormLoader/>}
            <form>
                {
                    selectBranchesData.status === statusEnum.succeeded  &&
                    <>
                        <div className="row mb-4 pt-2">
                            <div className="col-md-6 mb-2">
                                <label className="text-muted">ID</label>
                                <input
                                    name="id"
                                    placeholder="id"
                                    disabled
                                    defaultValue={selectBranchesData.data.id}
                                    className="form-control"
                                />
                            </div>
                            <div className="col-md-6 mb-2">
                                <label>Məkan Adı</label>
                                <input
                                    name="name"
                                    placeholder="Ad "
                                    disabled
                                    className="form-control"
                                    defaultValue={selectBranchesData.data.name}
                                />
                            </div>

                            <div className="col-md-6 mt-4">
                                <label>Məsul şəxs</label>
                                <input
                                    name="name"
                                    placeholder="Ad "
                                    disabled
                                    className="form-control"
                                    defaultValue={selectBranchesData.data.chief}
                                />
                            </div>
                            
                            <div className="col-md-6 mt-4">
                                <label>Elektron Poçt</label>
                                <input
                                    name="name"
                                    placeholder="Ad "
                                    disabled
                                    className="form-control"
                                    defaultValue={selectBranchesData.data.chief_email}
                                />
                            </div>

                            <div className="col-md-12 mt-5">
                                <label>Məkanın detallı adresi</label>
                                <div className='form-control bg-gray-200'>
                                    <FontAwesomeIcon icon={faLocationArrow}/> {selectBranchesData.data.address}
                                </div>
                            </div>

                            <div className="col-md-12 mt-5">
                                <label>Məkanın xəritə üzərindən görünüşü</label>
                                <MapObject lat={selectBranchesData.data.lat} long={selectBranchesData.data.long}/>
                            </div>
                            

                            <div className='col-md-12 mt-5'>
                                <h1>Filiala bağlı istifadəçilər</h1>
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                        <th scope="col">ID</th>
                                        <th scope="col">Adı</th>
                                        <th scope="col">Soyadı</th>
                                        <th scope="col">Elektron Poçtu</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            selectBranchesData.data.users.map((user:any, index:any) => {
                                                return (
                                                    <tr key={index}>
                                                        <th scope="row">{user.id}</th>
                                                        <td>{user.name}</td>
                                                        <td>{user.surname}</td>
                                                        <td>{user.email}</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            
                            </div>

                            
                        </div>
                        <div className="row">
                            <div className="col-md-12 text-center">
                                <Link
                                    to="/branches"
                                    className='btn btn-light'
                                >
                                    <FontAwesomeIcon icon={faList} className="mr-2" />
                                    Listə geri qayıt
                                </Link>
                            </div>
                        </div>
                    </>
                }

                
                {
                    selectBranchesData.status === statusEnum.failed &&
                    <div className="row">
                        <div className="col-md-12">
                            <div className="alert alert-warning">
                                Məlumat tapılmadı
                            </div>
                        </div>
                    </div>
                }
            </form>
        </div>
    )
}
 