import React, { useCallback, useContext, useEffect, useState } from "react"
import { createBranchesThunk, listBranchesThunk } from './slices/Branches.thunk';
import { faExclamationCircle, faSpinner } from "@fortawesome/free-solid-svg-icons"
import { useAppDispatch, useAppSelector } from 'app/__shared__/components/store/store';

import { BAKU_CORDINATES } from 'app/__shared__/data/contants';
import { CreateBranchesActions } from './slices/BranchesCreate.slice';
import { CustomInput } from 'app/__shared__/components/common/input/CustomInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MapObjectSelectLocation } from 'app/__shared__/components/common/map/MapObjectSelectLocation';
import { ModalContext } from 'app/__shared__/components/context/modal/ModalContext';
import Select from 'react-select';
import { statusEnum } from "app/__shared__/model/enums/statusLoading"
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

export const BranchesCreateForm = () => {
    const createBranchesData = useAppSelector(state => state.BranchesReducer.CreateBranchesReducer)
    const listBranches = useAppSelector(state => state.BranchesReducer.ListBranchesReducer)
    const navigate = useNavigate()

    const {toggleModalClose} = useContext(ModalContext)
    const dispatch = useAppDispatch()
    const [formData, setFormData] = useState<
    {
        name: string,
        address: string,
        chief_email: string,
        chief: string,
        position:{
            lat:number,
            lng:number
        },
        status:any
        isAllValidStatus:statusEnum
    }
    >({
        name: "",
        address: "",
        chief:"",
        chief_email: "",
        position:{
            lat:BAKU_CORDINATES.lat,
            lng:BAKU_CORDINATES.lng
        },
        status:{id:1, label:"Aktiv"},
        isAllValidStatus:statusEnum.idle
    })
    
    const {
        name,
        address,
        chief,
        chief_email,
        status,
        isAllValidStatus
    } = formData
    
    const [position, setPosition] = useState({lat:BAKU_CORDINATES.lat,lng:BAKU_CORDINATES.lng})

    useEffect(() => {
      setFormData({
        ...formData,
        position:position
      })
    }, [position, formData])
    
    useEffect(() => {
        if(createBranchesData.submit_status === statusEnum.succeeded){
            dispatch(listBranchesThunk({
                ...listBranches.pagination
            }))
            toggleModalClose()   
            toast.success('Filial bazaya əlavə edildi!')
            dispatch(CreateBranchesActions.resetBranchesCreate())
            // navigate("/branches")
        }
    }, [createBranchesData.submit_status, dispatch, toggleModalClose])

    const handleSubmit = (e:React.FormEvent) => {
        e.preventDefault()

        if(
            !formData.name ||
            !formData.address ||
            !formData.chief ||
            !formData.chief_email
        )   
        {
            setFormData({
                ...formData,
                isAllValidStatus:statusEnum.failed
            })
            return 
        }else{
            setFormData({
                ...formData,
                isAllValidStatus:statusEnum.succeeded
            })
        }
        
        dispatch(createBranchesThunk(
            {
                data:{
                    name: formData.name,
                    address: formData.address,
                    chief: formData.chief,
                    chief_email: formData.chief_email,
                    status: formData.status.id,
                    lat: formData.position.lat,
                    long: formData.position.lng,
                }
            }
        ))

        
        
    }
    
    return (
        <div className='mb-5'>
            <form   onSubmit={handleSubmit}>
                <div className="row pt-2">
                    
                    <div className="col-md-6">
                        <label >Filial Adı</label>
                        <CustomInput
                            required={true}
                            isFormSubmitStatus={isAllValidStatus}
                            emptyError={'Filial adını daxil edin.'}
                            value={name}
                            type='text'
                            placeHolder='Filial adını daxil edin.'
                            onChange={(data) => setFormData({...formData, name: data})}
                        />
                    </div>
                    
                    <div className="col-md-6">
                        <label>Status</label>
                        <Select
                            placeholder="Status"
                            value={status}
                            options={[{id:1, label:"Aktiv"}, {id:0, label:"Deaktiv"}]}
                            getOptionValue={option => option.id}
                            getOptionLabel={option => option.label}
                            onChange={(e) => {
                                setFormData({...formData, status: e})
                            }}
                        />
                    </div>
                    
                    <div className="col-md-6 mt-4">
                        <label>Məsul şəxs</label>
                        <CustomInput
                            required={true}
                            isFormSubmitStatus={isAllValidStatus}
                            emptyError={'Məsul şəxsin adını daxil edin.'}
                            value={chief}
                            type='text'
                            placeHolder='Məsul şəxsin adı'
                            onChange={(data) => setFormData({...formData, chief: data})}
                        />
                    </div>

                    <div className="col-md-6 mt-4">
                        <label>Elektron Poçt</label>
                        <CustomInput
                            required={true}
                            isFormSubmitStatus={isAllValidStatus}
                            emptyError={'Elektron poçtu daxil edin.'}
                            value={chief_email}
                            type='email'
                            placeHolder='Elektron poçt'
                            onChange={
                                (data) => setFormData({...formData, chief_email: data})
                            }
                        />
                    </div>

                    <div className="col-md-12 mt-5">
                        <label>Filialın detallı adresi</label>
                        <CustomInput
                            required={true}
                            isFormSubmitStatus={isAllValidStatus}
                            emptyError={'Məkanın detallı adresini daxil edin.'}
                            value={address}
                            type='text'
                            placeHolder='Məkanın detallı adresini daxil edin.'
                            onChange={
                                (data) => setFormData({...formData, address: data})
                            }
                        />
                    </div>

                    <div className="col-md-12 mt-5">
                        <label>Filialı xəritə üzərindəki uyğun məkanını seçmək üçün ikona klikləyərək sürükləyin.</label>
                        <MapObjectSelectLocation position={position} setPosition={setPosition}/>
                    </div>                    
                </div>
                
                <div className="row mt-5">
                    <div className="col-md-12 text-md-right">
                        <button
                            type="button"
                            onClick={() => navigate('/branches')}
                            className="btn btn-primary font-weight-bold ml-2 bg-gray-500 text-white"
                        >
                            Ləğv et
                        </button>
                        <button
                            className="btn btn-success font-weight-bold ml-2 bg-green-500 text-white hover:text-white"
                        >
                            Təsdiq et
                        </button>
                    </div>
                </div>

                {
                    createBranchesData.submit_status === statusEnum.loading &&
                    <div className="flex w-full justify-center mt-2 items-center">
                        <div className="flex gap-4  items-center mt-4">
                            <FontAwesomeIcon icon={faSpinner} className='animate-spin'/>
                            <span>Yüklənir</span>
                        </div>
                    </div>
                }

                {
                    createBranchesData.submit_status === statusEnum.failed &&
                    <div className="flex flex-col w-full justify-center mt-2 items-center">
                        {Object.keys(createBranchesData.submit_errors).map((key, index) => (
                            <div key={index} className="w-full flex gap-4  items-center mt-4">
                                <FontAwesomeIcon icon={faExclamationCircle} className='text-red-500'/>
                                {
                                    createBranchesData.submit_errors[key].map((error:any, index:any) => (
                                        <span key={index}>{error}</span>
                                    ))
                                }
                            </div>
                        ))}
                    </div>
                }
            </form>
        </div>
    )
}