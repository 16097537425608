import React, { useContext, useEffect, useState } from "react"
import { editUsersThunk, listUsersThunk, viewUsersThunk } from "./slices/Users.thunk";
import { faExclamationCircle, faSpinner } from "@fortawesome/free-solid-svg-icons"
import { listAllBranchesThunk, listBranchesThunk } from "app/branches/slices/Branches.thunk";
import { useAppDispatch, useAppSelector } from "app/__shared__/components/store/store";

import { CustomInput } from "app/__shared__/components/common/input/CustomInput";
import { EditFormLoader } from "app/__shared__/components/common/form-loader/EditFormLoader";
import { EditUsersActions } from "./slices/UsersEdit.slice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ModalContext } from "app/__shared__/components/context/modal/ModalContext";
import Select from 'react-select';
import Swl from 'sweetalert2';
import { hasEnum } from "app/__tests__/declerations/enums/selectEnums";
import { listRolesThunk } from "app/roles/slices/Roles.thunk";
import { statusEnum } from "app/__shared__/model/enums/statusLoading";
import toast from "react-hot-toast";

interface IUserEditForm {
    id:number
}

export const UserEditForm = ({id}:IUserEditForm) => {
    const listUsers = useAppSelector(state => state.UsersReducer.ListUsersReducer)
    const editUserData = useAppSelector(state => state.UsersReducer.EditUsersReducer)
    const selectUsers = useAppSelector(state => state.UsersReducer.SelectUsersReducer)
    const {toggleModalClose} = useContext(ModalContext)
    const dispatch = useAppDispatch()
    const rolesListData = useAppSelector(state => state.RolesReducer.ListRolesReducer)
    const listAllBranches = useAppSelector(state => state.BranchesReducer.ListBranchesReducer)
    
    const [formData, setFormData] = useState<any>({
        name: '',
        surname: '',
        email: '',
        password: '',
        status: hasEnum,
        selectedRoles:[],
        filteredSelectedRoles:[],
        selectedBranches:[],
        isAllValidStatus:statusEnum.idle
    })
    
    const {name,email,surname,password,status,selectedRoles,selectedBranches,isAllValidStatus,filteredSelectedRoles} = formData
    
    useEffect(() => {
        dispatch(listRolesThunk({}))
        dispatch(viewUsersThunk({id}))
        dispatch(listAllBranchesThunk({}))
    }, [])

    useEffect(() => {
        if(selectUsers.status === statusEnum.succeeded  && selectUsers.response.data){
            const roles = Object.keys(selectUsers.response.data.role).map((key:any) => 
                {
                    return {
                        id:key,
                        name:selectUsers.response.data.role[key]
                    }   
                }
            )
            setFormData({
                ...formData,
                name: selectUsers.response.data.name,
                surname: selectUsers.response.data.surname,
                email: selectUsers.response.data.email,
                status: selectUsers.response.data.status,
                selectedRoles: roles,
                selectedBranches: selectUsers.response.data.branches,
            })
        }
    }, [ dispatch, selectUsers])

    useEffect(() => {
        if(editUserData.status === statusEnum.succeeded){
            dispatch(listUsersThunk({...listUsers.pagination}))
            toggleModalClose()   
            toast.success('İstadəçi məlumatları yeniləndi!',);
            dispatch(EditUsersActions.resetUsersEdit())
        }
    }, [editUserData.status , dispatch, toggleModalClose])


    useEffect(() => {
        if(rolesListData.status === statusEnum.succeeded){
            const roles = rolesListData.response.data.map((role:any) => {
                return {
                    id:`${role.id}`,
                    name:role.name
                }
            })
            setFormData({
                ...formData,
                filteredSelectedRoles:roles
            })
        }
    }, [rolesListData.status])

    const handleSubmit = (e:React.FormEvent) => {
        if(!selectUsers.response.data){
            return
        }
        e.preventDefault()
        if(!email &&  !name && !surname){
            setFormData({
                ...formData,
                email: formData.email,
            })
            return 
        }else{
            setFormData({
                ...formData,
                isAllValidStatus:statusEnum.succeeded
            })
        }
        const selectedRolesIds = selectedRoles.map((role:any) => role.id)
        const selectedBranchesIds = selectedBranches.map((branch:any) => branch.id)

        const submit_data:any = {
            id: selectUsers.response.data.id,
            data:{
                name,
                surname,
                email,
                role:[...selectedRolesIds],
                branches:[...selectedBranchesIds],
                status:status,
            }
        }
        if(password){
            submit_data.data.password = password   
        }
        dispatch(editUsersThunk(
            submit_data
        ))
    }
    
    

    
    return (
        <div>
            {
                (selectUsers.status === statusEnum.succeeded) &&
                <form  className="max-w-lg" onSubmit={handleSubmit}>
                    <div className="row pt-2">
                        <div className="col-md-12 mb-4">
                            <label className="text-muted">ID</label>
                            <input
                                className="form-control"
                                disabled
                                name="id"
                                value={selectUsers.response.data.id}
                            />
                        </div>
                        <div className="col-md-12 mb-4">
                            <label className="font-weight-bold">Rol</label>
                            <Select
                                isLoading={rolesListData.status === statusEnum.loading}
                                placeholder="Rol növünü seçin"
                                value={selectedRoles}
                                options={filteredSelectedRoles}
                                getOptionValue={(option) => option.id}
                                getOptionLabel={option => option.name}
                                onChange={(e:any) => {
                                    setFormData({
                                        ...formData,
                                        selectedRoles: e
                                    })
                                }}
                                isMulti
                            />
                        </div>
                        <div className="col-md-12 mb-4">
                            <label className="font-weight-bold">Aid olduğu filiallar</label>
                            <Select
                                isLoading={listAllBranches.list_all.status === statusEnum.loading}
                                placeholder="Fiallialı seçin"
                                value={selectedBranches}
                                options={listAllBranches.list_all.data}
                                getOptionValue={(option:any) => option.id}
                                getOptionLabel={option => option.name}
                                onChange={(e:any) => {
                                    setFormData({
                                        ...formData,
                                        selectedBranches: e
                                    })
                                }}
                                isMulti
                            />
                        </div>
                        <div className="col-md-6 mb-4">
                            <label  className="font-weight-bold">Ad</label>
                            <CustomInput
                                required={true}
                                isFormSubmitStatus={isAllValidStatus}
                                emptyError={'Adı daxil edin.'}
                                value={name}
                                type='text'
                                placeHolder='Adı daxil edin.'
                                onChange={
                                    (data) => setFormData({
                                        ...formData,
                                        name: data
                                    })
                                }
                            />
                        </div>
                        <div className="col-md-6 mb-4">
                            <label  className="font-weight-bold">Soyad</label>
                            <CustomInput
                                required={true}
                                isFormSubmitStatus={isAllValidStatus}
                                emptyError={'Soyadı daxil edin.'}
                                value={surname}
                                type='text'
                                placeHolder='Soyadı daxil edin.'
                                onChange={
                                    (data) => setFormData({
                                        ...formData,
                                        surname: data
                                    })
                                }
                            />
                        </div>
                        <div className="col-md-6 mb-4">
                            <label  className="font-weight-bold">Elektron poçt</label>
                            <CustomInput
                                required={true}
                                isFormSubmitStatus={isAllValidStatus}
                                emptyError={'Elektron poçtu daxil edin.'}
                                invalidError={'Elektron poçtu düzgün daxil edin.'}
                                value={email}
                                type='email'
                                placeHolder='Elektron poçtu daxil edin'
                                onChange={
                                    (data) => setFormData({
                                        ...formData,
                                        email: data
                                    })
                                }
                                // regex={emailRegex}
                            />
                        </div>
                        
                        <div className="col-md-6 mb-4">
                            <label  className="font-weight-bold">Şifrə</label>
                            <input
                                // required={true}
                                // isFormSubmitStatus={isAllValidStatus}
                                // emptyError={'Şifrəni daxil edin.'}
                                // invalidError={'Şifr düzgün daxil edin.'}
                                className="form-control"
                                value={password}
                                type='password'
                                placeholder='Şifrə'
                                onChange={
                                    (e) => setFormData({
                                        ...formData,
                                        password: e.target.value
                                    })
                                }
                                // regex={emailRegex}
                            />
                            <small>*Boş qoyulduğu halda şifrə dəyişilmir.</small>
                        </div>

                        <div className="col-md-6 mb-4 flex gap-2 items-center">
                            <label  className="mb-0 flex items-center font-weight-bold">Status Aktivdirmi ? </label>
                            <input type="checkbox" checked={status} value={status} onChange={()=> setFormData({
                                ...formData,
                                status: !status
                            })}/>
                        </div>

                        
                    </div>
                    <div className="row">
                        <div className="col-md-12 text-md-right">
                            <button
                                type="button"
                                color="default"
                                onClick={toggleModalClose}
                                className="btn btn-primary font-weight-bold ml-2 bg-gray-500 text-white"
                            >
                                Ləğv et
                            </button>
                            <button
                                type="submit"
                                color="success"
                                className="btn btn-success font-weight-bold ml-2 bg-green-500 text-white hover:text-white"
                            >
                                Təsdiq et
                            </button>
                        </div>
                    </div>
                    {
                        editUserData.status === statusEnum.loading &&
                        <div className="flex w-full justify-center mt-2 items-center">
                            <div className="flex gap-4  items-center mt-4">
                                <FontAwesomeIcon icon={faSpinner} className='animate-spin'/>
                                <span>Yüklənir</span>
                            </div>
                        </div>
                    }

                    {
                        editUserData.status === statusEnum.failed &&
                        <div className="flex flex-col w-full justify-center mt-2 items-center">
                            {Object.keys(editUserData.response.errors).map((key, index) => (
                                <div key={index} className="w-full flex gap-4  items-center mt-4">
                                    <FontAwesomeIcon icon={faExclamationCircle} className='text-red-500'/>
                                    {
                                        editUserData.response.errors[key].map((error:any, index:any) => (
                                            <span key={index}>{error}</span>
                                        ))
                                    }
                                </div>
                            ))}
                        </div>
                    }
                </form>
            }

            {
                selectUsers.status === statusEnum.failed &&
                <div className="row">
                    <div className="col-md-12">
                        <div className="alert alert-warning">
                            Məlumat tapılmadı
                        </div>
                    </div>
                </div>
            }

            {
                selectUsers.status === statusEnum.loading &&
                <EditFormLoader/>
            }
        </div>
    )
}