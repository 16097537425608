import React, { useContext, useEffect, useState } from "react"
import { createUsersThunk, listUsersThunk } from "./slices/Users.thunk";
import { faExclamationCircle, faSpinner } from "@fortawesome/free-solid-svg-icons"
import { listAllBranchesThunk, listBranchesThunk } from "app/branches/slices/Branches.thunk";
import { useAppDispatch, useAppSelector } from "app/__shared__/components/store/store";

import { CreateUsersActions } from "./slices/UsersCreate.slice";
import { CustomInput } from "app/__shared__/components/common/input/CustomInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ModalContext } from "app/__shared__/components/context/modal/ModalContext";
import Select from 'react-select';
import Swl from 'sweetalert2';
import { emailRegex } from "app/__shared__/data/regexs";
import { genderEnum } from 'app/__shared__/model/enums/selectEnums';
import { listRolesThunk } from "app/roles/slices/Roles.thunk";
import { statusEnum } from "app/__shared__/model/enums/statusLoading"
import toast from "react-hot-toast";

export const UsersCreateForm = () => {
    const createUserData = useAppSelector(state => state.UsersReducer.CreateUsersReducer)
    const listRolesData = useAppSelector(state => state.RolesReducer.ListRolesReducer)
    const listAllBranches = useAppSelector(state => state.BranchesReducer.ListBranchesReducer)
    const {toggleModalClose} = useContext(ModalContext)
    const dispatch = useAppDispatch()
    const listUsers = useAppSelector(state => state.UsersReducer.ListUsersReducer)

    const [formData, setFormData] = useState<{
        name:string,
        surname:string,
        email:string,
        status:genderEnum,
        password:string,
        selectedRoles:any[],
        selectedBranches:any[],
        isAllValidStatus:statusEnum
    }>({
        name: "",
        surname: "",
        status:genderEnum.idle,
        email: "",
        password: "",
        selectedRoles: [],
        selectedBranches: [],
        isAllValidStatus:statusEnum.idle
    })
    
    const {
        name,surname,status,email,selectedRoles,selectedBranches,isAllValidStatus,password
    } = formData

    useEffect(() => {
        if(listAllBranches.list_all.status === statusEnum.idle){
            dispatch(listRolesThunk({}))
            dispatch(listAllBranchesThunk({}))
        }
    }, [listAllBranches.list_all.status , listRolesData.status, dispatch])

    

    useEffect(() => {
        if(createUserData.status === statusEnum.succeeded){
            dispatch(listUsersThunk({...listUsers.pagination}))
            toggleModalClose()   
            toast.success('İstadəçi bazaya əlavə edildi!',);
            dispatch(CreateUsersActions.resetUsersCreate())
        }
    }, [createUserData, dispatch, toggleModalClose])


   

    const handleSubmit = (e:React.FormEvent) => {
        e.preventDefault()
        if(!email || !name || !surname || !selectedRoles.length || !selectedBranches.length){
            setFormData({
                ...formData,
                isAllValidStatus:statusEnum.failed
            })
            return 
        }else{
            setFormData({
                ...formData,
                isAllValidStatus:statusEnum.succeeded
            })
        }

       
        const selectedBranchesIds = selectedBranches.map((item:any) => {
            return item.id
        })
        const selectedRolesIds = selectedRoles.map((item:any) => {
            return item.id
        })
        console.log(selectedBranchesIds)
        dispatch(createUsersThunk(
            {
                data:{
                    name,
                    surname,
                    email,
                    password,
                    role:[...selectedRolesIds],
                    branches:[...selectedBranchesIds],
                    status:status,
                }
            }
        ))

        
        
    }
    return (
        <div>
            {
                <form  className="max-w-lg" onSubmit={handleSubmit}>
                    <div className="row pt-2">
                        <div className="col-md-12 mb-4">
                            <label className="font-weight-bold">Rol</label>
                            <Select
                                placeholder="İstifadəçinin aid olduğu rolları seçin"
                                isLoading={listRolesData.status === statusEnum.loading}
                                options={listRolesData.response.data}
                                closeMenuOnSelect={false}
                                getOptionValue={(option) => option.id}
                                getOptionLabel={(option) => option.name}
                                isMulti
                                value={selectedRoles}
                                onChange={(e:any) => {
                                    setFormData({
                                        ...formData,
                                        selectedRoles: e
                                    });
                                }}
                                loadingMessage={() => "Yüklənir..."}
                                className="w-full"
                            />
                            {(isAllValidStatus === statusEnum.failed && !selectedRoles.length) && <span className="text-danger text-sm invalid">Rolları seçin</span>}
                        </div>

                        <div className="col-md-12 mb-4">
                            <label className="font-weight-bold">Aid olduğu filialı seçin</label>
                            <Select
                                closeMenuOnSelect={false}
                                placeholder="Aid olduğu filialı seçin"
                                options={listAllBranches.list_all.data}
                                isLoading={listAllBranches.list_all.status === statusEnum.loading}
                                getOptionValue={(option:any) => option.id}
                                getOptionLabel={(option:any) => option.name}
                                isMulti={true}
                                value={selectedBranches}
                                onChange={(e:any) => {
                                    setFormData({
                                        ...formData,
                                        selectedBranches: e
                                    });
                                }}
                                className="w-full"
                                loadingMessage={() => "Yüklənir..."}
                            />
                            {(isAllValidStatus === statusEnum.failed && !selectedBranches.length) && <span className="text-danger text-sm invalid">Filial seçin</span>}
                        </div>

                        <div className="col-md-6 mb-4">
                            <label className="font-weight-bold">Ad</label>
                            <CustomInput
                                required={true}
                                isFormSubmitStatus={isAllValidStatus}
                                emptyError={'Adı daxil edin.'}
                                value={name}
                                type='text'
                                placeHolder='Adı daxil edin.'
                                onChange={(data) => setFormData({
                                    ...formData,
                                    name: data
                                })}
                            />
                        </div>

                        
                        <div className="col-md-6 mb-4">
                            <label  className="font-weight-bold">Soyad</label>
                            <CustomInput
                                required={true}
                                isFormSubmitStatus={isAllValidStatus}
                                emptyError={'Soyadı daxil edin.'}
                                value={surname}
                                type='text'
                                placeHolder='Soyadı daxil edin.'
                                onChange={(data) => setFormData({
                                    ...formData,
                                    surname: data
                                })}
                            />
                        </div>
                        
                        <div className="col-md-6 mb-4">
                            <label  className="font-weight-bold">Elektron poçt</label>
                            <CustomInput
                                required={true}
                                isFormSubmitStatus={isAllValidStatus}
                                emptyError={'Elektron poçtu daxil edin.'}
                                invalidError={'Elektron poçtu düzgün daxil edin.'}
                                value={email}
                                type='email'
                                placeHolder='Elektron poçtu daxil edin'
                                regex={emailRegex}
                                onChange={(data) => setFormData({
                                    ...formData,
                                    email: data
                                })}
                            />
                        </div>

                        <div className="col-md-6 mb-4">
                            <label  className="font-weight-bold">Şifrə</label>
                            <CustomInput
                                required={true}
                                isFormSubmitStatus={isAllValidStatus}
                                emptyError={'Şifrəni daxil edin.'}
                                value={password}
                                type='password'
                                placeHolder='Şifrəni daxil edin'
                                onChange={(data) => setFormData({
                                    ...formData,
                                    password: data
                                })}
                            />
                        </div>
                        

                        <div className="col-md-12 mb-4 flex gap-2 items-center">
                            <label className="mb-0 flex items-center font-weight-bold">Status Aktivdirmi ? </label>
                            <input type="checkbox" checked={status} value={status} onChange={()=> setFormData({
                                ...formData,
                                status: !status
                            })}/>
                        </div>

                        
                    </div>
                    <div className="row">
                        <div className="col-md-12 text-md-right">
                            <button
                                type="button"
                                onClick={toggleModalClose}
                                className="btn btn-primary font-weight-bold ml-2 bg-gray-500 text-white"
                            >
                                Ləğv et
                            </button>
                            <button
                                type="submit"
                                className="btn btn-success font-weight-bold ml-2 bg-green-500 text-white hover:text-white"
                            >
                                Təsdiq et
                            </button>
                        </div>
                    </div>
                    {
                        createUserData.status === statusEnum.loading &&
                        <div className="flex w-full justify-center mt-2 items-center">
                            <div className="flex gap-4  items-center mt-4">
                                <FontAwesomeIcon icon={faSpinner} className='animate-spin'/>
                                <span>Yüklənir</span>
                            </div>
                        </div>
                    }

                    {
                        createUserData.status === statusEnum.failed &&
                        <div className="flex  flex-col w-full justify-center mt-2 items-center">
                            {Object.keys(createUserData.response.errors).map((key, index) => (
                                <div key={index} className="w-full flex gap-4  items-center mt-4">
                                    <FontAwesomeIcon icon={faExclamationCircle} className='text-red-500'/>
                                    {
                                        createUserData.response.errors[key].map((error:any, index:any) => (
                                            <span key={index}>{error}</span>
                                        ))
                                    }
                                </div>
                            ))}
                        </div>
                    }
                </form>
            }

        </div>
    )
}