import React, { useContext, useEffect, useState } from "react"
import { createRolesThunk, listRolesThunk } from "./slices/Roles.thunk";
import { faExclamationCircle, faSpinner } from "@fortawesome/free-solid-svg-icons"
import { useAppDispatch, useAppSelector } from "app/__shared__/components/store/store";

import { CreateRolesActions } from "./slices/RolesCreate.slice";
import { CustomInput } from "app/__shared__/components/common/input/CustomInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ModalContext } from "app/__shared__/components/context/modal/ModalContext";
import { MultiSelect } from "react-multi-select-component";
import Select from "react-select";
import Swl from 'sweetalert2';
import { listPermissionsThunk } from "app/permissions/Permissions.thunk";
import { statusEnum } from "app/__shared__/model/enums/statusLoading"
import toast from "react-hot-toast";

export const RolesCreateForm = () => {
    const createRoles = useAppSelector(state => state.RolesReducer.CreateRolesReducer)
    const {toggleModalClose} = useContext(ModalContext)
    const dispatch = useAppDispatch()
    const allPermission = useAppSelector(state => state.PermissionReducer)

    const [formData, setFormData] = useState<{
        name: string,
        selectedPermissions: any[],
        isAllValidStatus:statusEnum
    }>({
        name: '',
        selectedPermissions: [],
        isAllValidStatus: statusEnum.idle,
    })

    const { name, selectedPermissions, isAllValidStatus } = formData

    const [optionsPermissions, setOptionsPermissions] = useState<any>([]);

    useEffect(() => {
        dispatch(listPermissionsThunk({}))
    }, [])

    useEffect(() => {
        if(createRoles.status === statusEnum.succeeded){
            dispatch(listRolesThunk({}))
            toggleModalClose()   
            toast.success('Rol bazaya əlavə edildi!',);
            dispatch(CreateRolesActions.resetRolesCreate())
        }
    }, [createRoles.status])

    useEffect(() => {
        if(allPermission.status === statusEnum.succeeded){
            const options = allPermission.data.map(item => {
                return {
                    label: item.title,
                    value:item.name,
                }
            })
            const removePermissionPermissionsRegex = /^(?!permission).*/
            const filteredOptions = options.filter(item => {
                return removePermissionPermissionsRegex.test(item.value)
            }) 
            setOptionsPermissions(filteredOptions)
        }
    }, [allPermission.status])

    const handleSubmit = (e:React.FormEvent) => {
        e.preventDefault()
        if(!name){
            setFormData({
                ...formData,
                isAllValidStatus: statusEnum.failed,
            })
            return 
        }else{
            setFormData({
                ...formData,
                isAllValidStatus: statusEnum.succeeded,
            })
        }

        const permissions = selectedPermissions.map((item:any) => {
            return item.value
        })

        dispatch(createRolesThunk(
            {
                data:{
                    name,
                    permissions:[...permissions]
                }
            }
        ))

        
        
    }

    return (
        <div>
            {
                <form  className="max-w-lg" onSubmit={handleSubmit}>
                    <div className="row pt-2">
                        
                        <div className="col-md-12 mb-4">
                            <label  className="font-weight-bold">Ad</label>
                            <CustomInput
                                required={true}
                                isFormSubmitStatus={isAllValidStatus}
                                emptyError={'Rol adını daxil edin.'}
                                value={name}
                                type='text'
                                placeHolder='Rol adını daxil edin.'
                                onChange={
                                    (data) => setFormData({
                                        ...formData,
                                        name: data,
                                    }) 
                                }
                            />
                            
                        </div>
                        
                        
                        <div className="col-md-12 mb-4">
                            <label  className="font-weight-bold">İcazələr</label>
                            <div className="mb-4 flex gap-2 items-center">
                            <MultiSelect
                                    options={optionsPermissions}
                                    value={selectedPermissions}
                                    onChange={
                                        (data:any) => setFormData({
                                            ...formData,
                                            selectedPermissions: data,
                                        })
                                    }
                                    labelledBy="title"
                                    className="w-full"
                                    overrideStrings={
                                        {
                                            allItemsAreSelected: "Bütün icazələr seçilib.",
                                            clearSearch: "Axtarışı təmizlə",
                                            clearSelected: "Seçilmişləri təmizlə",
                                            noOptions: "Axtarış nəticəsi tapılmadı",
                                            search: "Axtarış",
                                            selectAll: "Hamısını seçin",
                                            selectAllFiltered: "Hamısını seçin",
                                            selectSomeItems: "İcazələri seçin",
                                            create: "Yarat",
                                        }
                                    }
                            />    
                            </div>
                        </div>

                        <div className="col-md-12">
                            {/* {console.log(selectedPermissions)} */}
                            {/* {console.log(optionsPermissions)} */}
                            {/* <Select 
                                closeMenuOnSelect={false}
                                isLoading={allPermission.status === statusEnum.loading}
                                isMulti={true}
                                options={optionsPermissions}
                                value={selectedPermissions}
                                onChange={
                                    (data:any) => setFormData({
                                        ...formData,
                                        selectedPermissions: data,
                                    })
                                }
                                getOptionLabel={(option:any) => option.title}
                                getOptionValue={(option:any) => option.name}
                            /> */}
                        </div>
                        
                    </div>

                    <div className="row">
                        <div className="col-md-12 text-md-right">
                            <button
                                type="button"
                                onClick={toggleModalClose}
                                className="btn btn-primary font-weight-bold ml-2 bg-gray-500 text-white"
                            >
                                Ləğv et
                            </button>
                            <button
                                type="submit"
                                color="success"
                                className="btn btn-success font-weight-bold ml-2 bg-green-500 text-white hover:text-white"
                            >
                                Təsdiq et
                            </button>
                        </div>
                    </div>
                    {
                        createRoles.status === statusEnum.loading &&
                        <div className="flex w-full justify-center mt-2 items-center">
                            <div className="flex gap-4  items-center mt-4">
                                <FontAwesomeIcon icon={faSpinner} className='animate-spin'/>
                                <span>Yüklənir</span>
                            </div>
                        </div>
                    }

                    {
                        createRoles.status === statusEnum.failed &&
                        <div className="flex flex-col w-full justify-center mt-2 items-center">
                            {Object.keys(createRoles.response.errors).map((key, index) => (
                                <div key={index} className="w-full flex gap-4  items-center mt-4">
                                    <FontAwesomeIcon icon={faExclamationCircle} className='text-red-500'/>
                                    {
                                        createRoles.response.errors[key].map((error:any, index:any) => (
                                            <span key={index}>{error}</span>
                                        ))
                                    }
                                </div>
                            ))}
                        </div>
                    }
                </form>
            }

        </div>
    )
}