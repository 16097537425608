import {
    Form,
    Input,
    Label,
} from 'reactstrap';
import { Link, useParams } from 'react-router-dom';
import { faBan, faCalendar, faCheck, faCheckSquare, faClock, faList, faLocationArrow, faUser } from '@fortawesome/free-solid-svg-icons';
import { useAppDispatch, useAppSelector } from 'app/__shared__/components/store/store';
import { useContext, useEffect, useState } from 'react';

import { AppealsMeetForm } from '../AppealsMeetForm';
import { AppealsRejectFrom } from '../AppealsRejectForm';
import { AppealsResultForm } from '../AppealsResultForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormLoader } from 'app/__shared__/components/common/form-loader/FormLoader';
import { MapMultipleLocation } from 'app/__shared__/components/common/map/MapMultipleLocation';
import { ModalContext } from 'app/__shared__/components/context/modal/ModalContext';
import { statusEnum } from 'app/__shared__/model/enums/statusLoading';
import { viewAppealsThunk } from '../slices/Appeals.thunk';

export const AppealsView = () => {
    const dispatch = useAppDispatch()
    const selectAppealsData = useAppSelector(state => state.AppealsReducer.SelectAppealsReducer)
    let { id } = useParams();
    const {toggleModalShow} = useContext(ModalContext)
    const [meet_date , setMeetdate] = useState<string>("")

    useEffect(() => {
        dispatch(viewAppealsThunk({id}))
    }, [])


    useEffect(() => {
        if(selectAppealsData.status === statusEnum.succeeded)
        {
            console.log(selectAppealsData.data)
            if(selectAppealsData.data.status.id === 2 || selectAppealsData.data.status.id === 3)
            {
                setMeetdate(selectAppealsData.data.meeting_date)
            }
        }
    }, [selectAppealsData.status])
    
    const createMeet = () => {
        dispatch(toggleModalShow(<AppealsMeetForm id={id} appealsData={selectAppealsData.data}/> , "Görüş təyin edin."))        
    }


    const updateMeet = () => {
        dispatch(toggleModalShow(<AppealsMeetForm id={id} appealsData={selectAppealsData.data}/> , "Görüşü yenidən təyin edin."))        
    }

    const sendResult = () => {
        dispatch(toggleModalShow(<AppealsResultForm id={id}/> , "Normativləri qiymətləndirib nəticəni göndərin."))        
    }

    const sendReject = () => {
        dispatch(toggleModalShow(<AppealsRejectFrom id={id}/> , ""))        
    }

    return (
        <div className="bg-white rounded p-4">
            <div className="row mb-4">
                <div className="col-md-12">
                    <div className="page-header">
                        <h1 className="font-weight-bold">Baxış</h1>
                        {
                            selectAppealsData.status === statusEnum.succeeded &&
                            <div className='flex gap-4'>
                                <button disabled type="button" className={`flex gap-2  btn bg-gray-100 ${
                                            selectAppealsData.data.status.id === 1 ? "text-blue-500" :
                                            selectAppealsData.data.status.id === 2 ? "text-yellow-500" :
                                            selectAppealsData.data.status.id === 3 ? "text-green-500" :
                                            selectAppealsData.data.status.id === 4 ? "text-red-500" :""
                                        } gap-2 flex justify-center items-center `}>
                                    <span>
                                        {
                                            selectAppealsData.data.status.id === 1 ? 
                                            <FontAwesomeIcon icon={faClock}/> 
                                            :
                                            selectAppealsData.data.status.id === 2 ?
                                            <FontAwesomeIcon icon={faCalendar}/> 
                                            :
                                            selectAppealsData.data.status.id === 3 ?
                                            <FontAwesomeIcon icon={faCheck}/> 
                                            :
                                            selectAppealsData.data.status.id === 4 ?
                                            <FontAwesomeIcon icon={faBan}/> 
                                            :
                                            ""
                                        }
                                    </span>
                                    <span>{selectAppealsData.data.status.name}</span>
                                </button>
                                
                                {
                                    (selectAppealsData.data.status.id === 1 || selectAppealsData.data.status.id === 2) && 
                                    <button onClick={selectAppealsData.data.meeting_date ? updateMeet : createMeet  } type="button" className="btn btn-outline-primary gap-2 flex justify-center items-center hover:text-gray-100">
                                        <FontAwesomeIcon icon={faCalendar} />
                                        <span>{selectAppealsData.data.meeting_date ?  "Görüşü redaktə et" : "Görüş təyin et" }</span>
                                    </button>
                                }
                                
                                {
                                    (selectAppealsData.data.status.id === 2)  && 
                                    <button onClick={sendResult} type="button" className="btn btn-outline-primary gap-2 flex justify-center items-center hover:text-gray-100">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                        <span>{"Nəticəni göndər"}</span>
                                    </button>
                                }
                                {
                                    (selectAppealsData.data.status.id === 1 ||  selectAppealsData.data.status.id === 2)  && 
                                    <button onClick={sendReject} type="button" className="btn btn-outline-primary gap-2 flex justify-center items-center hover:text-gray-100">
                                        <FontAwesomeIcon icon={faBan} />
                                        <span>{"İmtina et"}</span>
                                    </button>
                                }    
                            </div>
                        }
                        
                    </div>
                </div>
            </div>
            
            {selectAppealsData.status === statusEnum.loading && <FormLoader/>}
            <Form>
                {
                    selectAppealsData.status === statusEnum.succeeded  &&
                    <div className="row mb-4 pt-2">
                        <div className="col-md-6 mb-2 mt-4">
                            <Label className="text-muted">ID</Label>
                            <Input
                                name="id"
                                placeholder="id"
                                disabled
                                defaultValue={selectAppealsData.data.id}
                            />
                        </div>

                        <div className="col-md-6 mt-4">
                            <Label>Adı</Label>
                            <Input
                                name="name"
                                placeholder="Ad "
                                disabled
                                defaultValue={selectAppealsData.data.name}
                            />
                        </div>

                        <div className="col-md-6 mt-4">
                            <Label>Soyadı</Label>
                            <Input
                                name="name"
                                placeholder="Ad "
                                disabled
                                defaultValue={selectAppealsData.data.surname}
                            />
                        </div>

                        <div className="col-md-6 mt-4">
                            <Label>Ata adı</Label>
                            <Input
                                name="name"
                                placeholder="Ad "
                                disabled
                                defaultValue={selectAppealsData.data.father_name}
                            />
                        </div>

                        <div className="col-md-6 mt-4">
                            <Label>Elektron Poçt</Label>
                            <Input
                                name="name"
                                placeholder="Ad "
                                disabled
                                defaultValue={selectAppealsData.data.email}
                            />
                        </div>

                        <div className="col-md-6 mt-4">
                            <Label>Telefon nömrəsi</Label>
                            <Input
                                name="name"
                                placeholder="Ad "
                                disabled
                                defaultValue={selectAppealsData.data.phone}
                            />
                        </div>
                        
                        <div className="col-md-6 mb-2 mt-4">
                            <Label>Şəxsiyyətin seriya nömrəsi</Label>
                            <Input
                                name="name"
                                placeholder="Ad "
                                disabled
                                defaultValue={selectAppealsData.data.serial_number}
                            />
                        </div>

                        <div className="col-md-6 mb-2 mt-4">
                            <Label>Şəxsiyyətin fin nömrəsi</Label>
                            <Input
                                name="name"
                                placeholder="Ad "
                                disabled
                                defaultValue={selectAppealsData.data.fin}
                            />
                        </div>

                        <div className="col-md-6 mb-2 mt-4">
                            <Label>Kütləsi</Label>
                            <Input
                                name="name"
                                placeholder="Ad "
                                disabled
                                defaultValue={selectAppealsData.data.weight}
                            />
                        </div>

                        <div className="col-md-6 mb-2 mt-4">
                            <Label>Boy hündürlüyü (santimetrlə)</Label>
                            <Input
                                name="name"
                                placeholder="Ad "
                                disabled
                                defaultValue={selectAppealsData.data.height}
                            />
                        </div>
                        

                        <div className="col-md-6 mb-2 mt-4">
                            <Label>Təvəllüdü</Label>
                            <Input
                                name="name"
                                placeholder="Ad "
                                disabled
                                defaultValue={selectAppealsData.data.birthday}
                            />
                        </div>

                        <div className="col-md-6 mb-2 mt-4">
                            <Label>Cinsi</Label>
                            <Input
                                name="name"
                                placeholder="Ad "
                                disabled
                                defaultValue={selectAppealsData.data.gender === "1" ? "Kişi" : "Qadın"}
                            />
                        </div>

                        <div className="col-md-6 mb-2 mt-4">
                            <Label>Hərbi mükəlləfiyyəti</Label>
                            <Input
                                name="name"
                                placeholder="Ad "
                                disabled
                                defaultValue={selectAppealsData.data.is_military === 1 ? "Xidməti başa vurmuş" : "Xidmətdə olmamış"}
                            />
                        </div>

                        <div className="col-md-6 mb-2 mt-4">
                            <Label>Məhkumluq</Label>
                            <Input
                                name="name"
                                placeholder="Ad "
                                disabled
                                value={selectAppealsData.data.has_crime === 1 ? "Bəli" : "Xeyr"}
                            />
                        </div>

                        <div className="col-md-6 mb-2 mt-4">
                            <Label>Əlillik</Label>
                            <Input
                                name="name"
                                placeholder="Ad "
                                disabled
                                value={selectAppealsData.data.has_invalidity === 1 ? "Bəli" : "Xeyr"}
                            />
                        </div>

                        <div className="col-md-12 mt-4">
                            <Label>Şəkil</Label>
                            <div className='w-full'>
                                <img src={selectAppealsData.data.image} className="w-auto rounded-top" alt=""/>
                            </div>
                        </div>

                        {
                            selectAppealsData.data.video &&
                            <div className="col-md-12 mt-4">
                                <Label>Video</Label>
                                <div className='w-full'>
                                    <video controls src={selectAppealsData.data.video}></video>
                                </div>
                            </div>
                        }
                        {
                            selectAppealsData.data.note &&
                            <div className="col-md-12 mt-4">
                                <Label>Qeyd</Label>
                                <textarea className='w-full form-control resize-none ' disabled value={selectAppealsData.data.note}></textarea>
                            </div>
                        }

                        <div className="col-md-12 mt-4">
                            <Label>Məkanın detallı adresi</Label>
                            <div className='form-control bg-gray-200'>
                                <FontAwesomeIcon icon={faLocationArrow}/> {selectAppealsData.data.address}
                            </div>
                        </div>

                        <div className="col-md-12 mt-5">
                            <Label>Məkanın xəritə üzərindən görünüşü</Label>
                            <MapMultipleLocation positions={[
                                {
                                    lat: selectAppealsData.data.lat,
                                    lng: selectAppealsData.data.long,
                                    title:  "Namizədin ünvanı: " +  selectAppealsData.data.address,
                                },
                                {
                                    lat: selectAppealsData.data.branch?.lat,
                                    lng: selectAppealsData.data.branch?.long,
                                    title: "Bağlandığı filial: " +  selectAppealsData.data.branch?.address ,
                                }
                            ]} />
                            {/* <MapObject lat={selectAppealsData.data.lat} long={selectAppealsData.data.long}/> */}
                        </div>
                        
                        {
                            selectAppealsData.data.normatives &&
                            <div className="mt-4 col-md-12 text-center">
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                        <th scope="col">ID</th>
                                        <th scope="col">Normativ adı</th>
                                        <th scope="col">Qiymət</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            JSON.parse(selectAppealsData.data.normatives) && JSON.parse(selectAppealsData.data.normatives).map((item:any) => {
                                                return (
                                                    <tr key={item.id}>
                                                        <th scope="row">{item.id}</th>
                                                        <td>{item.name}</td>
                                                        <td>{item.starCount}</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                        
                                    </tbody>
                                </table>
                            </div>
                        }

                        {
                            (selectAppealsData.data.meeting_date) &&
                            <div className="col-md-12 mt-4">
                                <div className='form-group'>
                                    <label>Görüş tarixi</label>
                                    <input type="datetime-local" value={meet_date} disabled className='form-control' />
                                </div>

                                <div className='form-group'>
                                    <label>Görüş Məkanı</label>
                                    <input value={selectAppealsData.data.branch?.address} type="text" disabled className='form-control' />
                                </div>

                                <div className='form-group'>
                                    <label>Görüşün keçiriləcəyi filial</label>
                                    <input value={selectAppealsData.data.branch?.name} type="text" disabled className='form-control' />
                                </div>

                                <div className='form-group'>
                                    <label>Məsul şəxs</label>
                                    <input value={selectAppealsData.data.meeting_person} type="text" disabled className='form-control' />
                                </div>
                            </div>
                        }

                        <div className="col-md-12 text-center mt-5">
                            <Link
                                to="/appeals"
                                className='btn btn-light'
                            >
                                <FontAwesomeIcon icon={faList} className="mr-2" />
                                Listə geri qayıt
                            </Link>
                        </div>
                    </div>
                }
        
                
                {
                    selectAppealsData.status === statusEnum.failed &&
                    <div className="row">
                        <div className="col-md-12">
                            <div className="alert alert-warning">
                                Məlumat tapılmadı
                            </div>
                        </div>
                    </div>
                }
            </Form>
        </div>
    )
}
 