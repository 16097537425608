import { createNormativesService, deleteNormativesService, editNormativesService, listNormativesService, viewNormativesService } from "app/__shared__/helpers/api/services/normatives"

import { createAsyncThunk } from "@reduxjs/toolkit"

export const listNormativesThunk = createAsyncThunk("normatives/list", listNormativesService)
export const viewNormativesThunk = createAsyncThunk("normatives/view", viewNormativesService)
export const editNormativesThunk = createAsyncThunk("normatives/edit", editNormativesService)
export const createNormativesThunk = createAsyncThunk("normatives/create", createNormativesService)
export const deleteNormativesThunk = createAsyncThunk("normatives/delete", deleteNormativesService)

