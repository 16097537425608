import { Route, Routes } from "react-router-dom"
import { RolesList } from "./RolesList"
import { RolesView } from "./RolesView"


export const RolesRoute = () => {
    return (
        <Routes>
            <Route path=":id" element={<RolesView/>} />
            <Route path="/" element={<RolesList/>} />
        </Routes>
    )
}