import { AXIOS_CONFIG } from "../config"
import { axiosInstance } from "../interceptor"

export const listNormativesService = async ({rejectWithValue}:any) => {
    try {
        const response = await axiosInstance({...AXIOS_CONFIG.NORMATIVE.LIST_NORMATIVE})
        return response.data
    } catch (error:any) {
        return rejectWithValue(error.response.data)
    }
}


export const viewNormativesService = async (data:any , {rejectWithValue}:any) => {
    try {
        const response = await axiosInstance(`normatives/${data.id}`, AXIOS_CONFIG.NORMATIVE.SHOW_NORMATIVE)
        return response.data
    } catch (error:any) {
        return rejectWithValue(error.response.data)
    }
}

export const createNormativesService = async (data:any , {rejectWithValue}:any) => {
    try {
        const config:any = AXIOS_CONFIG.NORMATIVE.CREATE_NORMATIVE
        config.data = data.data
        const response = await axiosInstance(config)
        return response.data
    } catch (error:any) {
        return rejectWithValue(error.response.data)
    }
}

export const editNormativesService = async (data:any, {rejectWithValue}:any) => {
    try {
        const response = await axiosInstance(`normatives/${data.id}` , {...AXIOS_CONFIG.NORMATIVE.UPDATE_NORMATIVE, data:data.data})
        return response.data
    } catch (error:any) {
        return rejectWithValue(error.response.data)
    }
}

export const deleteNormativesService = async (data:any, {rejectWithValue}:any) => {
    try {
        const response = await axiosInstance(`/normatives/${data.id}`, AXIOS_CONFIG.NORMATIVE.DELETE_NORMATIVE)
        return response.data
    } catch (error:any) {
        return rejectWithValue(error.response.data)
    }
}


