export const AXIOS_CONFIG = {
    AUTH:{
        LOGIN: {
            method: 'post',
            url: '/auth/login',
            data: {
                email:"nasirmovlamov@gmail.com",
                password : "nasir1234"
            },
        },
        ME: {
            method: 'post',
            url: '/auth/me',
        },
        LOGOUT:{
            method: 'post',
            url: '/auth/logout',
        },
        REFRESH_TOKEN: {
            method: 'post',
            url: '/auth/refresh',
            data:{
                email: '',
                password: '',
            }
        },
    },
    PERMISSION:{
        LIST_PERMISSION: {
            method: 'get',
            url: '/permission',
        },
        SHOW_PERMISSION: {
            method: 'get',
            url: '/permission/:id',
        },
        CREATE_PERMISSION: {
            method: 'post',
            url: '/permission',
            data: {
                name: '',
            },
        },
        UPDATE_PERMISSION: {
            method: 'put',
            url: '/permission/:id',
            data: {
                name: '',
            },
        },
        DELETE_PERMISSION: {
            method: 'delete',
            url: '/permission/:id',
        },
    },
    USER:{
        LIST_USERS: {
            method: 'get',
            url: '/users'
        },
        SHOW_USER: {
            method: 'get',
            url: '/users/:id',
        },
        CREATE_USER: {
            method: 'post',
            url: '/users',
            data: {
                name: "",
                surname: "",
                email: "",
                password : "",
                status: 1,
                role : [1]
            },
        },
        UPDATE_USER: {
            method: 'put',
            url: '/users/:id',
            data: {
                name: "",
                surname: "",
                email: "",
                password : "",
                status: 1,
                role : [1]
            },
        },
        DELETE_USER: {
            method: 'delete',
            url: '/users/:id',
        },
    },
    VACANCY_SETTINGS:{
        VIEW_VACANCY_SETTINGS: {
            method: 'get',
            url: '/settings/',
        },
        UPDATE_VACANCY_SETTINGS: {
            method: 'put',
            url: '/settings/1',
            data: {
                name: '',
                email: '',
                password: '',
            },
        },
    },
    NORMATIVE:{
        LIST_NORMATIVE: {
            method: 'get',
            url: '/normatives',
        },
        SHOW_NORMATIVE: {
            method: 'get',
            url: '/normatives/:id',
        },
        CREATE_NORMATIVE: {
            method: 'post',
            url: '/normatives',
            data: {
                name: '',
                description: '',
                type: '',
                status: '',
                created_by: '',
                updated_by: '',
            },
        },
        UPDATE_NORMATIVE: {
            method: 'put',
            url: '/normatives/:id',
            data: {
                name: '',
                description: '',
                type: '',
                status: '',
                created_by: '',
                updated_by: '',
            },
        },
        DELETE_NORMATIVE: {
            method: 'delete',
            url: '/normatives/:id',
        },
    },
    ROLE:{
        LIST_ROLE: {
            method: 'get',
            url: '/role',
        },
        SHOW_ROLE: {
            method: 'get',
            url: '/role/:id',
        },
        CREATE_ROLE: {
            method: 'post',
            url: '/role',
            data: {
                name: '',
                description: '',
                status: '',
                created_by: '',
                updated_by: '',
            },
        },
        UPDATE_ROLE: {
            method: 'put',
            url: '/role/:id',
            data: {
                name: '',
                description: '',
                status: '',
                created_by: '',
                updated_by: '',
            },
        },
        DELETE_ROLE: {
            method: 'delete',
            url: '/role/:id',
        },
    },
    BRANCH:{
        LIST_BRANCH: {
            method: 'get',
            url: '/branches',
        },
        LIST_ALL_BRANCH: {
            method: 'get',
            url: '/branches?all&all',
        },
        SHOW_BRANCH: {
            method: 'get',
            url: '/branches/:id',
        },
        CREATE_BRANCH: {
            method: 'post',
            url: '/branches',
            data: {
                name: '',
                description: '',
                status: '',
                created_by: '',
                updated_by: '',
            },
        },
        UPDATE_BRANCH: {
            method: 'put',
            url: '/branches/:id',
            data: {
                name: '',
                description: '',
                status: '',
                created_by: '',
                updated_by: '',
            },  
        },
        DELETE_BRANCH: {
            method: 'delete',
            url: '/branches/:id',
        },
    },
    APPEAL:{
        LIST_APPEAL: {
            method: 'get',
            url: '/appeals',
        },
        SHOW_APPEAL: {
            method: 'get',
            url: '/appeals/:id',
        },
        CREATE_APPEAL: {
            method: 'post',
            url: '/appeals',
            data: {
                name: '',
                description: '',
                status: '',
                created_by: '',
                updated_by: '',
            },
        },
        UPDATE_APPEAL: {
            method: 'put',
            url: '/appeals/:id',
            data: {
                name: '',
                description: '',
                status: '',
                created_by: '',
                updated_by: '',
            },  
        },
        DELETE_APPEAL: {
            method: 'delete',
            url: '/appeals/:id',
        },
        UPDATE_MEET_APPEAL: {
            method: 'put',
            url: '/appeals/:id',
        },
        UPDATE_RESULT_APPEAL: {
            method: 'put',
            url: '/appeals/:id',
        },
        REJECT_APPEAL: {
            method: 'put',
            url: '/appeals/:id',
            data:{
                type:"RESULT",
                result:"No",
                normatives:[]
            }
        },
    },



    USER_ROLE:{
        LIST_USER_ROLE: {
            method: 'get',
            url: '/user-role',
        },
        SHOW_USER_ROLE: {
            method: 'get',
            url: '/user-role/:id',
        },
        CREATE_USER_ROLE: {
            method: 'post',
            url: '/user-role',
            data: {
                user_id: '',
                role_id: '',
                status: '',
                created_by: '',
                updated_by: '',
            },
        },
        UPDATE_USER_ROLE: {
            method: 'put',
            url: '/user-role/:id',
            data: {
                user_id: '',
                role_id: '',
                status: '',
                created_by: '',
                updated_by: '',
                permissions:[]
            },
        },
        DELETE_USER_ROLE: {
            method: 'delete',
            url: '/user-role/:id',
        },
    },
    
    
    
}