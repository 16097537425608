import { CustomInputProps } from "./CustomInput";
import { useState } from "react";

export const useCustomInputLogic = (props:CustomInputProps) => {

    const [isBlured, setIsBlured] = useState(true);
    const [isInValid, setIsInValid] = useState(true);
    const [isEmpty, setIsEmpty] = useState(true);
    const [isClicked, setIsClicked] = useState(false);
    const [hasFileName, sethasFileName] = useState('');
    
    const handleFocus = () => {
        setIsClicked(true)
        setIsBlured(true)
    }
      
    const handleBlur = () => {
        setIsBlured(false)
    };

    const handleChange = (e: any) => {
        setIsClicked(true)
        
        if(props.type === 'file'){
            sethasFileName(e.target.files[0].name)     
            props.onChange(e.target.files[0])
            console.log(e.target.files[0])
            // if(e.target.files[0]) {
            //     setIsEmpty(true)
            // } else {
            //     setIsEmpty(false)
            // }
            if(props.regex){
                if(props.regex.test(e.target.value)) {
                    setIsInValid(true)
                } else {
                    setIsInValid(false)
                }
            }
            return 
        }
        props.onChange(e.target.value)

        if(props.onlyNumber){
            const value = e.target.value.replace(/[^0-9]/g, '')
            props.onChange(value)
        }
        if(e.target.value.length === 0) {
            setIsEmpty(true)
        } else {
            setIsEmpty(false)
        }
        if(props.regex){
            if(props.regex.test(e.target.value)) {
                setIsInValid(true)
            } else {
                setIsInValid(false)
            }
        }
        
        
    };
    
    return {isBlured , isInValid , setIsBlured , setIsInValid, isEmpty, setIsEmpty, isClicked,  setIsClicked,  handleFocus, handleBlur , handleChange, hasFileName}
}