import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { RequestType } from 'app/__shared__/model/types/request'
import { RoleType } from 'app/__shared__/model/types/role.type'
import { RootState } from 'app/__shared__/components/store/store'
import { statusEnum } from 'app/__shared__/model/enums/statusLoading'
import { viewRolesThunk } from './Roles.thunk'

export type SelectRolesType = {} & RequestType<RoleType> 


const SelectedRoleState: SelectRolesType = {
    status: statusEnum.idle,
    response:{
        data: {
            id:0,
            name:"",
            permissions:[],
        },
        errors:{}
    }
}


export const SelectRolesSlice = createSlice({
  name: 'select-roles-slice',
  initialState: SelectedRoleState,
  reducers: {
      
  },
  
  extraReducers: (builder) => {
    builder.addCase(viewRolesThunk.pending, (state, action: PayloadAction<any>) => {
        state.status = statusEnum.loading
    })
    
    builder.addCase(viewRolesThunk.rejected, (state, action:  PayloadAction<any>) => {
        state.status = statusEnum.failed
        state.response.data = {
            id:0,
            name:"",
            permissions:[],
        }
    })
    
    builder.addCase(viewRolesThunk.fulfilled, (state, action:  PayloadAction<any>) => {
        state.status = statusEnum.succeeded
        state.response.data = action.payload.data
    })

    
  },
})


export const SelectRolesActions = {
    ...SelectRolesSlice.actions,
}


export const SelectRolesData = (state:RootState) => state.RolesReducer.SelectRolesReducer

export const SelectRolesReducer = SelectRolesSlice.reducer