import { Route, Routes } from "react-router-dom"

import { AppealsCreate } from "./AppealsCreate"
import { AppealsEdit } from "./AppealsEdit"
import { AppealsList } from "./AppealsList"
import { AppealsView } from "./AppealsView"

export const AppealsRoute = () => {
    return (
        <Routes>
            <Route path=":id/edit" element={<AppealsEdit/>} />
            <Route path=":id" element={<AppealsView/>} />
            <Route path="/create" element={<AppealsCreate/>} />
            <Route path="/" element={<AppealsList/>} />
        </Routes>
    )
}