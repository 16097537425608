import { AuthData } from "app/auth/Auth.slice";
import { BRANCHES_PERMISSIONS } from "app/__shared__/config/permissions";
import { BranchesAddButton } from "../BranchesAddButton"
import { BranchesCustomTable } from "../BranchesCustomTable"
import { useAppSelector } from "app/__shared__/components/store/store";

export const BranchesList = () => {
    const {user:{permissions}} = useAppSelector(AuthData);

    
    return (
        <div className="bg-white rounded-md p-6 mb-20">
            
            <div className="w-full flex justify-between items-center">
                <h1 className="text-4xl align-top">Filiallar</h1>
                {permissions[BRANCHES_PERMISSIONS.create] && <BranchesAddButton/>}
            </div>

            <BranchesCustomTable/>
        </div>
    )
}
 