import { AuthActions, AuthData } from "../app/auth/Auth.slice";
import { HOURS, SECONDS } from "app/__shared__/data/contants";
import { Navigate, Routes as ReactRouterRoutes, Route, useLocation, useNavigate } from "react-router-dom";
import React, { useEffect } from 'react'
import { meThunk, refreshTokenThunk } from "app/auth/Auth.thunk";
import { useAppDispatch, useAppSelector } from "../app/__shared__/components/store/store";

import { APPLICATION_PATH } from "app/__shared__/helpers/api/services/path";
import { BranchesList } from "app/branches/routes/BranchesList";
import { CreateAppeal } from "app/__tests__/appeals/CreateAppeal";
import { Dashboard } from "app/dashboard/Dashboard";
import Loader from "../app/__shared__/components/common/loader/Loader";
import { Login } from "app/login/Login";
import { Page401 } from "../app/401/401";
import { Page403 } from "../app/403/403";
import { Page404 } from "../app/404/404";
import { Page500 } from "../app/500/500";
import { getCookie } from "app/__shared__/helpers/utils/cookie/cookie";
import { statusEnum } from "app/__shared__/model/enums/statusLoading";

export const ProtectedRoutes:React.FC = () => {
    const authData = useAppSelector(AuthData);
    const dispatch  = useAppDispatch()
    const navigate = useNavigate()
    const cookie_jwt = getCookie('jwt')
    useEffect(() => {
        if(getCookie('jwt')){
            dispatch(meThunk({}))
        }
        
    }, []);

    useEffect(() => {
        if(!getCookie('jwt')){
           navigate('/')
           dispatch(AuthActions.toggleAuthStateFailed())
        }
    }, [authData]);


    useEffect(() => {
        // if(authData.userActiveSessionTime > (HOURS * 2)){
        //     dispatch(refreshTokenThunk({}))
        // }
    }, [authData.userActiveSessionTime]);

    return (
        <>
            {  }
            <ReactRouterRoutes>
                {(authData.status === statusEnum.loading) && <Route path={'*'} element={<Loader/>}/>}
                <Route path={APPLICATION_PATH.CREATE_APPEAL} element={<CreateAppeal/>}/>
                <Route path={APPLICATION_PATH.UNAUTHORIZED} element={<Page401/>}/>
                <Route path={APPLICATION_PATH.FORBIDDEN} element={<Page403/>}/>
                <Route path={APPLICATION_PATH.LOGIN} element={<Navigate to="/"/>}/>
                <Route path={APPLICATION_PATH.SERVER_ERROR} element={<Page500/>}/>
                {!cookie_jwt && <Route path="/"  element={<Login/>} /> }
                { 
                    (authData.status === statusEnum.succeeded && cookie_jwt)  && 
                    <Route path="/" element={<Dashboard />}>
                        <Route path="users">
                            <Route path=":id"/>
                        </Route>
                        <Route path="roles">
                            <Route path=":id"/>
                        </Route>
                        <Route path="appeals">
                            <Route path="create"/>
                            <Route path=":id/edit"/>
                            <Route path=":id"/>
                        </Route>
                        <Route path="branches">
                            <Route path="create"/>
                            <Route path=":id/edit"/>
                            <Route path=":id"/>
                        </Route>
                        <Route path="settings" >
                            <Route path="update"/>
                        </Route>
                        <Route path="normatives">
                            <Route path=":id"/>
                        </Route>
                    </Route>
                }
                
                <Route path={'*'} element={<Page404/>}/>
            </ReactRouterRoutes>
        </>
   )
}