import { AuthData } from "app/auth/Auth.slice";
import { USERS_PERMISSIONS } from "app/__shared__/config/permissions";
import { UsersAddButton } from "../UsersAddButton"
import { UsersCustomTable } from "../UsersCustomTable"
import { useAppSelector } from "app/__shared__/components/store/store";

export const UsersList = () => {
    const {user:{permissions}} = useAppSelector(AuthData);

    
    return (
        <div className="bg-white rounded-md p-6 mb-20">
            
            <div className="w-full flex justify-between items-center">
                <h1 className="text-4xl align-top">İstifadəçilər</h1>
                {permissions[USERS_PERMISSIONS.create] && <UsersAddButton/> }
            </div>

            <UsersCustomTable/>
            
        </div>
    )
}
