import { createUsersService, deleteUsersService, editUsersService, listUsersService, viewUsersService } from "app/__shared__/helpers/api/services/user"

import { createAsyncThunk } from "@reduxjs/toolkit"

export const listUsersThunk = createAsyncThunk("users/list", listUsersService)
export const listAllUsersThunk = createAsyncThunk("users/alllist", listUsersService)
export const viewUsersThunk = createAsyncThunk("users/view", viewUsersService)
export const editUsersThunk = createAsyncThunk("users/edit", editUsersService)
export const createUsersThunk = createAsyncThunk("users/create", createUsersService)
export const deleteUsersThunk = createAsyncThunk("users/delete", deleteUsersService)

