import { faEdit, faEye, faTrash } from "@fortawesome/free-solid-svg-icons"
import { useContext, useState } from "react";

import { AuthData } from "app/auth/Auth.slice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ModalContext } from "app/__shared__/components/context/modal/ModalContext";
import { NormativesDeleteForm } from "./NormativesDeleteForm";
import { NormativesEditForm } from "./NormativesEditForm";
import { VACANCY_SETTINGS_PERMISSIONS } from "app/__shared__/config/permissions";
import { useAppSelector } from "app/__shared__/components/store/store";
import { useNavigate } from "react-router-dom";

interface ITableButton {
    id: number,
    
}
export const NormativesCustomTableButton = ({id}:ITableButton) => {
    let navigate = useNavigate();
    const [showButtons , setShowButtons] = useState<boolean>(false)
    const [buttonsHovered, setButtonsHovered] = useState<boolean>(false)
    const {toggleModalShow} = useContext(ModalContext)
    const {user:{permissions}} = useAppSelector(AuthData);

    const goView = () => {
        navigate(`/normatives/${id}`)
    }
    
    const openEditModal = () => {
        setShowButtons(false)
        toggleModalShow(<NormativesEditForm id={id}/>)
    }

    const openDeleteModal = () => {
        setShowButtons(false)
        toggleModalShow(<NormativesDeleteForm id={id}/>)
    }
    
    return(
        <div className="relative">
            <button className='btn  bg-[#1A67B4] rounded-md text-white w-5 h-5 justify-center items-center flex' 
                onBlur={() => setShowButtons(buttonsHovered)}
                onClick={() => setShowButtons(!showButtons)}
            >
                ···
            </button>
            {
                showButtons &&
                <div 
                    onMouseEnter={() => setButtonsHovered(true)}
                    onMouseLeave={() => setButtonsHovered(false)}
                    className="shadow-md absolute flex flex-col top-8 z-10 bg-white py-4 rounded-md w-48 left-0">
                    {
                        permissions[VACANCY_SETTINGS_PERMISSIONS.update] &&  
                        <button
                            onClick={goView}  
                            className="flex items-center p-1 gap-2 pl-4 hover:bg-[#1A67B4] hover:text-white transition-all duration-200">
                            <FontAwesomeIcon icon={faEye}/> 
                            <span>Bax</span>
                        </button>
                    }
                    {
                        permissions[VACANCY_SETTINGS_PERMISSIONS.update] && 
                        <button onClick={openEditModal} className="flex items-center p-1 gap-2 pl-4 hover:bg-[#1A67B4] hover:text-white transition-all duration-200">
                            <FontAwesomeIcon icon={faEdit}/> 
                            <span>Redaktə et</span>    
                        </button>
                    }
                    {
                        permissions[VACANCY_SETTINGS_PERMISSIONS.update] && 
                        <button onClick={openDeleteModal} className="flex items-center p-1 gap-2 pl-4 hover:bg-[#1A67B4] hover:text-white transition-all duration-200">
                            <FontAwesomeIcon icon={faTrash}/> 
                            <span>Sil</span>           
                        </button>
                    }
                </div>
            }
        </div>
    )
}