import { combineReducers } from "@reduxjs/toolkit";
import { CreateUsersReducer } from "./UsersCreate.slice";
import { EditUsersReducer } from "./UsersEdit.slice";
import { ListUsersReducer } from "./UsersList.slice";
import { SelectUsersReducer } from "./UsersSelect.slice";


export const UsersReducer = combineReducers({
    ListUsersReducer:ListUsersReducer,
    CreateUsersReducer:CreateUsersReducer,
    EditUsersReducer:EditUsersReducer,
    SelectUsersReducer:SelectUsersReducer,
})

