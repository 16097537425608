import { ROLE_PERMISSIONS, USERS_PERMISSIONS } from "app/__shared__/config/permissions";

import { AuthData } from "app/auth/Auth.slice";
import { RolesAddButton } from "../RolesAddButton"
import { RolesCustomTable } from "../RolesCustomTable"
import { useAppSelector } from "app/__shared__/components/store/store";

export const RolesList = () => {
    const {user:{permissions}} = useAppSelector(AuthData);

    return (
        <div className="bg-white rounded-md p-6 mb-20">
            
            <div className="w-full flex justify-between items-center">
                <h1 className="text-4xl align-top">Rollar</h1>
                {permissions[ROLE_PERMISSIONS.create] && <RolesAddButton/>}
            </div>

            <RolesCustomTable/>
        </div>
    )
}
 