// import { UserCreateForm } from "./UserCreateForm"
import { faMapLocation, faPlusCircle, faUserPlus, faWarning } from "@fortawesome/free-solid-svg-icons"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useNavigate } from "react-router-dom"

export const BranchesAddButton = (props: any) => {
    const navigate = useNavigate()

    const handleClick = () => {
        navigate("create")
    }


    return (
        <button onClick={handleClick} className="rounded-md px-4 py-2 bg-[#1A67B4] text-lg text-white btn gap-2"  >
            <span> Filial əlavə et</span>
            <FontAwesomeIcon icon={faPlusCircle} className="ml-2" />
        </button>
    )

}