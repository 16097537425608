import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { RequestType } from 'app/__shared__/model/types/request'
import { RootState } from 'app/__shared__/components/store/store'
import { UserType } from 'app/__shared__/model/types/user.type'
import { editUsersThunk } from './Users.thunk'
import { statusEnum } from 'app/__shared__/model/enums/statusLoading'

export type EditUsersStateType = {} & RequestType<UserType | null>


const EditUsersState: EditUsersStateType = {
    status: statusEnum.idle,
    response:{
        data:null,
        errors: {},
    },
}


export const EditUsersSlice = createSlice({
  name: 'edit-users-slice',
  initialState: EditUsersState,
  reducers: {
    resetUsersEdit: (state:EditUsersStateType) => {
        state.status = statusEnum.idle
    }
      
  },
  
  extraReducers: (builder) => {

    builder.addCase(editUsersThunk.pending, (state:EditUsersStateType, action: PayloadAction<any>) => {
        state.status = statusEnum.loading
    })
    
    builder.addCase(editUsersThunk.rejected, (state:EditUsersStateType, action:  PayloadAction<any>) => {
        state.status = statusEnum.failed
        state.response.errors = action.payload.errors
    })
    
    builder.addCase(editUsersThunk.fulfilled, (state:EditUsersStateType, action:  PayloadAction<any>) => {
        state.status = statusEnum.succeeded
        state.response.data = action.payload.data
    })
        
  },
})


export const EditUsersActions = {
    ...EditUsersSlice.actions,
}


export const EditUsersData = (state:RootState) => state.UsersReducer.EditUsersReducer
export const EditUsersReducer = EditUsersSlice.reducer