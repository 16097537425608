import { deletePermissionsThunk, editPermissionThunk, listPermissionsThunk, viewPermissionsThunk } from './Permissions.thunk'
import { setStatusPending, setStatusRejected, setStatusSucceeded } from '../__shared__/components/store/thunkStatusHandler'

import { RootState } from '../__shared__/components/store/store'
import { createSlice } from '@reduxjs/toolkit'
import { statusEnum } from 'app/__shared__/model/enums/statusLoading'

export interface IPermissionState {
    data:any[],
    error: {} | null,
    message: string | null,
    status: statusEnum,
}

const PermissionsState: IPermissionState = {
    data: [],
    error: null,
    message: null,
    status: statusEnum.loading,
}


export const PermissionsSlice = createSlice({
  name: 'auth-slice',
  initialState: PermissionsState,
  reducers: {
  },
  
  extraReducers: (builder) => {
    builder.addCase(listPermissionsThunk.pending, (state, action:any) => {
        setStatusPending(state, action)
    })
    builder.addCase(listPermissionsThunk.rejected, (state, action:any) => {
        setStatusRejected(state, action)
        state.error = action.payload
    })
    builder.addCase(listPermissionsThunk.fulfilled, (state, action:any) => {
        setStatusSucceeded(state, action)
        state.data = action.payload.data
        state.error = null
    })


    builder.addCase(viewPermissionsThunk.pending, (state, action: any) => {
        setStatusPending(state, action)
    })
    builder.addCase(viewPermissionsThunk.rejected, (state, action: any) => {
        setStatusRejected(state, action)
        state.error = action.payload
    })
    builder.addCase(viewPermissionsThunk.fulfilled, (state, action: any) => {
        setStatusSucceeded(state, action)
        state.data = action.payload.data
        state.error = null
    })



    builder.addCase(deletePermissionsThunk.pending, (state, action: any) => {
        setStatusPending(state, action)
    })
    builder.addCase(deletePermissionsThunk.rejected, (state, action: any) => {
        setStatusRejected(state, action)
        state.error = action.payload
        console.log(action.payload)
    })
    builder.addCase(deletePermissionsThunk.fulfilled, (state, action: any) => {
        setStatusSucceeded(state, action)
        state.error = null
    })

    builder.addCase(editPermissionThunk.pending, (state, action: any) => {
        setStatusPending(state, action)
    })
    builder.addCase(editPermissionThunk.rejected, (state, action: any) => {
        setStatusRejected(state, action)
        state.error = action.payload
        console.log(action.payload)
    })
    builder.addCase(editPermissionThunk.fulfilled, (state, action: any) => {
        setStatusSucceeded(state, action)
        state.error = null
    })


    

    
    

    
  },
})



export const PermissionData = (state:RootState) => state.PermissionReducer

export const PermissionReducer = PermissionsSlice.reducer