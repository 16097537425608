import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { editRolesThunk, viewRolesThunk } from './Roles.thunk'
import { setStatusPending, setStatusRejected, setStatusSucceeded } from 'app/__shared__/components/store/thunkStatusHandler'

import { RequestType } from 'app/__shared__/model/types/request'
import { RoleType } from 'app/__shared__/model/types/role.type'
import { RootState } from 'app/__shared__/components/store/store'
import { statusEnum } from 'app/__shared__/model/enums/statusLoading'

export type EditRolesStateType = {} & RequestType<RoleType>


const EditRolesState: EditRolesStateType = {
    status: statusEnum.idle,
    response:{
        data: {
            id:0,
            name:"",
            permissions:[],
        },
        errors:{}
    }
}


export const EditRolesSlice = createSlice({
  name: 'edit-roles-slice',
  initialState: EditRolesState,
  reducers: {
    resetRolesEdit: (state:EditRolesStateType) => {
        state.status = statusEnum.idle
        state.response.data = {
            id:0,
            name:"",
            permissions:[],
        }
        state.response.errors = {}
    }
      
  },
  
  extraReducers: (builder) => {
    
    builder.addCase(editRolesThunk.pending, (state:EditRolesStateType, action: PayloadAction<any>) => {
        state.status = statusEnum.loading
    })
    
    builder.addCase(editRolesThunk.rejected, (state:EditRolesStateType, action:  PayloadAction<any>) => {
        state.status = statusEnum.failed
        state.response.errors = action.payload.errors
    })
    
    builder.addCase(editRolesThunk.fulfilled, (state:EditRolesStateType, action:  PayloadAction<any>) => {
        state.status = statusEnum.succeeded
        state.response.data = action.payload.data
    })

  },
})


export const EditRolesActions = {
    ...EditRolesSlice.actions,
}


export const EditRolesData = (state:RootState) => state.RolesReducer.EditRolesReducer
export const EditRolesReducer = EditRolesSlice.reducer