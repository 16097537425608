import { statusEnum } from "app/__shared__/model/enums/statusLoading";
import { useCustomInputLogic } from "./useCustomInputLogic";
import { useInputErrorHandle } from "./useInputErrorHandle";
import { useState } from "react";

export interface CustomFileInputProps {
    value: any;
    onChange: (value: string) => void;
    required?: boolean;
    isFormSubmitStatus?:statusEnum
    accept?: string;
    regex?: RegExp;
    emptyError?: string;
    invalidError?: string;
    type?: string;
    placeHolder?: string;
    onlyNumber?: boolean;
    maxLength?: number;
    fileMessage?: string;
    minDate?: any;
    maxDate?: string;
    url?:string,
    file_name?:string,
    id:any,
}


export const CustomFileInput = (props: CustomFileInputProps) => {
    const { 
        required, 
        isFormSubmitStatus, 
        emptyError , 
        invalidError,
        value ,
        type, 
        placeHolder,
        maxLength,
        fileMessage,
        accept,
        url,
        file_name,
        id
    } = props
    
    const {
        isBlured , 
        isInValid , 
        isEmpty ,
        isClicked, 
        handleFocus, 
        handleBlur , 
        handleChange,
        hasFileName
    } = useCustomInputLogic(props)
    
    const {
        error, 
        errorClass, 
        errorSpanText
    } = useInputErrorHandle({
        isFormSubmitStatus , 
        isBlured , 
        isEmpty, 
        isInValid , 
        emptyError , 
        invalidError, 
        isClicked, 
        value
    })

    


    
    return (
        <div className="relative ">
            <input
                type={type}
                name='file'
                className={`form-control absolute w-full h-full opacity-0`}
                onChange={handleChange}
                onBlur={handleBlur}
                onFocus={handleFocus}
                required={required}
                placeholder={placeHolder}
                maxLength={maxLength}
                accept={accept}
                id={id}
            />
            {
                (value) 
                && 
                <div className={`w-full h-full form-control ${
                    (isFormSubmitStatus === statusEnum.failed && value === null && required) ?
                    " is-invalid " :
                    ""
                }`}>
                    {(file_name ? file_name : hasFileName ? hasFileName : '')}
                </div>
            }
            {   
                ( !value) 
                && 
                <div className={`w-full h-full form-control ${
                    (isFormSubmitStatus === statusEnum.failed && value === null && required) ?
                    " is-invalid " :
                    ""
                }`}>
                    {fileMessage}
                </div>
            }
            {
                (isFormSubmitStatus === statusEnum.failed && value === null && required) && 
                <div className="text-danger text-sm invalid">Şəklinizi yükləyin</div>
            }
        </div>
    );
};
