import { AXIOS_CONFIG } from "../config"
import { PaginationType } from "app/__shared__/model/types/pagination.type"
import { axiosInstance } from "../interceptor"

export const listUsersService = async (pagination:PaginationType,{rejectWithValue}:any) => {
    try {
        AXIOS_CONFIG.USER.LIST_USERS.url =  `/users?type=list&per_page=${pagination.pageLimit}&page=${pagination.currentPage}&totalPages=${pagination.totalPages}`
        const response = await axiosInstance(AXIOS_CONFIG.USER.LIST_USERS.url)
        return response.data
    } catch (error:any) {
        return rejectWithValue(error.response.data)
    }
}


export const viewUsersService = async (data:any, {rejectWithValue}:any) => {
    try {
        const response = await axiosInstance(`/users/${data.id}`, AXIOS_CONFIG.USER.SHOW_USER)
        return response.data
    } catch (error:any) {
        return rejectWithValue(error.response.data)
    }
}

export const createUsersService = async (data:any, {rejectWithValue}:any) => {
    try {
        const response = await axiosInstance({...AXIOS_CONFIG.USER.CREATE_USER , data:data.data})
        return response
    } catch (error:any) {
        return rejectWithValue(error.response.data)
    }
}


export const editUsersService = async (data:any, {rejectWithValue}:any) => {
    try {
        console.log(data)
        const response = await axiosInstance(`/users/${data.id}`, {...AXIOS_CONFIG.USER.UPDATE_USER , data:data.data})
        return response.data
    } catch (error:any) {
        return rejectWithValue(error.response.data)
    }
}

export const deleteUsersService = async (data:any, {rejectWithValue}:any) => {
    try {
        const response = await axiosInstance(`/users/${data.id}`, AXIOS_CONFIG.USER.DELETE_USER)
        return response.data
    } catch (error:any) {
        return rejectWithValue(error.response.data)
    }
}


