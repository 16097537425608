import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { statusEnum } from 'app/__shared__/model/enums/statusLoading'
import { useDispatch } from 'react-redux'

type Props = {
    status:statusEnum,
    params:{
        totalPages:number,
        pageLimit:number,
        currentPage:number
    },
    actions:any
}

export const PaginationFooter = ({
        status, 
        params:
        {
            totalPages, pageLimit, currentPage
        },
        actions:{
            changeCurrentPage,
            handlePageLimit
        }
    }: Props) => {
    const dispatch = useDispatch()
    
    return (
        <div className='flex w-full justify-center mt-9 mb-9'>
            <div className='flex gap-4'>
                {
                    (status === statusEnum.succeeded && totalPages !== null) &&
                    <>
                        <div  className='bg-[#1A67B4] text-lg px-3 py-1 text-white rounded-md'>
                            <select className='bg-[#1A67B4] focus:outline-0' onChange={(e) => dispatch(handlePageLimit(e.target.value))} value={pageLimit}>
                                <option value="8">8</option>
                                <option value="16">16</option>
                                <option value="32">32</option>
                                <option value="54">54</option>
                                <option value="96">96</option>
                            </select>
                        </div>
                        <div className='flex justify-start items-center gap-3'>
                            <button disabled={currentPage < 2} className={'py-2 px-4 bg-[#1A67B4] rounded-lg text-white' + (currentPage===1 ? ' bg-gray-300 ' : '')} onClick={() => dispatch(changeCurrentPage(currentPage-1))}>
                                <FontAwesomeIcon icon={faAngleLeft} />
                            </button>
                            <button className={'py-2 px-4  rounded-lg text-white' + (currentPage === 1 ? " border border-[#1A67B4] border-solid text-[#1A67B4] " : " bg-[#1A67B4]")} onClick={() => dispatch(changeCurrentPage(1))}>1</button>
                            
                            { (currentPage >= 5 && totalPages > 6)   && <div className='py-2 px-4 bg-[#1A67B4] rounded-lg text-white'>...</div>}
                            
                            {
                                (currentPage < 5 ) &&
                                <>
                                    {(totalPages > 1 ) && <button className={'py-2 px-4  rounded-lg text-white' + (currentPage === 2 ? " border border-[#1A67B4] border-solid text-[#1A67B4] " : " bg-[#1A67B4]")} onClick={() => dispatch(changeCurrentPage(2))}>2</button>}
                                    {(totalPages > 2 ) && <button className={'py-2 px-4  rounded-lg text-white' + (currentPage === 3 ? " border border-[#1A67B4] border-solid text-[#1A67B4] " : " bg-[#1A67B4]")} onClick={() => dispatch(changeCurrentPage(3))}>3</button>}
                                    {(totalPages > 3 ) &&<button className={'py-2 px-4   rounded-lg text-white' + (currentPage === 4 ? " border border-[#1A67B4] border-solid text-[#1A67B4] " : " bg-[#1A67B4]")} onClick={() => dispatch(changeCurrentPage(4))}>4</button>}
                                    {(totalPages > 4 ) && <button className={'py-2 px-4  rounded-lg text-white' + (currentPage === 5 ? " border border-[#1A67B4] border-solid text-[#1A67B4] " : " bg-[#1A67B4]")} onClick={() => dispatch(changeCurrentPage(5))}>5</button>}
                                </>
                            }

                            {
                                (currentPage === 5 && totalPages < 6 ) &&
                                <>
                                    {(totalPages > 1 ) && <button className={'py-2 px-4  rounded-lg text-white' + (currentPage === 2 ? " border border-[#1A67B4] border-solid text-[#1A67B4] " : " bg-[#1A67B4]")} onClick={() => dispatch(changeCurrentPage(2))}>2</button>}
                                    {(totalPages > 2 ) && <button className={'py-2 px-4  rounded-lg text-white' + (currentPage === 3 ? " border border-[#1A67B4] border-solid text-[#1A67B4] " : " bg-[#1A67B4]")} onClick={() => dispatch(changeCurrentPage(3))}>3</button>}
                                    {(totalPages > 3 ) &&<button className={'py-2 px-4   rounded-lg text-white' + (currentPage === 4 ? " border border-[#1A67B4] border-solid text-[#1A67B4] " : " bg-[#1A67B4]")} onClick={() => dispatch(changeCurrentPage(4))}>4</button>}
                                    {(totalPages > 4 ) && <button className={'py-2 px-4  rounded-lg text-white' + (currentPage === 5 ? " border border-[#1A67B4] border-solid text-[#1A67B4] " : " bg-[#1A67B4]")} onClick={() => dispatch(changeCurrentPage(5))}>5</button>}
                                </>
                            }
                            
                            
                            {
                                (currentPage >= 5 && (totalPages - currentPage) > 3) &&
                                <>
                                    <button className={'py-2 px-4 bg-[#1A67B4] rounded-lg text-white'} onClick={() => dispatch(changeCurrentPage(currentPage - 1))}>{currentPage - 1}</button>
                                    <button className='py-2 px-4  rounded-lg border border-[#1A67B4] border-solid text-[#1A67B4]' onClick={() => dispatch(changeCurrentPage(currentPage))}>{currentPage}</button>
                                    <button className={'py-2 px-4 bg-[#1A67B4] rounded-lg text-white'} onClick={() => dispatch(changeCurrentPage(currentPage + 1))}>{currentPage + 1}</button>
                                    <div className='py-2 px-4 bg-[#1A67B4] rounded-lg text-white'>...</div>
                                </>
                            }
                            
                            {(totalPages > 6 && currentPage < 5 && (totalPages - currentPage) > 2)    && <div className='py-2 px-4 bg-[#1A67B4] rounded-lg text-white'>...</div>}

                            
                            {
                                (totalPages > 5 &&  currentPage >= 5 && (totalPages - currentPage) < 4) &&
                                <>
                                    <button className={'py-2 px-4  rounded-lg text-white' + ((totalPages - currentPage - 4) === 0 ? " border border-[#1A67B4] border-solid text-[#1A67B4] " : " bg-[#1A67B4]")} onClick={() => dispatch(changeCurrentPage(totalPages - 4))}>{totalPages - 4}</button>
                                    <button className={'py-2 px-4  rounded-lg text-white' + ((totalPages - currentPage - 3) === 0 ? " border border-[#1A67B4] border-solid text-[#1A67B4] " : " bg-[#1A67B4]")} onClick={() => dispatch(changeCurrentPage(totalPages - 3))}>{totalPages - 3}</button>
                                    <button className={'py-2 px-4  rounded-lg text-white' + ((totalPages - currentPage - 2) === 0 ? " border border-[#1A67B4] border-solid text-[#1A67B4] " : " bg-[#1A67B4]")} onClick={() => dispatch(changeCurrentPage(totalPages - 2))}>{totalPages - 2}</button>
                                    <button className={'py-2 px-4  rounded-lg text-white' + ((totalPages - currentPage - 1) === 0 ? " border border-[#1A67B4] border-solid text-[#1A67B4] " : " bg-[#1A67B4]")} onClick={() => dispatch(changeCurrentPage(totalPages - 1))}>{totalPages - 1}</button>
                                </>
                            }

                            {
                                totalPages > 5 &&
                                <>
                                    <button className={'py-2 px-4  rounded-lg text-white' + (currentPage === totalPages ? " border border-[#1A67B4] border-solid text-[#1A67B4] " : " bg-[#1A67B4]")} onClick={() => dispatch(changeCurrentPage(totalPages))}>{totalPages}</button>
                                    <button disabled={currentPage===totalPages} className={'py-2 px-4 bg-[#1A67B4] rounded-lg text-white' + (currentPage===totalPages ? ' bg-gray-300 ' : '')} onClick={() => dispatch(changeCurrentPage(currentPage+1))}>
                                        <FontAwesomeIcon icon={faAngleRight} />
                                    </button>
                                </>
                            }
                            
                            
                        </div>
                    </>
                }
            </div>
        </div>
    )
}
