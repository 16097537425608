export const translation:any = {
        name:'Ad ',
        surname:'Soyad ',        
        email:'Elektron poçt ',
        father_name:'Ata adı ' ,   
        serial_number:"Seriya nömrəsi ",
        fin:"Fin ",
        address:'Ünvan ',
        phone:'Telefon nömrəsi ',
        is_military:'Hərbi mükəlləfiyyət ',
        has_crime: "Cinayət məsuliyyətinə cəlb olunma ",
        has_invalidity: "Əlillik ",
        gender: "Cinsiyyət ",
        weight: "Çəki ",
        height: "Boy hündürlüyü ",
        birthday: "Doğum tarixi ",
        image: "Şəkil ",
        video: "Video ",
}