import { AXIOS_CONFIG } from "../../config"
import { axiosInstance } from "../../interceptor"
import { notifyError } from "app/__shared__/helpers/utils/notify/notifyError"
// import { handleCatchStatusError } from "app/helpers/utils/error/handleCatchStatusError"

export const refreshTokenService = async ({rejectWithValue}:any) => {
    try {
        const response:any = await axiosInstance(AXIOS_CONFIG.AUTH.REFRESH_TOKEN)
        return response.data
    } catch (error:any) {
        return rejectWithValue(error.response.data)
    }
}
