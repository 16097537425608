import { useAppDispatch, useAppSelector } from "app/__shared__/components/store/store"

import { AppealsPaginatedTable } from "../AppealsPaginatedTable"
import { AppealsSearch } from "../AppealsSearch"
import { ListAppealsActions } from "../slices/AppealsList.slice"

export const AppealsList = () => {
    const listAppeals = useAppSelector(state=>state.AppealsReducer.ListAppealsReducer)
    const dispatch = useAppDispatch()

    
    return (
        <div className="bg-white rounded-md p-6">
            
            <div className="w-full flex justify-between items-center">
                <h1 className="text-4xl align-top">Müraciətlər</h1>

                {  
                    !listAppeals.toggle_search && 
                    <button onClick={() => dispatch(ListAppealsActions.toggleSearch())} className="btn btn-primary flex justify-start items-center gap-2">
                        Axtarış et
                    </button>
                }
                
                {  
                    listAppeals.toggle_search && 
                    <button onClick={() => dispatch(ListAppealsActions.toggleSearch())} className="btn btn-primary flex justify-start items-center gap-2">
                        Bağla
                    </button>
                }
            </div>

            <AppealsSearch />
            
            <AppealsPaginatedTable/>
        </div>
    )
}
 