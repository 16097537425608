import { faBackward, } from "@fortawesome/free-solid-svg-icons"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useNavigate } from "react-router-dom"

export const VacancySettingsBackToButton = () => {
    const navigate = useNavigate()
    const goVacancySettingsUpdate = () => {
        navigate('/settings')
    }
    return (
        <button onClick={goVacancySettingsUpdate} className="flex rounded-md px-4 py-1 bg-[#1A67B4] text-lg text-white btn gap-3 items-center justify-center"  >
            <span > Geri Qayıt</span>
            <FontAwesomeIcon icon={faBackward} className="mb-1"/>
        </button>
    )
}
