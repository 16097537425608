import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faUserPlus } from "@fortawesome/free-solid-svg-icons"
import { useContext } from "react"
import { ModalContext } from "app/__shared__/components/context/modal/ModalContext"
import { UsersCreateForm } from "./UsersCreateForm"

export const UsersAddButton = (props: any) => {
    const {toggleModalShow} = useContext(ModalContext)

    const handleClick = () => {
        toggleModalShow(<UsersCreateForm/> , 'İstifadəçi məlumatlarını daxil edin.')  
    }


    return (
        <button onClick={handleClick} className="rounded-md px-4 py-2 bg-[#1A67B4] text-lg text-white btn gap-2"  >
            <span> İstifadəçi əlavə et</span>
            <FontAwesomeIcon icon={faUserPlus} className="ml-2" />
        </button>
    )

}