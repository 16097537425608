import { AXIOS_CONFIG } from "../../config"
import { axiosInstance } from "../../interceptor"
import { notifyError } from "app/__shared__/helpers/utils/notify/notifyError"

export const logoutService = async ({rejectWithValue}:any) => {
    try {
        const response:any = await axiosInstance(AXIOS_CONFIG.AUTH.LOGOUT)
        return response.data
    } catch (error:any) {
        return rejectWithValue(error.response.data)

    }
}
