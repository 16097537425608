import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { deleteRolesThunk, listRolesThunk } from './Roles.thunk'
import { setStatusPending, setStatusRejected, setStatusSucceeded } from 'app/__shared__/components/store/thunkStatusHandler'

import { RequestType } from 'app/__shared__/model/types/request'
import { RolesType } from 'app/__shared__/model/types/role.type'
import { RootState } from 'app/__shared__/components/store/store'
import { statusEnum } from 'app/__shared__/model/enums/statusLoading'

export type ListRolesStateType = {} & RequestType<RolesType>

const ListRolesState: ListRolesStateType = {
    status: statusEnum.idle,
    response:{
        data:[],
        errors: {},
    }
}


export const ListRolesSlice = createSlice({
  name: 'list-roles-slice',
  initialState: ListRolesState,
  reducers: {
      
  },
  
  extraReducers: (builder) => {
    builder.addCase(listRolesThunk.pending, (state, action) => {
        state.status = statusEnum.loading
    })
    
    builder.addCase(listRolesThunk.rejected, (state, action) => {
        state.status = statusEnum.failed
        state.response.data = []
    })
    
    builder.addCase(listRolesThunk.fulfilled, (state, action) => {
        state.status = statusEnum.succeeded
        state.response.data = action.payload.data
    })


    builder.addCase(deleteRolesThunk.pending, (state, action) => {
    })
    
    builder.addCase(deleteRolesThunk.rejected, (state, action) => {
    })
    
    builder.addCase(deleteRolesThunk.fulfilled, (state, action) => {
    })
    
  },
})


export const ListRolesActions = {
    ...ListRolesSlice.actions,
}


export const ListRolesData = (state:RootState) => state.RolesReducer.ListRolesReducer

export const ListRolesReducer = ListRolesSlice.reducer