import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { editAppealsThunk, viewAppealsThunk } from './Appeals.thunk'
import { setStatusPending, setStatusRejected, setStatusSucceeded } from '../../__shared__/components/store/thunkStatusHandler'

import { BAKU_CORDINATES } from 'app/__shared__/data/contants'
import { RootState } from '../../__shared__/components/store/store'
import { statusEnum } from 'app/__shared__/model/enums/statusLoading'

export interface IAppeal {
    name: string,
    surname: string,
    father_name: string,
    email: string,
    serial_number: string,
    fin_number: string,
    address: string,
    note:string,		
    position:{
        lat:number,
        lng:number
    },
    phone: string,
    is_millitary: boolean|null,
    has_crime: boolean|null,
    has_invalidity: boolean|null,
    gender:0|1|null,
    weight:string,
    height:string,
    birthday:string,
    birth_day:string,
    birth_month:string,
    birth_year:string,
    image:any,
    image_url:string,
    image_name:string,
    video:any,
    video_url:string,
    video_name:string,
    isAllValidStatus:statusEnum,
    steps:{
        currentStep:number,
        step1:{
            status:statusEnum,
        },
        step2:{
            status:statusEnum,
        },
        step3:{
            status:statusEnum,
        }
    }
} 

export interface IEditAppeal {
    status:statusEnum,
    data:IAppeal,
    submit_status:statusEnum,
    submit_errors:any
} 

const EditAppealsState: IEditAppeal = {
    status: statusEnum.idle,
    submit_status:statusEnum.idle,
    data:{
        name: '',
        surname: '',
        father_name: '',
        email: '',
        serial_number: '',
        fin_number: '',
        address: '',
        position:{
            lat:BAKU_CORDINATES.lat,
            lng:BAKU_CORDINATES.lng
        },
        phone: '',
        is_millitary: null,
        has_crime: null,
        has_invalidity: null,
        gender:null,
        weight:'',
        height:'',
        birthday:'',
        image:'',
        video:'',
        isAllValidStatus:statusEnum.idle
    },
    submit_errors:null
}


export const EditAppealsSlice = createSlice({
  name: 'edit-appeals-slice',
  initialState: EditAppealsState,
  reducers: {
    resetAppealsEdit: (state:IEditAppeal) => {
        state.status = statusEnum.idle
        state.data = {
            name: '',
            surname: '',
            father_name: '',
            email: '',
            serial_number: '',
            fin_number: '',
            address: '',
            position:{
                lat:BAKU_CORDINATES.lat,
                lng:BAKU_CORDINATES.lng
            },
            phone: '',
            is_millitary: null,
            has_crime: null,
            has_invalidity: null,
            gender:null,
            weight:'',
            height:'',
            birthday:'',
            image:'',
            video:'',
            isAllValidStatus:statusEnum.idle
        }
        state.submit_status = statusEnum.idle
        state.submit_errors = null
    }
      
  },
  
  extraReducers: (builder) => {
    builder.addCase(viewAppealsThunk.pending, (state:IEditAppeal, action: PayloadAction<any>) => {
        setStatusPending(state, action)
    })
    
    builder.addCase(viewAppealsThunk.rejected, (state:IEditAppeal, action:  PayloadAction<any>) => {
        setStatusRejected(state, action)
    })
    
    builder.addCase(viewAppealsThunk.fulfilled, (state:IEditAppeal, action:  PayloadAction<any>) => {
        setStatusSucceeded(state, action)
        state.data = action.payload.data
    })



    builder.addCase(editAppealsThunk.pending, (state:IEditAppeal, action: PayloadAction<any>) => {
        state.submit_status = statusEnum.loading
        console.log('HELLO sl')
    })
    
    builder.addCase(editAppealsThunk.rejected, (state:IEditAppeal, action:  PayloadAction<any>) => {
        console.log('HELLO sf')
        state.submit_status = statusEnum.failed
        state.submit_errors = action.payload.errors
    })
    
    builder.addCase(editAppealsThunk.fulfilled, (state:IEditAppeal, action:  PayloadAction<any>) => {
        console.log('HELLO st')

        state.submit_status = statusEnum.succeeded
        setStatusSucceeded(state, action)
        state.data = action.payload.data
    })


    


    
  },
})


export const EditAppealsActions = {
    ...EditAppealsSlice.actions,
}


export const EditAppealsData = (state:RootState) => state.AppealsReducer.EditAppealsReducer
export const EditAppealsReducer = EditAppealsSlice.reducer